import { ConnectionDataStore, ConnectionType } from '@core/enums';

export interface ConnectionModel {
  accessToken?: string;
  accountId?: string;
  bucketName?: string;
  connectionId: string;
  connectionName: string;
  connectionStatus: string;
  connectionType: ConnectionType;
  createdBy: string;
  createdByName: string;
  createdDate: string;
  dataStore: ConnectionDataStore;
  emailId: string;
  modifiedBy: string;
  modifiedDate: string;
  prefixName?: string;
  projectId: string;
  refreshToken?: string;
}
