import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { CreateProjectFromControls } from '@core';
import { EMAIL_REGEX, RESTRICTED_GROUP_REGEX } from '@core/constants';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { noWhitespaceValidator } from '@shared';
import { FeatureFlagsState, formValue, projectDetails } from 'app/state';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { AddRestrictedGroupComponent } from '../add-restricted-group/add-restricted-group.component';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit {
  @Input() public allowedExternalDomains: Array<string> = [];
  @Input() public data: projectDetails = {
    credit: 0,
    dwAccess: false,
    emailDistributionList: [],
    hasPowerUsers: false,
    projectName: '',
    projectNickname: '',
    projectNumber: '',
    projectRestrictedDataUseGroups: [],
    projectSize: '',
  };
  @Output() public proceedToShowCancelPopup = new EventEmitter<string>();
  @Output() public proceedToShowPopup = new EventEmitter<any>();
  @ViewChild('restrictedGroupInput')
  public restrictedGroupInput!: ElementRef<HTMLInputElement>;
  @Input() public roleName = '';
  @ViewChild('typeOfProjectSize') public skipper!: MatSelect;
  public addOnBlur = true;
  public afterChangeFormValue: string[] = [];
  public blockExternalDomain: string | undefined;
  public changedFormValue: formValue = {};
  public emailList: Array<string> = [];
  public emailListInput!: ElementRef<HTMLInputElement>;
  public internalDomainList: Array<string> = [];
  public isAnyChangeInEditForm = true;
  public isEdit = false;
  public numberValidations = '^[a-zA-Z0-9-_. ]+$';
  public orginalFormValue: projectDetails = {
    credit: 0,
    dwAccess: false,
    emailDistributionList: [],
    hasPowerUsers: false,
    projectName: '',
    projectNickname: '',
    projectNumber: '',
    projectRestrictedDataUseGroups: [],
    projectSize: '',
  };
  public projectForm: FormGroup;
  public restrictedDataUseGroups: Array<string> = [];
  public selectedEmailList: Array<string> = [];
  public selectedRestrictedGroup: Array<string> = [];
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public showInvalidDomainError = false;
  public specialValidators = '^[a-zA-Z0-9-_.]+$';
  public wareHouseSize = [
    'X-Small',
    'Small',
    'Medium',
    'Large',
    'X-Large',
    '2X-Large',
    '3X-Large',
    '4X-Large',
    '5X-Large',
    '6X-Large',
  ];

  constructor(
    public store: Store,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.numberValidations = '^[1-9]d*$';

    this.projectForm = this.fb.group({
      projectNumber: [
        '',
        [
          Validators.required,
          noWhitespaceValidator(),
          Validators.pattern(this.specialValidators),
          Validators.maxLength(12),
        ],
      ],
      projectNickname: [
        '',
        [
          Validators.required,
          noWhitespaceValidator(),
          Validators.pattern(this.specialValidators),
          Validators.maxLength(12),
        ],
      ],
      projectName: [
        '',
        [
          Validators.required,
          noWhitespaceValidator(),
          Validators.maxLength(100),
        ],
      ],
      projectRestrictedDataUseGroups: [[]],
      emailDistributionList: [
        [],
        [Validators.required, this.customEmailValidator],
      ],
      dwAccess: [false],
      hasPowerUsers: [false],
      projectSize: ['', [Validators.required]],
      credit: [
        '',
        [Validators.required, Validators.pattern(this.numberValidations)],
      ],
    });
  }

  public addChip(event: MatChipInputEvent): void {
    const formControl = this.projectForm.controls;
    const checkValueIsValid = this.customEmailValidator(
      formControl[CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP],
      true
    );
    if (
      !formControl[CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP]
        .errors
    ) {
      const value = (event.value || '').trim();

      if (value.length <= 20) {
        if (value && checkValueIsValid) {
          this.restrictedDataUseGroups.push(value);
        }
        // Clear the input value
        event.chipInput?.clear();
        formControl[
          CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP
        ].setValue(this.restrictedDataUseGroups);
      }
    }
  }

  public addEmail(event: MatChipInputEvent): void {
    const formControl = this.projectForm.controls;
    const value = (event.value || '').trim();
    const checkValueIsValid = this.customEmailValidator(
      formControl[CreateProjectFromControls.EMAIL_DISTRIBUTION_LIST],
      true
    );
    if (value && checkValueIsValid == null) {
      if (this.isValidDomain(value)) {
        this.emailList.push(value);
      } else {
        this.emailList.push(value);
        this.showInvalidDomainError = true;
      }
    }
    // Clear the input value
    event.chipInput?.clear();
    formControl[CreateProjectFromControls.EMAIL_DISTRIBUTION_LIST].setValue(
      this.emailList
    );
  }

  public addRestrictedGroup(): void {
    const dialogRef = this.dialog
      .open(AddRestrictedGroupComponent, {
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResponse) => {
        if (dialogResponse.result === 'success') {
          this.restrictedDataUseGroups.push(
            dialogResponse.restrictedGroupValue
          );
          this.projectForm.patchValue({
            projectRestrictedDataUseGroups: this.restrictedDataUseGroups,
          });
        }
      });
  }

  public changeSelection(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedRestrictedGroup.indexOf(event.option.viewValue) >= 0) {
      this.removeChip(event.option.viewValue);
    } else {
      this.selectedChip(event);
    }
  }

  public changeSelectionEmail(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedEmailList.indexOf(event.option.viewValue) >= 0) {
      this.removeEmail(event.option.viewValue);
    } else {
      this.selectedEmail(event);
    }
  }

  public customEmailValidator(
    control: AbstractControl,
    editEmail?: boolean
  ): { [key: string]: any } | null | [[key: string]] {
    const emailPattern = new RegExp(EMAIL_REGEX);
    let valid;
    if (typeof control.value === 'object' && control.value.length > 0) {
      valid = control.value.filter((res: string) => {
        emailPattern.test(res);
      });
    } else if (typeof control.value === 'object' && editEmail) {
      valid = emailPattern.test(control.value);
    } else {
      valid = emailPattern.test(control.value);
    }
    return valid ? null : { invalidEmail: true };
  }

  public customTextInputValidator(
    control: AbstractControl
  ): { [key: string]: any } | null | [[key: string]] {
    let valid = true;
    let invalidText;
    const invalidTexts = ['staging', 'restricted'];
    const inputValue = control.value.toString();
    if (inputValue) {
      if (invalidTexts.includes(inputValue.toLowerCase())) {
        valid = false;
        invalidText = inputValue;
      }
    }
    return valid
      ? null
      : {
          textValidators: true,
          message: `Restricted data use groups cannot be named as "staging" or "restricted"`,
        };
  }

  public customWhiteSpaceValidator(
    control: AbstractControl,
    editRestrictedGroup?: boolean
  ): { [key: string]: any } | null | [[key: string]] {
    const spacePattern = new RegExp(RESTRICTED_GROUP_REGEX);
    let valid;
    if (typeof control.value === 'object' && control.value.length > 0) {
      valid = control.value.filter((res: string) => {
        spacePattern.test(res);
      });
    } else if (typeof control.value === 'object' && editRestrictedGroup) {
      valid = spacePattern.test(control.value);
    } else if (
      typeof control.value === 'object' &&
      control.value.length === 0
    ) {
      valid = true;
    } else {
      valid = spacePattern.test(control.value);
    }
    return valid ? null : { spaceValidators: true };
  }

  public disableControl(control: AbstractControl): void {
    control.setValue('');
    control.disable();
    control.clearValidators();
    control.updateValueAndValidity();
  }
  public enableControl(formField: string): void {
    const control = this.projectForm.controls[formField];
    control.enable();
    control.setValidators([Validators.required]);
    control.updateValueAndValidity();
  }

  public enableDataWareHouse(): void {
    const wareHouseSizeControl =
      this.projectForm.controls[CreateProjectFromControls.WARE_HOUSE_SIZE];
    const creditControl =
      this.projectForm.controls[CreateProjectFromControls.CREDIT];
    if (this.projectForm.controls[CreateProjectFromControls.DWACCESS].value) {
      this.enableControl(CreateProjectFromControls.WARE_HOUSE_SIZE);
      this.enableControl(CreateProjectFromControls.CREDIT);
    } else {
      this.disableControl(wareHouseSizeControl);
      this.disableControl(creditControl);
    }
  }

  public getProjectIdValue(): string {
    if (
      this.projectForm.controls[CreateProjectFromControls.PROJECT_NUMBER]
        .value ||
      this.projectForm.controls[CreateProjectFromControls.PROJECT_NICK_NAME]
        .value
    ) {
      const formattedProjectNumebr =
        this.projectForm.controls[
          CreateProjectFromControls.PROJECT_NUMBER
        ].value.trim();
      const formattedProjectNickName =
        this.projectForm.controls[
          CreateProjectFromControls.PROJECT_NICK_NAME
        ].value.trim();
      return `${formattedProjectNumebr}_${formattedProjectNickName}`;
    } else {
      return ``;
    }
  }

  public hasInvalidEmails(): boolean {
    return this.emailList.some((email) => this.isInvalidEmail(email));
  }

  public isInvalidEmail(email: string): boolean {
    return !this.isValidDomain(email);
  }

  ngOnInit(): void {
    const featureFlags: FeatureFlagsStateModel = this.store.selectSnapshot(
      FeatureFlagsState.returnFeatureFlags
    );
    this.internalDomainList = featureFlags.internalDomainList;
    this.blockExternalDomain =
      featureFlags.blockExternalDomainForEmailDistributionList;

    if (this.data && this.data.projectName) {
      this.isEdit = true;
      this.projectForm.patchValue({
        projectNumber: this.data.projectNumber,
        projectNickname: this.data.projectNickname,
        projectName: this.data.projectName,
        projectRestrictedDataUseGroups:
          this.data.projectRestrictedDataUseGroups,
        emailDistributionList: this.data.emailDistributionList,
        dwAccess: this.data.dwAccess,
        hasPowerUsers: this.data.hasPowerUsers ? true : false,
        wareHouseSize: this.data.projectSize ? this.data.projectSize : '',
        credit: this.data.credit ? this.data.credit : '',
      });
      if (
        this.data.projectRestrictedDataUseGroups &&
        this.data.projectRestrictedDataUseGroups?.length > 0
      ) {
        this.restrictedDataUseGroups = [
          ...this.data.projectRestrictedDataUseGroups,
        ];
      }
      if (this.data.projectSize) {
        this.projectForm.controls[
          CreateProjectFromControls.WARE_HOUSE_SIZE
        ].setValue(this.data.projectSize);
      }
      if (
        this.data.emailDistributionList &&
        this.data.emailDistributionList?.length > 0
      ) {
        this.emailList = [...this.data.emailDistributionList];
      }
      this.projectForm.controls[
        CreateProjectFromControls.PROJECT_NUMBER
      ].disable();
      this.projectForm.controls[
        CreateProjectFromControls.PROJECT_NICK_NAME
      ].disable();
      this.enableDataWareHouse();
      const initialValue = this.projectForm.value;
      this.projectForm.valueChanges.subscribe((selectedValue) => {
        this.afterChangeFormValue = [];
        this.afterChangeFormValue = Object.keys(initialValue).filter(
          (key) => initialValue[key] !== selectedValue[key]
        );

        if (this.afterChangeFormValue.includes('dwAccess')) {
          this.afterChangeFormValue.push('credit', 'projectSize');
        }

        this.changedFormValue = { ...this.changedFormValue };
        // eslint-disable-next-line guard-for-in
        for (const i in this.afterChangeFormValue) {
          this.changedFormValue[this.afterChangeFormValue[i]] =
            selectedValue[this.afterChangeFormValue[i]];
        }
        this.isAnyChangeInEditForm = false;
      });
    } else {
      this.isEdit = false;
      this.isAnyChangeInEditForm = false;
      const wareHouseSizeControl =
        this.projectForm.controls[CreateProjectFromControls.WARE_HOUSE_SIZE];
      const creditControl =
        this.projectForm.controls[CreateProjectFromControls.CREDIT];
      this.disableControl(creditControl);
      this.disableControl(wareHouseSizeControl);
      this.projectForm.controls[
        CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP
      ].setValidators([
        Validators.maxLength(20),
        this.customWhiteSpaceValidator,
        this.customTextInputValidator,
      ]);
    }
  }

  public removeChip(chip: string): void {
    const index = this.restrictedDataUseGroups.indexOf(chip);

    if (index >= 0) {
      this.restrictedDataUseGroups.splice(index, 1);
    }
    this.showInvalidDomainError = false;
  }

  public removeEmail(chip: string): void {
    const index = this.emailList.indexOf(chip);

    if (index >= 0) {
      this.emailList.splice(index, 1);
    }

    if (this.isEdit) {
      this.projectForm.controls[
        CreateProjectFromControls.EMAIL_DISTRIBUTION_LIST
      ].setValue(this.emailList);
    }
  }

  public selectedChip(event: MatAutocompleteSelectedEvent): void {
    this.restrictedDataUseGroups.push(event.option.viewValue);
    this.restrictedGroupInput.nativeElement.value = '';
    this.projectForm.controls[
      CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP
    ].setValue(this.restrictedDataUseGroups);
  }
  public selectedEmail(event: MatAutocompleteSelectedEvent): void {
    this.emailList.push(event.option.viewValue);
    this.emailListInput.nativeElement.value = '';
    this.projectForm.controls[
      CreateProjectFromControls.EMAIL_DISTRIBUTION_LIST
    ].setValue(this.emailList);
  }

  public showCancelPopup(): void {
    this.proceedToShowCancelPopup.emit();
  }

  public showConfirmPopup(): void {
    if (this.isEdit) {
      const projectFormValue = this.projectForm.value;
      const changedFormValue = this.changedFormValue;
      this.proceedToShowPopup.emit({ projectFormValue, changedFormValue });
    } else {
      this.proceedToShowPopup.emit(this.projectForm.value);
    }
  }

  private isValidDomain(email: string): boolean {
    const isBlockExternalDomain =
      this.blockExternalDomain?.toLowerCase() === 'true';
    const emailDomain = email.split('@')[1];
    if (isBlockExternalDomain) {
      return this.internalDomainList.some(
        (domain) => domain.toLowerCase() === emailDomain.toLowerCase()
      );
    } else {
      const allowedDomains = this.internalDomainList.concat(
        this.allowedExternalDomains
      );
      return allowedDomains.some(
        (domain) => domain.toLowerCase() === emailDomain.toLowerCase()
      );
    }
  }
}
