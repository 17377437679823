<div
  class="mpr-dashboard-loader"
  *ngIf="showSpinner$ | async; else showProjectForm"
>
  <div class="mpr-noresult-container">
    <div class="mpr-noresutl-error">
      <span>
        <mat-spinner diameter="45" color="accent"></mat-spinner>
      </span>
    </div>
  </div>
</div>
<ng-template #showProjectForm>
  <mpr-project-form
    (proceedToShowPopup)="showConfirmPopup($event)"
    (proceedToShowCancelPopup)="showCancelPopup()"
    [data]="selectedProjectDetails"
    [allowedExternalDomains]="allowedExternalDomains"
  ></mpr-project-form>
</ng-template>
