import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MprConfirmComponent } from '@shared';
import { MatDialog } from '@angular/material/dialog';
import {
  CreateAProject,
  ManageProjectState,
  ResetProjectData,
  SetLoaderState,
} from 'app/state';
import ManageProjectModel, {
  MessageDetails,
  projectDetails,
} from 'app/state/manage-project/manage-project-state-model';
import { UserProjectRoleEnum } from '@core';
import {
  Observable,
  catchError,
  of,
  throwError,
  timer,
  withLatestFrom,
} from 'rxjs';
import { AlertMessageService } from '@core/services';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit, OnDestroy {
  @Select(ManageProjectState.getProjectCreationStatus)
  public projectCreation$!: Observable<MessageDetails>;
  @Select(ManageProjectState.getLoaderStatus)
  public showSpinner$?: Observable<boolean>;
  public returnPath = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public store: Store,
    public dialog: MatDialog,
    private alertMsgService: AlertMessageService
  ) {
    this.returnPath = route.snapshot.data['back'];
  }

  public formattedMessagToShow(projectForm: projectDetails): string {
    if (projectForm.dwAccess && projectForm.hasPowerUsers) {
      return `You are about to add a new project “<b>${projectForm.projectNumber}_${projectForm.projectNickname}</b>” with Data Warehouse and Heavy Lifting services enabled.<br/><br/>Are you sure you want to continue?`;
    } else if (projectForm.dwAccess) {
      return `You are about to add a new project “<b>${projectForm.projectNumber}_${projectForm.projectNickname}</b>” with Data Warehouse service enabled.<br/><br/>Are you sure you want to continue?`;
    } else if (projectForm.hasPowerUsers) {
      return `You are about to add a new project “<b>${projectForm.projectNumber}_${projectForm.projectNickname}</b>” with Heavy Lifting services enabled.<br/><br/>Are you sure you want to continue?`;
    } else {
      return `You are about to add a new project “<b>${projectForm.projectNumber}_${projectForm.projectNickname}</b>”.<br/><br/>Are you sure you want to continue? `;
    }
  }

  public navigateBack(message?: any, type = 'success'): void {
    const navigationExtras: NavigationExtras = {
      state: {
        type,
        message,
      },
    };

    this.returnPath = `/platformAdmin/projects/`;
    this.router.navigate([this.returnPath], navigationExtras);
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.store.dispatch(new ResetProjectData());
  }

  ngOnInit(): void {}

  public showCancelPopup(): void {
    this.dialog
      .open(MprConfirmComponent, {
        disableClose: true,
        data: {
          confirmTitle: 'Cancel Confirmation',
          confirmMessage: `You will lose any changes you made. Are you sure you want to cancel?`,
          cancelButtonText: 'Go Back',
          confirmButtonText: 'Proceed',
          confirmData: true,
        },
      })
      .afterClosed()
      .subscribe((proceed: boolean) => {
        if (proceed) {
          this.router.navigate([this.returnPath]);
        }
      });
  }

  public showConfirmPopup(projectForm: projectDetails): void {
    const popupContentMessage = this.formattedMessagToShow(projectForm);
    this.dialog
      .open(MprConfirmComponent, {
        disableClose: true,
        data: {
          confirmTitle: 'Create Project',
          confirmMessage: popupContentMessage,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Proceed',
          confirmData: true,
        },
      })
      .afterClosed()
      .subscribe((proceed: boolean) => {
        if (proceed) {
          this.store
            .dispatch(
              new CreateAProject(
                projectForm,
                UserProjectRoleEnum.PLATFORM_ADMIN
              )
            )
            .pipe(
              withLatestFrom(this.projectCreation$),
              catchError((err) => {
                this.store.dispatch(new SetLoaderState(false));
                this.navigateBack(err.error.message, 'error');
                return throwError(() => new Error(''));
              })
            )
            .subscribe(([_, res]) => {
              if (res && res.projectId) {
                timer(1000).subscribe(() => {
                  this.alertMsgService.success({
                    body: 'New project has been successfully created.',
                  });
                });
                this.router.navigate([this.returnPath]);
              }
            });
        }
      });
  }
}
