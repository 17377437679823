import { Component, Input, OnChanges } from '@angular/core';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { ChartOptions } from '@core/interfaces';
@Component({
    selector: 'mpr-job-details-stacked-bar',
    templateUrl: './job-details-stacked-bar.component.html',
    styleUrls: ['./job-details-stacked-bar.component.scss']
})

export class MprJobDetailsStackedBarComponent implements OnChanges {
    @Input() public chartColors: Array<string> = [];
    @Input() public chartData: ApexAxisChartSeries = [];
    public chartOptions: Partial<ChartOptions> = {
        series: [],
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%',
            width: 175,
            toolbar: {
                show: false
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }
        ],
        xaxis: {

            categories: ['files'
            ],
            labels: {
                show: false
            }
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            showForSingleSeries: true,
            position: 'right',
            offsetX: -35,
            offsetY: 75,
            inverseOrder: true,
            width: 150,
            height: 200,
            itemMargin: {
                horizontal: 15,
                vertical: 15
            },
            onItemClick: {
                toggleDataSeries: false
            },
            onItemHover: {
                highlightDataSeries: false
            },
        },
        tooltip: {
            enabled: false
        },
        dataLabels: {
            enabled: false
        }
    };

    ngOnChanges(): void {
        if (this.chartData.length !== 0) {
            this.chartOptions.series = this.chartData;
            this.chartOptions.legend.customLegendItems = [];
            this.chartOptions.colors = this.chartColors;
            this.chartData.forEach((data) => {
                this.chartOptions.legend.customLegendItems.push(`${data.name} : ${data.data[0]}`);
            })

        }
    }

}