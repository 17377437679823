import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ProjectMetadataService } from '@theme/services';
import { Observable, tap } from 'rxjs';
import ProjectModel from './project-state-model';
import {
  LoadSelectedProjectMetadata,
  ResetSelectedProjectMetadata,
} from './project.actions';
import { SetDashboardLoaderState } from '../dashboard-loader-state/dashboard-loader.actions';

const stateDefaults: ProjectModel = {
  businessUnit: '',
  clientName: '',
  department: '',
  dwAccess: false,
  hasPowerUsers: false,
  projectAdminEmailList: [],
  projectId: '',
  projectName: '',
  projectNumber: '',
  projectRestrictedDataUseGroups: [],
  snowflakeProvisioningStatus: '',
};
@State<ProjectModel>({
  defaults: stateDefaults,
  name: 'ProjectMetadata',
})
@Injectable()
export class ProjectState {
  constructor(
    private projectMetadataService: ProjectMetadataService,
    private store: Store
  ) {}

  @Selector()
  public static getProjectAdminEmailList(state: ProjectModel): string[] {
    return state.projectAdminEmailList || [];
  }

  @Selector()
  public static getProjectPowerUsers(state: ProjectModel): boolean {
    return state.hasPowerUsers;
  }

  @Selector()
  public static getProjectRestrictedDataGroups(state: ProjectModel): string[] {
    return state.projectRestrictedDataUseGroups;
  }

  @Selector()
  public static getSelectedProjectData(state: ProjectModel): ProjectModel {
    return state;
  }

  @Selector()
  public static getSnowflakeProvisioningStatus(state: ProjectModel): string {
    return state.snowflakeProvisioningStatus;
  }

  @Action(LoadSelectedProjectMetadata)
  public loadSelectedProjectMetadata(
    { getState, patchState }: StateContext<ProjectModel>,
    { requestHeaders, getProjectAdminEmailList }: LoadSelectedProjectMetadata
  ): Observable<ProjectModel> {
    this.store.dispatch(new SetDashboardLoaderState(true));
    return this.projectMetadataService.getProjectMetadata(requestHeaders, getProjectAdminEmailList).pipe(
      tap((project) => {
        this.store.dispatch(new SetDashboardLoaderState(false));
        project.snowflakeProvisioningStatus =
          project.snowflakeProvisioningStatus ?? 'inactive';
        patchState({ ...project });
      })
    );
  }

  @Action(ResetSelectedProjectMetadata)
  public resetProjectState({ patchState }: StateContext<ProjectModel>): void {
    patchState({ ...stateDefaults });
  }
}
