<div
  class="matero-container-wrap matero-navbar-side matero-header-fixed"
  dir="ltr"
>
  <mat-sidenav-container class="matero-container" autosize autoFocus>
    <mat-sidenav-content #content class="matero-content-wrap">
      <header>
        <mat-toolbar
          class="matero-toolbar"
          color="accent"
          [class]="isTermsPage ? 'd-flex mpr-justify-content-center' : ''"
        >
          <div class="matero-sidebar-header">
            <app-branding></app-branding>
          </div>
        </mat-toolbar>
      </header>
      <div *ngIf="showTitle">
        <div
          class="subheader app-sub-header"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div class="subheader-left-container">
            <mpr-subheader-page-title-nav></mpr-subheader-page-title-nav>
          </div>
        </div>
      </div>
      <main
        class="main-content"
        [ngClass]="!showTitle ? 'main-content-with-no-title' : ''"
      >
        <div class="matero-content">
          <router-outlet></router-outlet>
        </div>
      </main>
      <footer>
        <app-footer></app-footer>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
