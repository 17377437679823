export default interface ProjectModel {
  businessUnit: string;
  clientName: string;
  department: string;
  dwAccess?: boolean;
  hasPowerUsers: boolean;
  [key: string]: any;
  projectAdminEmailList?: string[];
  projectId: string;
  projectName: string;
  projectNumber: string;
  projectRestrictedDataUseGroups: string[];
  snowflakeProvisioningStatus: string;

}
