<ng-container
  *ngIf="{
    connections: (connectionsLoading$ | async),
    connection: (connectionLoading$ | async)
  } as loaders"
>
  <div class="mpr-upload-schedule-form">
    <div class="mpr-form">
      <form [formGroup]="editScheduleForm">
        <div class="mpr-form-block">
          <div class="mpr-form-block-field">
            <div class="mpr-form-block-field-label" for="typeOfDataSource"
              >Select Data Source<sup>*</sup>
              <button class= "mpr-btn-transparency" 
                [mtxTooltip]="selectDataSourceInfo" 
                [mprMtxTooltipAriaDescribedBy]="selectDataSourceInfo" 
                #tooltip="mtxTooltip"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
              <span
                class="mpr-select-source-spinner"
                *ngIf="loaders.connections"
              >
                <mat-spinner diameter="30" color="accent"></mat-spinner>
              </span>
            </div>
            <mat-form-field
              class="mpr-mat-select-field mpr-width-percentage-100"
              floatLabel="never"
              [hideRequiredMarker]="hideRequiredMarker"
              appearance="outline"
            >
              <mat-select
                formControlName="connectionId"
                panelClass="mpr-select-option-panel"
                placeholder="Select Data Source"
                #typeOfDataSource
                (selectionChange)="setSelectedConnection()"
                [disabled] = "!isloggedinUserSchedule"
              >
                  <mat-option
                    *ngFor="let connection of displayConnectionList"
                    [value]="connection.connectionId"
                    [disabled] = "externalConnectionId === connection.connectionId"
                  >
                    {{ connection.connectionName }}
                  </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error
              class="padding"
              *ngIf="editScheduleForm.controls['connectionId'].touched && editScheduleForm.controls['connectionId'].errors?.['required']"
            >
              Please select a data source.</mat-error
            >
          </div>
          <div class="mpr-form-block-field">
            <label for="folderPath" class="mpr-form-block-field-label"
              >Folder Path<sup>*</sup>
              <button class= "mpr-btn-transparency" 
                [mtxTooltip]="folderPathInfo" 
                [mprMtxTooltipAriaDescribedBy]="folderPathInfo" 
                #tooltip="mtxTooltip"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
              <span
                class="mpr-select-source-spinner"
                *ngIf="loaders.connection"
              >
                <mat-spinner diameter="30" color="accent"></mat-spinner>
              </span>
            </label>
            <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
              <div class="mpr-form-fields-row-items mpr-folder-path-item">
                <mat-form-field
                  floatLabel="never"
                  class="mpr-width-percentage-100"
                  appearance="outline"
                  [hideRequiredMarker]="hideRequiredMarker"
                >
                  <input
                    id="folderPath"
                    formControlName="folderPath"
                    class="mpr-form-block-field-input"
                    readonly
                    matInput
                  />
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="editScheduleForm.controls['folderPath'].touched && editScheduleForm.controls['folderPath'].errors?.['required']"
                >
                  Please click on Add Path to select a folder path.</mat-error
                >
              </div>
              <div class="mpr-form-fields-row-items mpr-add-path-item">
                <button
                  mat-stroked-button
                  [disabled]="
                    loaders.connection ||
                    typeOfDataSource.value === '' ||
                    !isloggedinUserSchedule ||
                    typeOfDataSource.value === externalConnectionId
                  "
                  class="mpr-button mpr-green-button mpr-width-px-100 mpr-add-path-button"
                  type="button"
                  (click)="showFilePicker()"
                >
                  Add Path
                </button>
              </div>
            </div>
          </div>
          <div class="mpr-form-block-field" *ngIf = "scheduleType === 'staging'">
            <label class="mpr-form-block-field-label" for="mpr-select-destination-folder"
              >Select Destination Folder<sup>*</sup>
              <button class= "mpr-btn-transparency" 
                [mtxTooltip]="destinationFolderPopover" 
                [mprMtxTooltipAriaDescribedBy]="destinationFolderPopover" 
                #tooltip="mtxTooltip"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
            </label>
            <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
              <div class="mpr-form-fields-row-items mpr-select-destination-item">
                <mat-form-field
                  floatLabel="never"
                  class="mpr-width-percentage-100"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                >
                  <input
                      formControlName="stagingTargetPath"
                      class="mpr-form-block-field-input"
                      readonly
                      matInput
                      id="mpr-select-destination-folder"
                  />
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="editScheduleForm.controls['stagingTargetPath'] && editScheduleForm.controls['stagingTargetPath'].touched && editScheduleForm.controls['stagingTargetPath'].errors?.['required']"
                >
                  Please click on Select Folder to select a destination folder.</mat-error
                >
              </div>
              <div class="mpr-form-fields-row-items mpr-select-folder-item">
                <button
                  mat-stroked-button
                  class="mpr-button mpr-green-button mpr-width-px-140 mpr-select-folder-button"
                  type="button"
                  (click)="showDestinationFolderPickerDialog()"
                >
                  Select Folder
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mpr-form-block">
          <div class="mpr-form-block-field">
            <label for="scheduleName" class="mpr-form-block-field-label"
              >Schedule Name<sup>*</sup>
              <button class= "mpr-btn-transparency"
                [mtxTooltip]="scheduleNameInfo" 
                [mprMtxTooltipAriaDescribedBy]="scheduleNameInfo" 
                #tooltip="mtxTooltip"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
            </label>
            <mat-form-field
              floatLabel="never"
              class="mpr-width-percentage-100"
              [hideRequiredMarker]="hideRequiredMarker"
              appearance="outline"
            >
              <input
                id="scheduleName"
                formControlName="scheduleName"
                class="mpr-form-block-field-input"
                matInput
                placeholder="Enter Schedule Name"
                maxlength="100"
              />
            </mat-form-field>
            <mat-error
              class="padding"
              *ngIf="editScheduleForm.controls['scheduleName'].touched && editScheduleForm.controls['scheduleName'].errors?.['required']"
            >
              Please enter schedule name.</mat-error
            >
            <mat-error
              class="padding"
              *ngIf="editScheduleForm.controls['scheduleName'].touched && editScheduleForm.controls['scheduleName'].errors?.['maxlength']"
            >
              Max 100 characters allowed</mat-error
            >
            <div
              class="mpr-form-block-field-wordcount"
              [ngClass]="
                editScheduleForm.controls['scheduleName'].value.length === 100
                  ? 'mpr-error-color'
                  : ''
              "
              >{{ editScheduleForm.controls['scheduleName'].value.length }}/100
              characters</div
            >
          </div>
        </div>
        <div class="mpr-form-block">
          <div class="mpr-form-block-field">
            <div
              class="d-flex mpr-form-fields-row mpr-flex-alignitems-start mpr-form-frequency-items"
            >
              <div class="mpr-form-fields-row-items">
                <div class="mpr-form-block-field-label"
                  >Select Frequency<sup>*</sup></div
                >
                <mat-form-field
                  class="mpr-mat-select-field"
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                >
                  <mat-select
                    formControlName="frequency"
                    panelClass="mpr-select-option-panel"
                    placeholder="Select Frequency"
                    (selectionChange)="frequencyChanged()"
                  >
                    <mat-option
                      *ngFor="let frequency of frequencyList"
                      [value]="frequency"
                    >
                      {{ frequency }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="editScheduleForm.controls['frequency'].touched && editScheduleForm.controls['frequency'].errors?.['required']"
                >
                  Please select frequency.</mat-error
                >
                <div
                  class="mpr-form-block-field-note-text"
                  *ngIf="
                    editScheduleForm.controls['frequency'].value ===
                    frequencyListEnum.HOURLY
                  "
                >
                  The Job Will Start At 00 Mins Of The Next Hour.
                  <mpr-utc-time-zone></mpr-utc-time-zone
                ></div>
              </div>
              <div
                class="mpr-form-fields-row-items"
                *ngIf="
                  editScheduleForm.controls['frequency'].value ===
                  frequencyListEnum.WEEKLY
                "
              >
                <div class="mpr-form-block-field-label"
                  >Day Of The Week<sup>*</sup></div
                >
                <mat-form-field
                  class="mpr-mat-select-field"
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                >
                  <mat-select
                    formControlName="frequencyDetail"
                    panelClass="mpr-select-option-panel"
                    placeholder="Select day of the week"
                  >
                    <mat-option
                      *ngFor="let day of daysOfTheWeek"
                      [value]="day.value"
                    >
                      {{ day.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="editScheduleForm.controls['frequencyDetail'].touched && editScheduleForm.controls['frequencyDetail'].errors?.['required']"
                >
                  Please select day of the week.</mat-error
                >
              </div>
              <div
                class="mpr-form-fields-row-items"
                *ngIf="
                  editScheduleForm.controls['frequency'].value ===
                  frequencyListEnum.MONTHLY
                "
              >
                <div class="mpr-form-block-field-label"
                  >Day Of The Month<sup>*</sup></div
                >
                <mat-form-field
                  class="mpr-mat-select-field"
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                >
                  <mat-select
                    formControlName="frequencyDetail"
                    panelClass="mpr-select-option-panel"
                    placeholder="Select day of the month"
                  >
                    <mat-option
                      *ngFor="let day of daysOfTheMonth"
                      [value]="day"
                    >
                      {{ day }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="editScheduleForm.controls['frequencyDetail'].touched && editScheduleForm.controls['frequencyDetail'].errors?.['required']"
                >
                  Please select day of the month.</mat-error
                >
              </div>
              <div
                class="mpr-form-fields-row-items"
                *ngIf="
                  editScheduleForm.controls['frequency'].value ===
                  frequencyListEnum.YEARLY
                "
              >
                <label for="selectDate" class="mpr-form-block-field-label"
                  >Select Date<sup>*</sup></label
                >
                <mat-form-field
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                >
                  <input
                    id="selectDate"
                    formControlName="frequencyDetail"
                    class="mpr-form-block-field-input"
                    matInput
                    placeholder="MM/DD"
                  />
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="editScheduleForm.controls['frequencyDetail'].touched && editScheduleForm.controls['frequencyDetail'].errors?.['required']"
                >
                  Please enter date of the year.</mat-error
                >
                <mat-error
                  class="padding"
                  *ngIf="editScheduleForm.controls['frequencyDetail'].touched && editScheduleForm.controls['frequencyDetail'].errors?.['dateValidator']"
                >
                  Please enter valid date in the format MM/DD</mat-error
                >
              </div>
              <div
                class="mpr-form-fields-row-items"
                *ngIf="
                  editScheduleForm.controls['frequency'].value !== '' &&
                  editScheduleForm.controls['frequency'].value !==
                    frequencyListEnum.HOURLY
                "
              >
                <div class="mpr-form-block-field-label"
                  >Hour Of The Day<sup>*</sup
                  ><mpr-utc-time-zone></mpr-utc-time-zone
                ></div>
                <mat-form-field
                  class="mpr-mat-select-field"
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                >
                  <mat-select
                    formControlName="hourOfTheDay"
                    panelClass="mpr-select-option-panel"
                    placeholder="Select hour of the day"
                  >
                    <mat-option
                      *ngFor="let hr of hoursOfTheDay"
                      [value]="hr.value"
                    >
                      {{ hr.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="editScheduleForm.controls['hourOfTheDay'].touched && editScheduleForm.controls['hourOfTheDay'].errors?.['required']"
                >
                  Please select hour of the day</mat-error
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="mpr-buttons-row" fxLayout="row" fxLayoutAlign="flex-end">
      <div
        class="mpr-user-private-space-details"
        *ngIf="editScheduleObj.datasetName"
      >
        <div class="mpr-private-space">
          <div class="mpr-private-space-text">
            <div>
              To edit the metadata for this recurring schedule, please go to
            </div>
            <div>
              <strong
                ><a
                  *ngIf="ckanMetaDataUrl !== ''"
                  href="{{ ckanMetaDataUrl }}"
                  >{{ ckanMetaDataUrl }}</a
                ><img
                  class="linkImg"
                  src="assets/images/hyperLink.svg"
                  alt="MetaData Link"
                  (click)="goToCkanMetadata()"
              /></strong>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          mat-stroked-button
          class="mpr-button mpr-blue-button mpr-cancel-button btn-margin"
          [routerLink]="returnPath"
        >
          Cancel
        </button>
      </div>
      <div>
        <button
          mat-button
          type="submit"
          [disabled]="isSubmitDisabled()"
          class="mpr-button mpr-yellow-button btn-margin"
          (click)="submit()"
        >
          Save Schedule
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #selectDataSourceInfo>
  <div class="mpr-width-px-300" id="mpr-datasource-class">
    Select Data Source from where you want to upload the file.
  </div>
</ng-template>
<ng-template #folderPathInfo>
  <div class="mpr-width-px-300" id="mpr-path-class">
    Select a folder from where you want to upload files in a recurring way.
  </div>
</ng-template>
<ng-template #scheduleNameInfo>
  <div class="mpr-width-px-300" id="mpr-name-class">
    Brief description of the job E.g Fetch Daily Covid Data
  </div>
</ng-template>
<ng-template #destinationFolderPopover>
  <div class="mpr-width-px-160" id="mpr-select-folder">Select destination folder</div>
</ng-template>
