import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public appLogoText = 'Data Library Platform';
  public brandName = 'Mathematica';
  public loaderText = '';
  private appName = 'Data Library Platform';
  constructor() {
    this.loaderText = `${this.brandName} - ${this.appName}`;
  }
}
