import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { 
  ConnectionModel,
  SkipMainContentService,
  OneTimeUploadHistory,
  RecurringUploadHistory,
} from '@shared';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {
  ConnectionDataStore,
  UploadStatusesEnum,
  UploadTypeEnum,
  StackbarColorsEnum,
  JobOperationTypeEnums,
} from '@core';
import { Clipboard } from '@angular/cdk/clipboard';
import { RecurringJobDetailsState } from 'app/state/uploads/recurring-job-details/recurring-job-details.state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OnetimeJobDetailsState } from 'app/state/uploads/onetime-job-details/onetime-job-details.state';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import {
  ResetOneTimeJobDetails,
  SetOneTimeJobDetails,
} from 'app/state/uploads/onetime-job-details/onetime-job-details.actions';
import {
  ResetRecurringJobDetails,
  SetRecurringJobDetails,
} from 'app/state/uploads/recurring-job-details/recurring-job-details.action';
import * as constants from '@core/constants';
import { environment } from '@env/environment';
import { Observable, withLatestFrom } from 'rxjs';

import {
  CheckDatasetStatus,
  DatasetStatusState,
  DatasetStatusModel,
  Message,
  FeatureFlagsState,
  LoggedinUserState,
} from 'app/state/';
import { MprReadyFile } from 'app/uploads/interfaces';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';

@Component({
  selector: 'mpr-staging-job-details',
  templateUrl: './staging-job-details.component.html',
  styleUrls: ['./staging-job-details.component.scss'],
})
@UntilDestroy({ checkProperties: true })
export class StagingJobDetailsComponent implements OnInit, OnDestroy {
  @Select(DatasetStatusState.getDatasetStatus)
  public datasetStatus$!: Observable<DatasetStatusModel>;
  @Select(FeatureFlagsState.returnFeatureFlags)
  public featureFlags$!: Observable<FeatureFlagsStateModel>;
  @Select(OnetimeJobDetailsState.getOneTimeJobDetails)
  public getOnetimeJobDetails$!: Observable<any>;
  @Select(RecurringJobDetailsState.getRecurringJobDetails)
  public getRecurringJobDetails$!: Observable<any>;
  @ViewChild('skipper') public skipper!: ElementRef;
  public chartColors: Array<string> = [];
  public chartData: ApexAxisChartSeries = [];
  public commonConstants = constants;
  public connectionDataStoreEnum = ConnectionDataStore;
  public contactUs = '';
  public detailsToDisplay = {
    recurring: [
      'scheduleName',
      'dataStore',
      'uploadType',
      'totalNoOfFiles',
      'runDate',
      'description',
      'jobStatus',
    ],
    onetime: [
      'userName',
      'dataStore',
      'uploadType',
      'totalNoOfFiles',
      'uploadDate',
      'description',
      'jobStatus',
    ],
  };
  public filesReadyTableDataSource: Array<MprReadyFile> = [];
  public isScanSkipped = false;
  public isTotalNoOfFilesZero = false;
  public jobStatus: UploadStatusesEnum = UploadStatusesEnum.BLANK;
  public loggedinUserEmailDomain = '';
  public oneTimeJobDetails: OneTimeUploadHistory;
  public quarantineFilesColumns = ['fileName'];
  public quarantinedFiles: Array<MprReadyFile> = [];
  public readyFilesColumns = ['fileName'];
  public recurringJobDetails: RecurringUploadHistory;
  public selectedDatasetResponse: Message;
  public selectedDetailType: any = [];
  public selectedJobId: string;
  public selectedUploadType: string;
  public showLoader = true;
  public skippedFiles: Array<MprReadyFile> = [];
  public skippedFilesColumns = ['fileName'];
  public unsupportedFileTypes = ['EXE', 'COM', 'MSI', 'BAT', 'CGI']; // TODO: Will soon move to a externally loaded API / JSON
  public uploadStatusesEnum = UploadStatusesEnum;
  public uploadTypeEnum = UploadTypeEnum;
  public workspaceServiceNowUrl = environment.workspaceChangeServiceNowUrl;

  constructor(
    private clipboard: Clipboard,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private skipMainContentService: SkipMainContentService,
  ) {
    this.selectedUploadType = route.snapshot.params['uploadType'];
    this.selectedJobId = route.snapshot.params['stagingJobId'];
    this.oneTimeJobDetails = {
      uploadDate: '',
      userName: '',
      dataStore: '',
      description: '',
      destinationFolder: '',
      noOfSkippedFiles: '',
      totalNoOfFiles: '',
      uploadType: '',
      jobStatus: UploadStatusesEnum.BLANK,
      stagingJobId: '',
      filesReadyForSearch: [],
      quarantinedFiles: [],
      quarantinedSupportingFiles: [],
      skippedFiles: [],
      supportingFilesReadyForSearch: [],
      noOfQuarantinedFiles: '',
      noOfFilesReadyForSearch: '',
      noOfFilesReadyForStaging: '',
    };
    this.recurringJobDetails = {
      connectionName: '',
      uploadType: '',
      totalNoOfFiles: '',
      runDate: '',
      description: '',
      destinationFolder: '',
      jobStatus: UploadStatusesEnum.BLANK,
      scheduleName: '',
      filesReadyForSearch: [],
      quarantinedFiles: [],
      quarantinedSupportingFiles: [],
      skippedFiles: [],
      stagedFiles: [],
      supportingFilesReadyForSearch: [],
      noOfSkippedFiles: '',
      noOfQuarantinedFiles: '',
      noOfFilesReadyForSearch: '',
      noOfFilesReadyForStaging: '',
      dataStore: '',
      frequency: '',
      scheduleId: '',
      scheduleJobId: '',
    };
    this.selectedDatasetResponse = {
      actionDate: '',
      actionType: '',
      userName: '',
    };
  }

  public copyToClipBoard(s3URL: string): void {
    this.clipboard.copy(s3URL);
  }

  public isCompletedJob(jobStatus: UploadStatusesEnum): boolean {
    return [
      UploadStatusesEnum.COMPLETED,
      UploadStatusesEnum.COMPLETED_WITH_ERRORS,
    ].includes(jobStatus);
  }

  public isFailedJob(jobStatus: UploadStatusesEnum): boolean {
    return [UploadStatusesEnum.UPLOAD_FAILED].includes(jobStatus);
  }

  public isInProgressJob(jobStatus: UploadStatusesEnum): boolean {
    return [
      UploadStatusesEnum.UPLOAD_IN_PROGRESS,
      UploadStatusesEnum.UPLOAD_COMPLETED,
    ].includes(jobStatus);
  }

  public isQuarantineSupportingDoc(fileLocation: string): boolean {
    let result = false;
    if (
      this.selectedUploadType === this.uploadTypeEnum.RECURRING &&
      this.recurringJobDetails.quarantinedSupportingFiles
    ) {
      result =
        this.recurringJobDetails.quarantinedSupportingFiles.includes(
          fileLocation
        );
    } else if (
      this.selectedUploadType === this.uploadTypeEnum.ONETIME &&
      this.oneTimeJobDetails.quarantinedSupportingFiles
    ) {
      result =
        this.oneTimeJobDetails.quarantinedSupportingFiles.includes(
          fileLocation
        );
    }
    return result;
  }

  public isReadyFileSupportingDoc(fileLocation: string): boolean {
    let result = false;
    if (
      this.selectedUploadType === this.uploadTypeEnum.RECURRING &&
      this.recurringJobDetails.supportingFilesReadyForSearch
    ) {
      result =
        this.recurringJobDetails.supportingFilesReadyForSearch.includes(
          fileLocation
        );
    } else if (
      this.selectedUploadType === this.uploadTypeEnum.ONETIME &&
      this.oneTimeJobDetails.supportingFilesReadyForSearch
    ) {
      result =
        this.oneTimeJobDetails.supportingFilesReadyForSearch.includes(
          fileLocation
        );
    }
    return result;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetOneTimeJobDetails());
    this.store.dispatch(new ResetRecurringJobDetails());
  }
  ngOnInit(): void {
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.nativeElement.focus();
    });
    this.skippedFiles = [];
    this.quarantinedFiles = [];
    this.filesReadyTableDataSource = [];
    this.featureFlags$.subscribe((featureFlags: FeatureFlagsStateModel) => {
      this.loggedinUserEmailDomain = this.store.selectSnapshot(
        LoggedinUserState.getLoggedInUserEmail
      ).split('@')[1];
      const isLoggedInUserInternal = featureFlags.internalDomainList.findIndex((x) => x === this.loggedinUserEmailDomain) > -1;
      this.contactUs = isLoggedInUserInternal ? featureFlags.internalContactUsUrl : featureFlags.externalContactUsUrl;
    });
    if (this.selectedUploadType === this.uploadTypeEnum.ONETIME) {
    const jobOperationType =
      JobOperationTypeEnums.JOB_OPERATION_TYPE_UPLOAD_TO_STAGING;
    this.store
      .dispatch(new SetOneTimeJobDetails(this.selectedJobId, jobOperationType))
      .pipe(withLatestFrom(this.getOnetimeJobDetails$))
      .subscribe(([_, responseJson]) => {
        const response = { ...responseJson };
        const noOfStagedFiles = response.stagedFiles?.length;
        response.noOfFilesReadyForStaging = `${noOfStagedFiles}`;
        this.showLoader = false;
        this.oneTimeJobDetails = response;
        this.jobStatus = this.oneTimeJobDetails.jobStatus;
        this.isScanSkipped = this.oneTimeJobDetails.scan_skipped || true;
        this.skippedFiles =
          this.oneTimeJobDetails.skippedFiles?.slice(0, 30) || [];
        this.isTotalNoOfFilesZero = this.isFilesCountNoneOrZero(
          this.oneTimeJobDetails.totalNoOfFiles
        );
        this.quarantinedFiles = this.getFileList(
          this.oneTimeJobDetails.quarantinedFiles || []
        ).slice(0, 30);
        this.filesReadyTableDataSource = this.getFileList(
          this.oneTimeJobDetails.stagedFiles || []
        );
        if (
          !this.isFilesCountNoneOrZero(
            this.oneTimeJobDetails.noOfFilesReadyForStaging
          )
        ) {
          this.chartData.push({
            name: constants.FILES_READY_FOR_SEARCH,
            data: [Number(this.oneTimeJobDetails.noOfFilesReadyForStaging)],
          });
          this.chartColors.push(StackbarColorsEnum.READY);
        }
        if (
          !this.isFilesCountNoneOrZero(
            this.oneTimeJobDetails.noOfQuarantinedFiles
          )
        ) {
          this.chartData.push({
            name: constants.QUARANTINED_FILES,
            data: [Number(this.oneTimeJobDetails.noOfQuarantinedFiles)],
          });
          this.chartColors.push(StackbarColorsEnum.QUARANTINED);
        }
        if (
          !this.isFilesCountNoneOrZero(this.oneTimeJobDetails.noOfSkippedFiles)
        ) {
          this.chartData.push({
            name: constants.SKIPPED_FILES,
            data: [Number(this.oneTimeJobDetails.noOfSkippedFiles)],
          });
          this.chartColors.push(StackbarColorsEnum.SKIPPED);
        }
      });
    } else {
      const jobOperationType =
        JobOperationTypeEnums.RECURRING_JOB_DETAILS_FOR_STAGING;
      this.store
      .dispatch(new SetRecurringJobDetails(this.selectedJobId, jobOperationType))
      .pipe(withLatestFrom(this.getRecurringJobDetails$))
      .subscribe(([_, responseJson]) => {
        this.showLoader = false;
        const response = { ...responseJson };
        const noOfStagingFiles = response.stagedFiles?.length;
        response.noOfFilesReadyForStaging = `${noOfStagingFiles}`;
        this.recurringJobDetails = response;

        this.jobStatus = this.recurringJobDetails.jobStatus;
        this.isScanSkipped = this.recurringJobDetails.scan_skipped || true;
        this.skippedFiles =
          this.recurringJobDetails.skippedFiles?.slice(0, 30) || [];
        this.isTotalNoOfFilesZero = this.isFilesCountNoneOrZero(
          this.recurringJobDetails.totalNoOfFiles
        );
        this.quarantinedFiles = this.getFileList(
          this.recurringJobDetails.quarantinedFiles || []
        ).slice(0, 30);
        this.filesReadyTableDataSource = this.getFileList(
          this.recurringJobDetails.stagedFiles || []
        );
        if (
          !this.isFilesCountNoneOrZero(
            this.recurringJobDetails.noOfFilesReadyForStaging
          )
        ) {
          this.chartData.push({
            name: constants.FILES_READY_FOR_SEARCH,
            data: [Number(this.recurringJobDetails.noOfFilesReadyForStaging)],
          });
          this.chartColors.push(StackbarColorsEnum.READY);
        }
        if (
          !this.isFilesCountNoneOrZero(
            this.recurringJobDetails.noOfQuarantinedFiles
          )
        ) {
          this.chartData.push({
            name: constants.QUARANTINED_FILES,
            data: [Number(this.recurringJobDetails.noOfQuarantinedFiles)],
          });
          this.chartColors.push(StackbarColorsEnum.QUARANTINED);
        }
        if (
          !this.isFilesCountNoneOrZero(
            this.recurringJobDetails.noOfSkippedFiles
          )
        ) {
          this.chartData.push({
            name: constants.SKIPPED_FILES,
            data: [Number(this.recurringJobDetails.noOfSkippedFiles)],
          });
          this.chartColors.push(StackbarColorsEnum.SKIPPED);
        }
      });
  }}

  public proceedToDestinationFolder(stagingUrl: string): void {
    this.router.navigate([
      '/filemanager',
      btoa(stagingUrl.replace('s3://', '')),
    ]);
  }

  public routeToAddFiles(): void {
    const jobId =
      this.selectedUploadType === this.uploadTypeEnum.RECURRING
        ? this.recurringJobDetails.scheduleJobId
        : this.oneTimeJobDetails.jobId;
    this.router.navigate([`/uploads/onetime/job/${jobId}`]);
  }

  public showFilesGridOrError(
    jobStatus: UploadStatusesEnum,
    selectedUploadType: string
  ): boolean {
    // returns true for error and false for grid
    return selectedUploadType === this.uploadTypeEnum.ONETIME
      ? this.isFailedJob(jobStatus)
      : this.isFilesCountNoneOrZero(
          this.recurringJobDetails.noOfSkippedFiles
        ) &&
      this.isFilesCountNoneOrZero(
            this.recurringJobDetails.noOfFilesReadyForStaging
    );
  }

  public showNote(tableType: string): boolean {
    if (tableType === 'skippedFiles')
      return this.selectedUploadType === UploadTypeEnum.RECURRING
        ? Number(this.recurringJobDetails.noOfSkippedFiles) > 30
        : Number(this.oneTimeJobDetails.noOfSkippedFiles) > 30;
    else 
      return this.selectedUploadType === UploadTypeEnum.RECURRING
        ? Number(this.recurringJobDetails.noOfQuarantinedFiles) > 30
        : Number(this.oneTimeJobDetails.noOfQuarantinedFiles) > 30;
  }

  private getFileList(
    noOfFilesReadyForStaging: Array<string>
  ): Array<MprReadyFile> {
    const fileList: Array<MprReadyFile> = [];

    if (noOfFilesReadyForStaging.length > 0) {
      for (let index = 0; index < noOfFilesReadyForStaging.length; index++) {
        const s3Location = noOfFilesReadyForStaging[index] ?? '';
        const fileName = s3Location.split('/').pop() || '';
        fileList[index] = {
          fileName,
          s3Location,
        };
      }
      return fileList;
    }
    return fileList;
  }

  private isFilesCountNoneOrZero(count: string = ''): boolean {
    return count === '0' || count === constants.NONE || count === '';
  }
}
