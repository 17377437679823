import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ConnectionAddResponse,
  ConnectionModel,
  CommonResponseModel,
} from '@shared/interfaces';

import {
  ConnectionDataStore,
  ConnectionStatus,
  ConnectionType,
} from '@core/enums';
import { ConnectionService } from '@shared/services';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConnectionStateModel } from './connection-state-model';
import {
  SetSelectedConnection,
  GetAllConnections,
  AddConnection,
  DeleteConnection,
  UpdateConnection,
  ResetConnectionState,
} from './connection.actions';
import { GenericHttpResponse, MprHttpHeaderModal } from '@core/interfaces';

const stateDefaults = {
  connections: [],
  lastAddedConnection: {
    connectionId: '',
    box_auth_url: '',
  },
  loadingConnection: false,
  loadingConnections: false,
  selectedConnectionId: '',
  selectedConnection: {
    connectionStatus: ConnectionStatus.INACTIVE,
    projectId: '',
    dataStore: ConnectionDataStore.BOX,
    createdDate: '',
    connectionName: '',
    connectionType: ConnectionType.INGESTION,
    emailId: '',
    connectionId: '',
    modifiedDate: '',
    createdBy: '',
    createdByName: '',
    modifiedBy: '',
    accountId: '',
    prefixName: '',
    bucketName: '',
  },
};

@State<ConnectionStateModel>({
  name: 'ConnectionState',
  defaults: stateDefaults
})
@Injectable()
export class ConnectionState {
  constructor(private connectionService: ConnectionService) { }

  @Action(AddConnection)
  public addNewConnection(
    ctx: StateContext<ConnectionStateModel>,
    { connectionDetails }: AddConnection
  ): Observable<ConnectionAddResponse> {
    return this.connectionService.addConnection(connectionDetails).pipe(
      tap((lastAddedConnection) => {
        ctx.patchState({
          lastAddedConnection,
        });
      })
    );
  }

  @Action(DeleteConnection)
  public delete(
    ctx: StateContext<ConnectionStateModel>,
    { connectionId }: DeleteConnection
  ): Observable<GenericHttpResponse> {
    return this.connectionService.deleteConnection(connectionId);
  }

  @Selector()
  public static getAddedConnection(
    state: ConnectionStateModel
  ): ConnectionAddResponse {
    return state.lastAddedConnection;
  }

  @Selector()
  public static getAllActiveExportConnections(
    state: ConnectionStateModel
  ): ConnectionModel[] {
    const connections = state.connections.filter(
      (connection) =>
        connection.connectionStatus === ConnectionStatus.ACTIVE &&
        connection.connectionType === ConnectionType.OUTPUT
    );
    return connections;
  }

  @Selector()
  public static getAllActiveIngestionConnections(
    state: ConnectionStateModel
  ): ConnectionModel[] {
    return state.connections.filter(
      (connection) =>
        connection.connectionStatus === ConnectionStatus.ACTIVE &&
        connection.connectionType === ConnectionType.INGESTION
    );
  }

  @Selector()
  public static getConnectionList(
    state: ConnectionStateModel
  ): ConnectionModel[] {
    return state.connections.filter(
      (connection) => connection.connectionStatus === ConnectionStatus.ACTIVE
    );
  }

  @Selector()
  public static getConnectionLoading(state: ConnectionStateModel): boolean {
    return state.loadingConnection;
  }

  @Action(GetAllConnections)
  public getConnections(
    { patchState }: StateContext<ConnectionStateModel>, { ...requestHeaders }: GetAllConnections): Observable<ConnectionModel[]> {
    patchState({ loadingConnections: true });
    return this.connectionService.fetchConnections(requestHeaders.requestHeaders).pipe(
      tap((connections) => {
        patchState({
          connections,
          loadingConnections: false,
        });
      })
    );
  }

  @Selector()
  public static getConnectionsLoading(state: ConnectionStateModel): boolean {
    return state.loadingConnections;
  }

  @Selector()
  public static getSelectedConnection(
    state: ConnectionStateModel
  ): ConnectionModel {
    return state.selectedConnection;
  }

  @Selector()
  public static getSelectedConnectionId(state: ConnectionStateModel): string {
    return state.selectedConnection.connectionId;
  }

  @Action(ResetConnectionState)
  public resetProjectState({ patchState }: StateContext<ConnectionStateModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(SetSelectedConnection)
  public setSelectedConnection(
    { patchState }: StateContext<ConnectionStateModel>,
    { selectedConnection, requestHeaders }: SetSelectedConnection
  ): Observable<ConnectionModel> {
    const selectedConnectionId = selectedConnection.connectionId;
    // If local or DLS3 connection we dont need token 
    if (selectedConnection.connectionId === ConnectionDataStore.LOCAL || selectedConnection.connectionId === ConnectionDataStore.DL_S3 || selectedConnection.connectionId === '') {
      patchState({
        selectedConnection,
        selectedConnectionId,
        loadingConnection: false,
      });
      return of(selectedConnection);
    }

    patchState({ loadingConnection: true });

    return this.connectionService.fetchConnection(selectedConnectionId, requestHeaders).pipe(
      tap((selectedConnectionData) => {
        patchState({
          selectedConnection: selectedConnectionData,
          selectedConnectionId,
          loadingConnection: false,
        });
      })
    );
  }

  @Action(UpdateConnection)
  public updateConnection(
    ctx: StateContext<ConnectionStateModel>,
    { connectionUpdateDetails }: UpdateConnection
  ): Observable<CommonResponseModel> {
    return this.connectionService
      .updateConnection(connectionUpdateDetails)
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const connectionsList = JSON.parse(JSON.stringify(state.connections));
          const index = connectionsList.findIndex(
            (list: { connectionId: string }) =>
              list.connectionId === connectionUpdateDetails.connectionId
          );
          connectionsList[index] = connectionUpdateDetails;
          ctx.patchState({
            connections: connectionsList,
          });
        })
      );
  }
}
