import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { SetUploadsMetaData, UserProjectState } from 'app/state';
import { noWhitespaceValidator, publicDataValidator, startDateEndDateValidator } from '@shared';
import { ActivatedRoute } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelectChange } from '@angular/material/select';
import { MetadataOptionsState } from 'app/state/metadata-options/metadata-options.state';
import { Observable } from 'rxjs';
import { MetaDataFromControls, OneTimeScheduleEnum } from '@core/enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SetScheduleMetaData } from 'app/state/uploads/schedule/schedule.action';
import { ProjectState } from 'app/state/project/project.state';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-enter-metadata',
  templateUrl: './enter-metadata.component.html',
  styleUrls: ['./enter-metadata.component.scss'],
})
export class EnterMetadataComponent implements OnInit {
  @ViewChild('metaTagsInput')
  public metaTagsInput!: ElementRef<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Select(MetadataOptionsState.getAllAggregationLevels)
  public aggregationLevelsList$?: Observable<string[]>;
  @Select(MetadataOptionsState.getAllDataAccessClassification)
  public dataAccessClassificationsLookup$!: Observable<string[]>;
  @Select(MetadataOptionsState.getAllFocusAreas)
  public focusAreas$?: Observable<string[]>;
  @Select(MetadataOptionsState.getAllGeographicRegion)
  public geoRegions$?: Observable<string[]>;
  @Input() public oneTimeOrSchedule = '';
  @Output() public proceedToUploadOrSchedule = new EventEmitter<any>();
  @Select(ProjectState.getProjectRestrictedDataGroups)
  public projectRestrictedUseDataList$?: Observable<string[]>;
  @Select(UserProjectState.getSelectedProjectUserRestrictedDataUseGroups)
  public userRestrictedDataUseGroups$!: Observable<string[]>;
  public addOnBlur = true;
  public dataAccessDefaultGroup = 'restricted';
  public dataAccessClassificationList: Array<string> = [];
  public dataAccessClassificationDisplayText = '';
  public hasDefaultRestrictedGroup = true;
  public hideRequiredMarker = true;
  public maxDate = new Date();
  public selectedAggregations: Array<string> = [];
  public metaDataForm: FormGroup;
  public metaTagsList: Array<string> = [];
  public userRestrictedGroups: Array<string> = [];
  public projectRestrictedGroups: Array<string> = [];
  public projectRestrictedUseDataText = 'Project Restricted Use Data';
  public returnPath: string;
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public showDataAccessGroupsDropdown = false;
  public showGeoRegionCustomValue = false;
  private dataSourceYearValidators = [
    Validators.required,
    Validators.min(1000),
    Validators.max(9999),
  ];

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private fb: FormBuilder
  ) {
    this.returnPath = route.snapshot.data['back'];
    this.metaDataForm = this.fb.group({
      datasetName: ['', [Validators.required, noWhitespaceValidator()]],
      shortDescription: [''],
      aggregationLevel: [[], Validators.required],
      focusArea: ['', Validators.required],
      dataAccessClassification: [
        '',
        [
          Validators.required,
          publicDataValidator(MetaDataFromControls.DATA_ACCESS_CLASSIFICATION),
        ],
      ],
      dataTags: ['', Validators.maxLength(100)],
      restrictedDataUseGroups: [],
      geographicRegion: [''],
      geoRegionCustomValue: ['', Validators.maxLength(100)],
      dateCreated: [''],
      dataPurchased: [null, Validators.required],
      pii: [
        null,
        [Validators.required, publicDataValidator(MetaDataFromControls.PII)],
      ],
      noDataSourceYearsApply: [false],
      dataSourceYearsEnd: [
        '',
        [
          ...this.dataSourceYearValidators,
          startDateEndDateValidator(MetaDataFromControls.DATA_SOURCE_YEARS_END),
        ],
      ],
      dataSourceYearsStart: [
        '',
        [
          ...this.dataSourceYearValidators,
          startDateEndDateValidator(
            MetaDataFromControls.DATA_SOURCE_YEARS_START
          ),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.projectRestrictedUseDataList$?.subscribe((groups: Array<string>) => {
      this.projectRestrictedGroups = groups.filter(
        (g) => g !== this.dataAccessDefaultGroup
      );
    });
    this.dataAccessClassificationsLookup$.subscribe(
      (classifications: Array<string>) => {
        this.dataAccessClassificationList = classifications;
      }
    );
    this.userRestrictedDataUseGroups$.subscribe((grps: Array<string>) => {
      this.hasDefaultRestrictedGroup = grps.includes(
        this.dataAccessDefaultGroup
      );
      this.userRestrictedGroups = grps;
    });
  }
  public addChip(event: MatChipInputEvent): void {
    const formControl = this.metaDataForm.controls;
    if (!formControl[MetaDataFromControls.DATA_TAGS].errors) {
      const value = (event.value || '').trim();
      if (value.length <= 100) {
        if (value) {
          this.metaTagsList.push(value);
        }
        // Clear the input value
        event.chipInput?.clear();
        formControl[MetaDataFromControls.DATA_TAGS].setValue(null);
      }
    }
  }

  public changeSelection(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedAggregations.indexOf(event.option.viewValue) >= 0) {
      this.removeChip(event.option.viewValue);
    } else {
      this.selectedChip(event);
    }
  }

  public dataAccessClassificationChange(change: MatSelectChange): void {
    const dataAccessClassificationControl =
      this.metaDataForm.controls[
        MetaDataFromControls.DATA_ACCESS_CLASSIFICATION
      ];
    const restrictedDataUseGrpControl =
      this.metaDataForm.controls[
        MetaDataFromControls.RESTRICTED_DATA_USE_GROUP
      ];
    dataAccessClassificationControl.markAsPristine();
    dataAccessClassificationControl.setErrors(null);
    dataAccessClassificationControl.setValidators([
      Validators.required,
      publicDataValidator(MetaDataFromControls.DATA_ACCESS_CLASSIFICATION),
    ]);
    dataAccessClassificationControl.updateValueAndValidity();
    const value = change.value;
    this.dataAccessClassificationDisplayText = value;
    const isProjectRestrictedUseDataSelected =
      value === this.projectRestrictedUseDataText;
    if (this.dataAccessClassificationList.indexOf(value) !== -1) {
      if (isProjectRestrictedUseDataSelected) {
        if (this.hasDefaultRestrictedGroup) {
          restrictedDataUseGrpControl.setValue(this.dataAccessDefaultGroup);
        } // restricted group is not part of user restricted group
        else {
          dataAccessClassificationControl.markAsDirty();
          dataAccessClassificationControl.setErrors({
            showNoDefaultGrpError: true,
          });
        }
      }
    } else {
      this.dataAccessClassificationDisplayText = `${this.projectRestrictedUseDataText} - ${value}`;
      // selected group is user restricted groups
      if (this.userRestrictedGroups.indexOf(value) !== -1) {
        restrictedDataUseGrpControl.setValue(value);
        dataAccessClassificationControl.setValue(
          this.projectRestrictedUseDataText
        );
      }
      // selected group is not user restricted group
      else {
        dataAccessClassificationControl.markAsDirty();
        dataAccessClassificationControl.setErrors({
          showNoDefaultGrpError: true,
        });
      }
    }
  }
  public geographicRegionChanged(): void {
    const geographicRegionControl =
      this.metaDataForm.controls[MetaDataFromControls.GEOGRAPHIC_REGION];
    const geoRegionCustomValueControl =
      this.metaDataForm.controls[MetaDataFromControls.GEO_REGION_CUSTOM_VALUE];
    this.showGeoRegionCustomValue = geographicRegionControl.value === 'Others';
    if (!this.showGeoRegionCustomValue) {
      geoRegionCustomValueControl.setValue('');
      geoRegionCustomValueControl.clearValidators();
    } else {
      geoRegionCustomValueControl.setValidators([
        Validators.maxLength(100),
        Validators.required,
      ]);
    }
    geoRegionCustomValueControl.updateValueAndValidity();
  }

  public noSpecificYearRangeChanged(): void {
    const dataSourceYrsStartControl =
      this.metaDataForm.controls[MetaDataFromControls.DATA_SOURCE_YEARS_START];
    const dataSourceYrsEndControl =
      this.metaDataForm.controls[MetaDataFromControls.DATA_SOURCE_YEARS_END];
    if (
      this.metaDataForm.controls[
        MetaDataFromControls.NO_DATA_SOURCE_YEARS_APPLY
      ].value
    ) {
      this.disableYearControl(dataSourceYrsStartControl);
      this.disableYearControl(dataSourceYrsEndControl);
    } else {
      this.enableYearControl(MetaDataFromControls.DATA_SOURCE_YEARS_START);
      this.enableYearControl(MetaDataFromControls.DATA_SOURCE_YEARS_END);
    }
  }

  public removeAggregation(removeAgg: string): void {
    const selectedAggregations = this.metaDataForm.controls[
      MetaDataFromControls.AGGREGATION_LEVEL
    ].value as string[];
    this.removeFirst(selectedAggregations, removeAgg);
    this.metaDataForm.controls[MetaDataFromControls.AGGREGATION_LEVEL].setValue(
      selectedAggregations
    ); // To trigger change detection
  }

  public removeChip(chip: string): void {
    const index = this.metaTagsList.indexOf(chip);

    if (index >= 0) {
      this.metaTagsList.splice(index, 1);
    }
  }

  public removeFocusArea(removeFocusArea: string): void {
    const selectedFocusAreas = this.metaDataForm.controls[
      MetaDataFromControls.FOCUS_AREA
    ].value as string[];
    this.removeFirst(selectedFocusAreas, removeFocusArea);
    this.metaDataForm.controls[MetaDataFromControls.FOCUS_AREA].setValue(
      selectedFocusAreas
    ); // To trigger change detection
  }

  public selectedChip(event: MatAutocompleteSelectedEvent): void {
    this.metaTagsList.push(event.option.viewValue);
    this.metaTagsInput.nativeElement.value = '';
    this.metaDataForm.controls[MetaDataFromControls.DATA_TAGS].setValue(null);
  }

  public triggerFileUpload(): void {
    const formControl = this.metaDataForm.controls;
    formControl[MetaDataFromControls.DATA_TAGS].setValue(
      this.metaTagsList.join(',')
    );
    if (
      formControl[MetaDataFromControls.GEOGRAPHIC_REGION].value === 'Others'
    ) {
      formControl[MetaDataFromControls.GEOGRAPHIC_REGION].setValue(
        formControl[MetaDataFromControls.GEO_REGION_CUSTOM_VALUE].value
      );
    }
    formControl[MetaDataFromControls.RESTRICTED_DATA_USE_GROUP].setValue([
      formControl[MetaDataFromControls.RESTRICTED_DATA_USE_GROUP].value,
    ]);
    formControl[MetaDataFromControls.DATA_SOURCE_YEARS_END].setValue(
      formControl[MetaDataFromControls.DATA_SOURCE_YEARS_END].value.toString()
    );
    formControl[MetaDataFromControls.DATA_SOURCE_YEARS_START].setValue(
      formControl[MetaDataFromControls.DATA_SOURCE_YEARS_START].value.toString()
    );
    formControl[MetaDataFromControls.DATA_SET_NAME].setValue(
      formControl[MetaDataFromControls.DATA_SET_NAME].value.trim()
    );
    if (this.oneTimeOrSchedule === OneTimeScheduleEnum.ONETIME) {
      this.store.dispatch(new SetUploadsMetaData(this.metaDataForm.value));
    } else {
      this.store.dispatch(new SetScheduleMetaData(this.metaDataForm.value));
    }
    this.proceedToUploadOrSchedule.emit();
  }

  private disableYearControl(control: AbstractControl): void {
    control.setValue('');
    control.disable();
    control.clearValidators();
    control.updateValueAndValidity();
  }

  private enableYearControl(formField: string): void {
    const control = this.metaDataForm.controls[formField];
    control.enable();
    control.setValidators([
      Validators.required,
      Validators.min(1000),
      Validators.max(9999),
      startDateEndDateValidator(formField),
    ]);
    control.updateValueAndValidity();
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
}
