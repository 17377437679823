import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserProjectRoleEnum } from '@core/enums';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { User, UserProject } from '@theme/interfaces';
import {
  FeatureFlagsState,
  UserProjectState,
  UserState,
  SetDashboardLoaderState,
  ChangeSFRoleState,
} from 'app/state';
import { filter, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';
import SideMenuList from './side-menu-list';
import * as constants from '@core/constants';
import { AuthService } from '@core/services';
import { LoadSelectedProjectMetadata } from 'app/state/project/project.actions';
import { ProjectState } from 'app/state/project/project.state';
import { SkipMainContentService } from '@shared';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { ChangeSFDefaultRole } from 'app/state/change-sf-role/change-sf-role.action';
import { CommonResponseModel } from '@shared/interfaces';
import { LoadUserInfodata } from 'app/state/user-info/user-info.actions';
import { UserInfoState } from 'app/state/user-info/user-info.state';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@UntilDestroy({ checkProperties: true })
export class SidemenuComponent implements OnInit, OnDestroy {
  @Select(ChangeSFRoleState.getChangeRoleStatus)
  public changeSFRoleStatus$!: Observable<CommonResponseModel>;
  @Select(UserState.getDWFeatureFlag)
  public dwFeatureFlag$!: Observable<string>;
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  @Select(ProjectState.getSnowflakeProvisioningStatus)
  public snowflakeProvisioningStatus$!: Observable<string>;
  @Select(UserInfoState.getCurrentUserRole)
  private userRole$?: Observable<string>;
  public currentRoute = '/dashboard';
  public endPointUrl = `${environment.ckanUrl}${environment.ckanLoginPath}`;
  public isDWMenuClicked = false;
  public menuList: SideMenuList[] = [];
  public platformAdminText = constants.PLATFORM_ADMIN_CONSOLE;
  public selectedRoleName!: string;
  public sfRoleResponse: CommonResponseModel = {
    status_code: 0,
    message: '',
  };
  private adminConsoleMenuItem = {
    dataTestId: 'app-side-menu-item-admin-console',
    icon: 'admin-console',
    text: constants.ADMIN_CONSOLE,
    roles: [UserProjectRoleEnum.ADMIN],
    routePath: '/admin',
    isEnabled: true,
    externalUrl: false,
  };

  // Will come from a call to a service in future when role / sso worksout.
  private commonMenuList: SideMenuList[] = [
    {
      dataTestId: 'app-side-menu-item-home',
      icon: 'home',
      text: constants.HOME,
      routePath: '/dashboard',
      isEnabled: true,
      externalUrl: false,
    },
    {
      dataTestId: 'app-side-menu-item-catalog',
      icon: 'catalog',
      text: constants.SEARCH_DATA_CATALOG,
      isEnabled: true,
      externalUrl: true,
      routePath: `${environment.ckanUrl}${environment.ckanLoginPath}`,
      target: '_self',
    },
  ];
  private destroyed$ = new Subject<boolean>();
  private dwMenuItem = {
    dataTestId: 'app-side-menu-item-Data_Warehouse',
    icon: 'Data_Warehouse',
    text: constants.DATA_WAREHOUSE,
    roles: [
      UserProjectRoleEnum.DEVELOPER,
      UserProjectRoleEnum.ADMIN,
      UserProjectRoleEnum.RESEARCHER,
      UserProjectRoleEnum.EXTERNAL_DEVELOPER,
      UserProjectRoleEnum.EXTERNAL_RESEARCHER,
    ],
    routePath: `${environment.snowflakeUrl}`,
    isEnabled: true,
    externalUrl: true,
    target: '_blank',
  };

  private researcherMenuItems = {
    dataTestId: 'app-side-menu-item-workspace-aws',
    icon: 'workspace',
    text: constants.WORKSPACE,
    roles: [
      UserProjectRoleEnum.RESEARCHER,
      UserProjectRoleEnum.EXTERNAL_RESEARCHER,
    ],
    routePath: '/workspace/aws',
    isEnabled: true,
  };

  // This will later come from backend : POST SSO.
  private roleBasedMenuItems = [
    {
      dataTestId: 'app-side-menu-item-upload',
      icon: 'upload',
      text: constants.PUBLISH_TO_CATALOG,
      roles: [
        UserProjectRoleEnum.RESEARCHER,
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
        UserProjectRoleEnum.EXTERNAL_RESEARCHER,
      ],
      routePath: '/uploads',
      isEnabled: false,
      externalUrl: false,
    },
    {
      dataTestId: 'app-side-menu-item-workspace',
      icon: 'workspace',
      text: constants.WORKSPACE,
      roles: [
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
      ],
      routePath: '/workspace',
      externalUrl: false,
    },
    {
      dataTestId: 'app-side-menu-item-filemanager',
      icon: 'filemanager',
      text: constants.FILE_MANAGER,
      roles: [
        UserProjectRoleEnum.RESEARCHER,
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
        UserProjectRoleEnum.EXTERNAL_RESEARCHER,
      ],
      routePath: '/filemanager',
    },
    {
      dataTestId: 'app-side-menu-item-exchange',
      icon: 'exchange',
      text: constants.MANAGE_DATA_CONNECTIONS,
      roles: [
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
      ],
      routePath: '/connections/manage',
      isEnabled: false,
      externalUrl: false,
    },
  ];

  private selectedProject!: UserProject;

  constructor(
    private router: Router,
    private authService: AuthService,
    public store: Store,
    private skipMainContentService: SkipMainContentService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        this.currentRoute = this.router.url;
      });
  }

  public isMenuItemDisabled(dataTestId: string): boolean {
    return (
      dataTestId === 'app-side-menu-item-workspace' &&
      !this.selectedProject?.serverUrls?.rstudio &&
      !this.selectedProject?.serverUrls?.jupyter
    );
  }
  public navigateToPlatFormAdminConsole(routePath: string): void {
    this.router.navigate([`${routePath}`]);
  }

  public navigateToRoute(
    routePath: string,
    isExternalUrl: boolean | undefined,
    target: string | undefined,
    title: string | undefined
  ): void {
    if (!isExternalUrl) {
      this.isDWMenuClicked = false;
      if (routePath === '') {
        this.skipMainContentService.fetchValueToOpenUploadPopup();
      } else {
        this.router.navigate([`${routePath}`]);
      }
    } else {
      if (title === constants.DATA_WAREHOUSE) {
        if (this.sfRoleResponse.status_code !== 0) {
          this.handleExternalUrls(routePath, target);
          this.isDWMenuClicked = false;
          this.skipMainContentService.showHideLoaderForOpeningDataWareHouse(
            false
          );
        } else {
          this.isDWMenuClicked = true;
          this.skipMainContentService.showHideLoaderForOpeningDataWareHouse(
            true
          );
          this.store.dispatch(
            new ChangeSFDefaultRole(
              this.selectedProject.projectId,
              this.selectedProject.roleName
            )
          );
        }
      } else {
        this.isDWMenuClicked = false;
        this.handleExternalUrls(routePath, target);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.store.dispatch(new SetDashboardLoaderState(true));
    const userId = this.authService.getUserId();
    if (userId) {
      this.store.dispatch(new LoadUserInfodata(userId));
      this.userRole$?.subscribe((roleName: string) => {
        this.selectedRoleName = roleName;
      });
    }

    this.menuList = this.commonMenuList.concat(this.roleBasedMenuItems);
    this.project$?.pipe(takeUntil(this.destroyed$)).subscribe((project) => {
      this.selectedProject = project;
      if (project.roleName !== UserProjectRoleEnum.BLANK) {
        this.store
          .dispatch(new LoadSelectedProjectMetadata())
          .pipe(withLatestFrom(this.snowflakeProvisioningStatus$))
          .subscribe(([_, snowFlakeProvisioningresponse]) => {
            //If we do not still have completed this wait.
            if (snowFlakeProvisioningresponse === '') return;

            const featureFlags: FeatureFlagsStateModel =
              this.store.selectSnapshot(FeatureFlagsState);
            const dwResponse: string = featureFlags.dwFeatureFlag;
            const dwTile =
              snowFlakeProvisioningresponse === 'active' &&
              dwResponse.toLowerCase() === 'true';
            this.roleBasedMenuItems.forEach((menu) => {
              menu.isEnabled = menu.roles.includes(project.roleName);
            });
            this.menuList = this.commonMenuList.concat(this.roleBasedMenuItems);
            if (dwTile && this.dwMenuItem.roles.includes(project.roleName))
              this.menuList.push(this.dwMenuItem);

            // Remove file manager if falg is false and also handle routing for upload
            const finalMenuItems: SideMenuList[] = [];
            this.menuList.forEach((menu) => {
              if (
                menu.icon === 'filemanager' &&
                featureFlags.isStagingAccessAvailable.toLowerCase() !== 'true'
              ) {
                return;
              } else {
                if (
                  menu.icon === 'upload' &&
                  featureFlags.isStagingAccessAvailable.toLowerCase() !== 'true'
                )
                  menu.routePath = '/uploads';

                finalMenuItems.push(menu);
              }
            });

            if (
              project.roleName === UserProjectRoleEnum.ADMIN &&
              !finalMenuItems.some(
                (menuItem) => menuItem.icon === 'admin-console'
              ) &&
              featureFlags.isProjectAdminConsole.toLowerCase() === 'true' &&
              this.selectedRoleName !== UserProjectRoleEnum.PLATFORM_ADMIN
            ) {
              finalMenuItems.push(this.adminConsoleMenuItem);
            }

            // If the researcher menu items are not already present that the user role matches add it.
            if (
              !finalMenuItems.some(
                (menuItem) =>
                  menuItem.icon === 'workspace' &&
                  menuItem.roles?.includes(UserProjectRoleEnum.RESEARCHER)
              ) &&
              this.researcherMenuItems.roles.includes(project.roleName)
            ) {
              const workSpaceIndex = finalMenuItems.findIndex(
                (menu) => menu.icon === 'workspace'
              );
              if (
                featureFlags.isAwsWorkspacesAvailable.toLowerCase() === 'true'
              ) {
                // Replace / do not add else we we have 2 workspace menus
                if (workSpaceIndex > -1)
                  finalMenuItems[workSpaceIndex] = this.researcherMenuItems;
              } else {
                finalMenuItems.splice(workSpaceIndex, 1);
              }
            }
            this.menuList = finalMenuItems;
            this.store.dispatch(new SetDashboardLoaderState(false));
          });
      } else {
        this.store.dispatch(new SetDashboardLoaderState(false));
      }
    });

    this.changeSFRoleStatus$.subscribe((res) => {
      this.sfRoleResponse = res;
      if (this.isDWMenuClicked) {
        if (res.status_code !== 0) {
          this.skipMainContentService.showHideLoaderForOpeningDataWareHouse(
            false
          );
          window.open(`${environment.snowflakeUrl}`, '_blank');
          this.isDWMenuClicked = false;
        }
      }
    });
  }

  private handleExternalUrls(
    externalUrl: string,
    target: string | undefined
  ): void {
    if (this.authService.check()) window.open(externalUrl, target);
    else this.authService.authenticate(true);
  }
}
