import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { RouteState } from 'app/state';
import { SetRoute } from 'app/state/route/route.actions';
import { map, Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService, private store: Store) {}

  public authenticate(
    url: string,
    attemptRefreshToken: boolean = false
  ): boolean | Observable<boolean> {
    // This is the last route the user was on (it will be falsy in case of a refresh / new window / tab)
    // If not falsy this means user actually was on a application page, show him session timeout and not login page.
    const showSessionTimeout = Boolean(
      this.store.selectSnapshot(RouteState.getRoute)
    );

    // Auth check.
    if (this.auth.check()) return true;

    // Set the Route
    this.store.dispatch(new SetRoute(url));

    if (attemptRefreshToken) return this.attemptRefreshToken();

    this.auth.authenticate(showSessionTimeout);

    return false;
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const isRedirectURLPresent = localStorage.getItem('redirectURL');
    if(!isRedirectURLPresent){
      if(window.location.href.includes(state.url))
      localStorage.setItem('redirectURL', state.url);
    }

    // route.queryParams is not reliably returning the params in certain scenarios hence used window
    return this.authenticate(
      state.url,
      window.location.href.includes('?loggedin=true')
    );
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    // route.queryParams is not reliably returning the params in certain scenarios hence used window
    return this.authenticate(
      state.url,
      window.location.href.includes('?loggedin=true')
    );
  }

  private attemptRefreshToken(): Observable<boolean> {
    return this.auth.refreshToken().pipe(map(() => true));
  }
}
