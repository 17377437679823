<div class="dialog-container">
  <mat-icon mat-dialog-close class="mpr-close-icon">close</mat-icon>
  <h1 mat-dialog-title class="mpr-dialog-title">Update Amazon Workspace</h1>
  <div>
    <div class="mpr-context">
      Newer version(s) of the Amazon WorkSpace are now available. Updating to
      the latest version provides the latest features, security updates, and bug
      fixes.
    </div>
    <div class="mpr-select">
      <div>
        Please select a workspace type (this will be used across all of your
        projects):
      </div>
      <div>
        <mat-radio-group
          aria-labelledby="mpr-radio-group-label"
          [(ngModel)]="updatedWorkspaceType"
        >
          <mat-radio-button
            class="mpr-radio"
            *ngFor="let updateType of availableWSTypes"
            [value]="updateType"
          >
            {{ updateType | replaceRoleName }}
            <span
              *ngIf="
                updateType.toLowerCase().includes('researcher');
                else developer
              "
            >
              (Includes tools like Excel, WinMerge, Notepad++, Stata, VS Code)
            </span>
            <ng-template #developer>
              (Provides extra processing power, May incur additional cost for
              your project)
            </ng-template>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="mpr-info">
      <b>Note:</b> By choosing to update, your current Amazon WorkSpace will be
      terminated. To avoid losing work, please move any data from your current
      Amazon WorkSpace to your project staging location and push all your code
      to GitHub. Once your workspace is updated, you can pull your code from
      GitHub and access the data from your project's staging location to
      continue working as before.<br /><br />
      <div>Would you like to update your Amazon WorkSpace now?</div>
    </div>
    <div mat-dialog-actions class="mpr-button-enable">
      <button mat-button mat-dialog-close class="mpr-button mpr-cancel-button">
        Dismiss
      </button>
      <button
        mat-button
        class="mpr-button mpr-yellow-button"
        [ngClass]="disableAction ? 'mpr-disabled' : ''"
        (click)="onUpdateSubmit()"
      >
        Update
      </button>
    </div>
  </div>
</div>
