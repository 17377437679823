export interface FeatureFlagsStateModel {
  blockExternalDomainForEmailDistributionList: string;
  dwFeatureFlag: string;
  externalContactUsUrl: string;
  internalContactUsUrl: string;
  internalDomainList: Array<string>;
  isAwsWorkspacesAvailable: string;
  isBillingFeatureFlagEnabled: string;
  isDevelopersAwsWorkspacesAvailable: string;
  isHeavyLiftingFlagAvailable: string;
  isManageProjects: string;
  isManageUsers: string;
  isProjectAdminConsole: string;
  isRecurringStagingUploadAvailable: string;
  isStagingAccessAvailable: string;
  isStataFeatureFlagAvailable: string;
  isVscodeFeatureFlagAvailable: string;
}
