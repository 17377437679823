import { NgModule } from '@angular/core';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AcceptTermsState } from './accept-terms/accept-terms.state';
import { AWSDetailsState } from './aws-details/aws-details.state';
import { ChangeSFRoleState } from './change-sf-role/change-sf-role.state';
import { ConnectionState } from './connection';
import { ConsoleStatsState } from './console-stats/console-stats.state';
import { DashboardLoaderState } from './dashboard-loader-state/dashboard-loader.state';
import { DomainListState } from './domain-list/domain-list.state';
import { DownloadState } from './download';
import { FeatureFlagsState } from './feature-flags';
import { LaunchEc2State } from './launch-ec2/launch-ec2.state';
import { LaunchAWSWorkspaceState } from './launch-workspaces/launch-aws-workspace.state';
import { LoggedinUserState } from './loggedin-user/loggedin-user.state';
import { ManageProjectState } from './manage-project/manage-project.state';
import { MetadataOptionsState } from './metadata-options/metadata-options.state';
import { ProjectUserState } from './project-user/project-user.state';
import { ProjectState } from './project/project.state';
import { RoleLabelsState } from './role-labels/role-labels.state';
import { RouteState } from './route';
import { S3PickerState } from './s3-picker/s3-picker.state';
import { SelectedUserProjectInfoState } from './selected-user-project-info/selected-user-project-info.state';
import { SfJWTTokenState } from './sf-jwt-token';
import { SnowflakeDetailsState } from './snowflake-details';
import { DatasetStatusState } from './uploads/dataset-delete-status/dataset-deletion-status.state';
import { OnetimeJobDetailsState } from './uploads/onetime-job-details/onetime-job-details.state';
import { RecentUploadsState } from './uploads/recent-uploads/recent-uploads.state';
import { RecurringJobDetailsState } from './uploads/recurring-job-details/recurring-job-details.state';
import { ScheduleState } from './uploads/schedule/schedule.state';
import { UploadHistoryState } from './uploads/upload-history/history.state';
import { UploadsMetadataState } from './uploads/uploads-metadata-state';
import { UploadsState } from './uploads/uploads.state';
import { UserInfoState } from './user-info/user-info.state';
import { UserProjectState } from './user-projects/user-project.state';
import { UserState } from './user/user.state';
import { WorkspacesInstanceState } from './workspaces-instance/workspaces-instance.state';
import { UpdateInstanceState } from './update-instance';
import { UpdateRVersionState } from './update-r-version/update-r-version.state';
import { BillingState } from './billing';
@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forRoot([
      AWSDetailsState,
      ConnectionState,
      LoggedinUserState,
      MetadataOptionsState,
      ProjectState,
      ProjectUserState,
      RouteState,
      ScheduleState,
      UploadsMetadataState,
      UploadsState,
      UserProjectState,
      UploadHistoryState,
      RecentUploadsState,
      RecurringJobDetailsState,
      OnetimeJobDetailsState,
      DownloadState,
      S3PickerState,
      UserState,
      UserInfoState,
      ConsoleStatsState,
      LaunchEc2State,
      LaunchAWSWorkspaceState,
      UpdateInstanceState,
      AcceptTermsState,
      DatasetStatusState,
      WorkspacesInstanceState,
      FeatureFlagsState,
      DashboardLoaderState,
      ChangeSFRoleState,
      SnowflakeDetailsState,
      SfJWTTokenState,
      ManageProjectState,
      DomainListState,
      SelectedUserProjectInfoState,
      RoleLabelsState,
      UpdateRVersionState,
      BillingState
    ]),
    NgxsStoragePluginModule.forRoot({
      key: ['UserProject.selectedProjectId'],
    }),
  ],
})
export class StateModule {}
