<div fxLayout="column" class="mpr-mat-dialog-confirm mpr-mat-dialog-small">
  <h1 mat-dialog-title *ngIf = "data.confirmTitle">{{ data.confirmTitle }}</h1>
  <div mat-dialog-content>
    <ng-container
      *ngIf="data.innerContent; else innerContent"
      [ngTemplateOutlet]="data.innerContent"
    ></ng-container>
    <ng-template #innerContent>
      <div>
        <span [innerHTML]="data.confirmMessage"></span>
        <span
          class="f-w-900"
          *ngIf="data.confirmAction"
          [innerHTML]="data.confirmAction"
        ></span>
      </div>
    </ng-template>
  </div>
  <div
    [class.mpr-custom-innercontent]="data.innerContent"
    class="mpr-progressbar-actions"
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="flex-end center"
  >
    <div>
      <button
        mat-button
        mat-dialog-close
        class="mpr-button mpr-cancel-button"
        id="mpr-cancel"
      >
        {{ data.cancelButtonText || 'Cancel' }}
      </button>
    </div>
    <div>
      <button
        mat-button
        class="mpr-button mpr-yellow-button"
        id="mpr-confirm"
        (click)="confirm()"
      >
        {{ data.confirmButtonText || 'Confirm' }}
      </button>
    </div>
  </div>
</div>
