<ng-container *ngIf="!showLoader; else loaderConatiner">
  <div fxLayout="column" class="mpr-mat-dialog-small">
    <div>
      <div
        fxLayout="row"
        fxLayoutGap="30px"
        *ngIf="selectedUploadType === uploadTypeEnum.RECURRING"
      >
        <div
          class="mpr-job-details m-t-4"
          *ngFor="let detail of detailsToDisplay.recurring"
        >
          <ng-container [ngSwitch]="detail">
            <ng-container *ngSwitchCase="'scheduleName'"
              ><div class="mpr-form-block-field-label text-label"
                >Schedule Name</div
              >
              <span>{{ recurringJobDetails.scheduleName }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'dataStore'"
              ><div class="mpr-form-block-field-label text-label"
                >Data Source</div
              >
              <span>{{ recurringJobDetails.dataStore }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'uploadType'"
              ><div class="mpr-form-block-field-label text-label"
                >Upload Type</div
              >
              <span>Recurring</span>
            </ng-container>
            <ng-container *ngSwitchCase="'totalNoOfFiles'"
              ><div class="mpr-form-block-field-label text-label"
                ># Of Files</div
              >
              <span>{{ recurringJobDetails.totalNoOfFiles }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'runDate'"
              ><div class="mpr-form-block-field-label text-label"
                >Date Uploaded</div
              >
              <span>{{ recurringJobDetails.runDate | utcDate }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'jobStatus'">
              <div class="mpr-form-block-field-label text-label"
                >Upload Status</div
              >
              <ng-container
                [ngTemplateOutlet]="jobStatusTemplate"
                [ngTemplateOutletContext]="{ $implicit: jobStatus }"
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="67px"
        *ngIf="selectedUploadType === uploadTypeEnum.ONETIME"
      >
        <div
          class="mpr-job-details m-t-4"
          *ngFor="let detail of detailsToDisplay.onetime"
        >
          <ng-container [ngSwitch]="detail">
            <ng-container *ngSwitchCase="'userName'"
              ><div class="mpr-form-block-field-label text-label"
                >Username</div
              >
              <span>{{ oneTimeJobDetails.userName }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'dataStore'"
              ><div class="mpr-form-block-field-label text-label"
                >Data Source</div
              >
              <ng-container [ngSwitch]="oneTimeJobDetails.dataStore">
                <span *ngSwitchCase="connectionDataStoreEnum.BOX">Box.com</span>
                <span *ngSwitchCase="connectionDataStoreEnum.LOCAL"
                  >Local Drive</span
                >
                <span *ngSwitchCase="connectionDataStoreEnum.S3"
                  >External S3</span
                >
                <span *ngSwitchCase="connectionDataStoreEnum.DATA_LIBRARY_S3"
                  >Data Library S3</span
                >
                <span *ngSwitchCase="connectionDataStoreEnum.DL_S3"
                  >Data Library S3</span
                >
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'uploadType'"
              ><div class="mpr-form-block-field-label text-label"
                >Upload Type</div
              >
              <span>One Time</span>
            </ng-container>
            <ng-container *ngSwitchCase="'totalNoOfFiles'"
              ><div class="mpr-form-block-field-label text-label"
                ># Of Files</div
              >
              <span>{{ oneTimeJobDetails.totalNoOfFiles }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'uploadDate'"
              ><div class="mpr-form-block-field-label text-label"
                >Date Uploaded</div
              >
              <span>{{ oneTimeJobDetails.uploadDate | utcDate }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'jobStatus'">
              <div class="mpr-form-block-field-label text-label"
                >Upload Status</div
              >
              <ng-container
                [ngTemplateOutlet]="jobStatusTemplate"
                [ngTemplateOutletContext]="{ $implicit: jobStatus }"
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>  

  <div class="d-flex add-details">
    <h1>File Details</h1>
  </div>
  <div class="d-flex mpr-file-details-wrapper">
    <ng-container 
      *ngIf="
        oneTimeJobDetails.destinationFolder || 
        recurringJobDetails.destinationFolder
      "
    >
      <div class="mpr-metadata-link-row">
        <span class="mpr-metadata-link-title">DESTINATION FOLDER : </span>
        &nbsp;
        <ng-container 
          *ngIf="oneTimeJobDetails.destinationFolder"
        >
          <button
            #skipper
            (click)="
              proceedToDestinationFolder(oneTimeJobDetails.destinationFolder)
            " 
            class="mpr-metadata-link mpr-btn-transparency"
          >
            {{ oneTimeJobDetails.destinationFolder }}</button
          >
          <span class="mpr-private-space-copy">
            <button 
              class="mpr-icon-button mpr-copy-to-clipboard-icon" 
              (click)="
                copyToClipBoard(oneTimeJobDetails.destinationFolder)
              " 
              matTooltip="Copy Link"
              aria-label="Copy Link"
            ></button>
          </span>
        </ng-container>
        <ng-container 
          *ngIf="recurringJobDetails.destinationFolder"
        >
          <button 
            #skipper
            (click)="
              proceedToDestinationFolder(recurringJobDetails.destinationFolder)
            " 
            class="mpr-metadata-link mpr-btn-transparency"
          >
            {{ recurringJobDetails.destinationFolder }}</button
          >
          <span class="mpr-private-space-copy">
            <button 
              class="mpr-icon-button mpr-copy-to-clipboard-icon" 
              (click)="
                copyToClipBoard(recurringJobDetails.destinationFolder)
              " 
              matTooltip="Copy Link"
              aria-label="Copy Link"
            ></button
            >
          </span>
        </ng-container>
      </div>
    </ng-container>
    <div
      class="mpr-file-count-zero-job"
      *ngIf="isCompletedJob(jobStatus) && isTotalNoOfFilesZero"
    >
      <div class="mpr-noresult">
        No files were available for upload when this job was scheduled to run.
      </div>
    </div>
    <div
      class="mpr-completed-job mpr-files-listing-row m-x-16 m-t-16"
      fxLayoutGap="67px"
      *ngIf="
        (isCompletedJob(jobStatus) && !isTotalNoOfFilesZero) ||
        (!showFilesGridOrError(jobStatus, selectedUploadType) &&
          !isFailedJob(jobStatus))
      "
    >
      <div class="mpr-vertical-stack-wrapper" *ngIf="chartData.length > 0">
        <div
          class="mpr-table-title mpr-skipped-files-legend"
          *ngIf="skippedFiles.length !== 0"
        >
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.SKIPPED_FILES }} :
          {{ 
             oneTimeJobDetails.noOfSkippedFiles || 
             recurringJobDetails.noOfSkippedFiles
          }}
        </div>
        <div
          class="mpr-table-title mpr-quarantine-files-legend"
          *ngIf="quarantinedFiles.length !== 0"
        >
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.QUARANTINED_FILES }} :
          {{ oneTimeJobDetails.noOfQuarantinedFiles || 
             recurringJobDetails.noOfQuarantinedFiles
          }}
        </div>
        <div
          class="mpr-table-title mpr-ready-files-legend"
          *ngIf="
            filesReadyTableDataSource.length !== 0 &&
            !isInProgressJob(jobStatus)
          "
        >
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.FILES_READY_FOR_SEARCH }} :
          {{ oneTimeJobDetails.noOfFilesReadyForStaging || 
             recurringJobDetails.noOfFilesReadyForStaging
          }}
        </div>
      </div>
      <div class="mpr-skipped-files-wrapper" *ngIf="skippedFiles.length !== 0">
        <div class="mpr-table-title">
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.SKIPPED_FILES }}
          <button class= "mpr-btn-transparency" 
            [mtxTooltip]="skippedFilesInfo" 
            #tooltip="mtxTooltip"
            [mprMtxTooltipAriaDescribedBy]="skippedFilesInfo">
            <mat-icon
              class="mpr-info-icon"
              >info
            </mat-icon>
          </button>
        </div>
        <mat-table
          [dataSource]="skippedFiles"
          class="skipped-files-table mpr-tables scrollbar-thin"
          role="table"
          aria-rowcount="-1"
        >
          <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef scope="col">
              File Name
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
              <span class="mpr-ellipsis" matTooltip="{{ file.fileName }}">
                {{ file.fileName }}</span
              >
            </mat-cell>
          </ng-container>
          <mat-header-row
            role="rowheader"
            *matHeaderRowDef="skippedFilesColumns"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: skippedFilesColumns"></mat-row>
        </mat-table>
        <div class="mpr-table-note-text" *ngIf="showNote('skippedFiles')">
          NOTE: Showing 30 of
          {{
            recurringJobDetails.noOfSkippedFiles ||
              oneTimeJobDetails.noOfSkippedFiles
          }}
          files. To view remaining files, refer to file upload email
          notification sent to user who uploaded the files.
        </div>
      </div>
      <div
        class="mpr-quarantine-files-wrapper"
        *ngIf="quarantinedFiles.length !== 0"
      >
        <div class="mpr-table-title">
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.QUARANTINED_FILES }}
          <button class= "mpr-btn-transparency" 
            [mtxTooltip]="quarantineFilesInfo" 
            #tooltip="mtxTooltip"
            [mprMtxTooltipAriaDescribedBy]="quarantineFilesInfo">
            <mat-icon
              class="mpr-info-icon"
              >info
            </mat-icon>
          </button>
        </div>
        <mat-table
          [dataSource]="quarantinedFiles"
          class="mpr-tables scrollbar-thin"
          role="table"
          aria-rowcount="-1"
        >
          <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef scope="col">
              File Name
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
              <span class="mpr-ellipsis" matTooltip="{{ file.fileName }}">
                {{ file.fileName }}</span
              ><button
                *ngIf="isQuarantineSupportingDoc(file.s3Location)"
                class="mpr-support-doc-indicator"
              >
                <img
                  src="assets/images/Supporting_Document.svg"
                  alt="Supporting Document"
                  class="img-margin"
                  matTooltip="Supporting Documents"
                />
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row
            role="rowheader"
            *matHeaderRowDef="quarantineFilesColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: quarantineFilesColumns"
          ></mat-row>
        </mat-table>
        <div class="mpr-table-note-text" *ngIf="showNote('quarantinedFiles')">
          NOTE: Showing 30 of
          {{
            recurringJobDetails.noOfQuarantinedFiles ||
              oneTimeJobDetails.noOfQuarantinedFiles
          }}
          files. To view remaining files, refer to file upload email
          notification sent to user who uploaded the files.
        </div>
      </div>
      <div
        class="mpr-ready-files-wrapper"
        *ngIf="
          filesReadyTableDataSource.length !== 0 && !isInProgressJob(jobStatus)
        "
      >
        <div class="mpr-table-title">
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.FILES_READY_FOR_SEARCH }}
          <button class= "mpr-btn-transparency" 
            [mtxTooltip]="readyFilesInfo" 
            #tooltip="mtxTooltip"
            [mprMtxTooltipAriaDescribedBy]="readyFilesInfo">
            <mat-icon
              class="mpr-info-icon"
              >info
            </mat-icon>
          </button>
        </div>
        <mat-table
          [dataSource]="filesReadyTableDataSource"
          class="ready-files-table mpr-tables scrollbar-thin"
          role="table"
          aria-rowcount="-1"
        >
          <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef scope="col">
              File Name
            </mat-header-cell>
            <mat-cell *matCellDef="let readyFile">
              <span class="mpr-ellipsis" matTooltip="{{ readyFile.fileName }}">
                {{ readyFile.fileName }}</span
              >
            </mat-cell>
          </ng-container>
          <mat-header-row
            role="rowheader"
            *matHeaderRowDef="readyFilesColumns"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: readyFilesColumns"></mat-row>
        </mat-table>
      </div>
    </div>
    <div *ngIf="isInProgressJob(jobStatus)">
      <div class="mpr-inprogress">
        <div class="mpr-failed-status-error-wrapper">
          <img
            src="assets/images/WarningIcon.svg"
            alt="Error"
            class="errorIcon"
          />&nbsp;<span
            >The files are being uploaded. Please check the status after some
            time.</span
          >
        </div>
      </div>
    </div>
    <div class="mpr-error-job" *ngIf="isFailedJob(jobStatus)">
      <div class="mpr-noresult">
        <div>
          <div class="mpr-failed-status-error-wrapper">
            <img src="assets/images/Error.svg" alt="" /><span
              class="mpr-failed-status-error-text"
              >Error</span
            >
          </div>
          <div class="mpr-note-dashed-divider"></div>
          <div class="mpr-failed-status-reason-label">Reason For Error</div>
          <div class="mpr-failed-status-reason">
            File upload failed. Please try again or contact
            <a class="mpr-service-now-link" href="{{ workspaceServiceNowUrl }}"
              >“Data Library – Support”</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loaderConatiner>
  <div class="mpr-content-loader">
    <h1 class="mpr-content-loader-text">Retrieving Details. Please Wait...</h1>
  </div>
</ng-template>
<ng-template #skippedFilesInfo>
  <div class="mpr-width-px-300" id="mpr-skippedfile-class">
    These files are skipped, because they are not a supported format. These file
    extension(s) [{{ unsupportedFileTypes.join(',') }}] are not supported by
    Data Library Platform.
  </div>
</ng-template>
<ng-template #quarantineFilesInfo>
  <div class="mpr-width-px-300" id="mpr-quarantinefile-class">
    The data file scan detected the presence of PII/PHI data. Please re-upload
    the data files and select the correct PII/PHI metadata attribute indicator.
  </div>
</ng-template>
<ng-template #readyFilesInfo>
  <div class="mpr-width-px-300" id="mpr-readyfile-class">
    These files are uploaded and available to access from the staging folder.
  </div>
</ng-template>
<ng-template #jobStatusTemplate let-jobStatus>
  <ng-container [ngSwitch]="jobStatus">
    <span *ngSwitchCase="uploadStatusesEnum.COMPLETED">
      <mat-icon
        ><img src="assets/images/completed.svg" alt="" /></mat-icon
      >Completed
    </span>
    <span *ngSwitchCase="uploadStatusesEnum.COMPLETED_WITH_ERRORS">
      <mat-icon
        ><img src="assets/images/completed.svg" alt="" /></mat-icon
      >Completed with errors
    </span>
    <span *ngSwitchCase="uploadStatusesEnum.UPLOAD_FAILED">
      <mat-icon
        ><img src="assets/images/red-dot-error.svg" alt="" /></mat-icon
      >Error
    </span>
    <span *ngSwitchCase="uploadStatusesEnum.SCAN_FAILED">
      <mat-icon
        ><img src="assets/images/red-dot-error.svg" alt="" /></mat-icon
      >Error
    </span>
    <span *ngSwitchCase="uploadStatusesEnum.CATALOGING_FAILED">
      <mat-icon
        ><img src="assets/images/red-dot-error.svg" alt="" /></mat-icon
      >Error
    </span>
    <span *ngSwitchDefault>
      <mat-icon
        ><img src="assets/images/in-progress.svg" alt="" /></mat-icon
      >In-Progress
    </span>
  </ng-container>
</ng-template>