import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import {
  LoggedinUserState,
  UserProjectState,
  LoadSelectedUserProjects,
} from 'app/state';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import { Observable, catchError, throwError, withLatestFrom } from 'rxjs';
import { MprConfirmComponent } from '../mpr-confirm/mpr-confirm.component';
import { AlertMessageService } from '@core/services';
import { UserProjectRoleEnum, userStatus } from '@core/enums';
import { ProjectUserState } from 'app/state/project-user/project-user.state';
import { DeleteUserList } from 'app/state/project-user/delete-user-list-model';
import { DeleteUserFromProject } from 'app/state/project-user/project-user.actions';
import { convertToCSV, saveAsCSV } from '@shared/utils';
import { USER_DATA_FIELDS } from '@core/constants';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { UserProject } from '@theme/interfaces';
import { SelectedUserProjectInfoState } from 'app/state/selected-user-project-info/selected-user-project-info.state';
import { RoleLabelsState } from 'app/state/role-labels/role-labels.state';
@Component({
  selector: 'mpr-list-details',
  templateUrl: './list-details.component.html',
  styleUrls: ['./list-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListDetailsComponent implements OnInit {
  @Select(ProjectUserState.getDeleteUserList)
  public deleteUsersList$?: Observable<DeleteUserList[]>;
  @Input() public isFromListPage = false;
  @Input() public listDetails?: Observable<ProjectUserList[]>;
  @Input() public listType = '';
  @Select(SelectedUserProjectInfoState.getSelectedUserProjects)
  public projectsOfUserToBeDeleted$!: Observable<UserProject[]>;
  public isDeleteInProgress = false;
  public loggedinUserEmail = '';
  public projectUserCount = '';
  public selectedProjectId = '';
  public userProjectsCount = 0;
  public userStatusInvited = userStatus.INVITED;
  private projectId = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public dialog: MatDialog,
    private alertMsgService: AlertMessageService
  ) {
    this.projectId = this.route.snapshot.params['projectId'];
    this.projectId = this.projectId
      ? atob(this.route.snapshot.params['projectId'])
      : '';
  }

  public exportToCSV(listDetails: ProjectUserList[]): void {
    const valueMapping = { roleName : {} };
    valueMapping['roleName'] = this.store.selectSnapshot(RoleLabelsState.getRoleLabelsMapping) || {};
    const csv = convertToCSV(listDetails, USER_DATA_FIELDS, valueMapping);
    const projectId = this.projectId
      ? this.projectId
      : this.store.selectSnapshot(UserProjectState.getSelectedProjectId);
    saveAsCSV(csv, `${projectId}_Users.csv`);
  }

  public navigateToAddusers(): void {
    let navigateRoute = 'admin/users/add';
    if (this.isFromListPage)
      navigateRoute = `/platformAdmin/user/add/${this.route.snapshot.params['projectId']}`;
    this.router.navigate([navigateRoute]);
  }

  public navigateToEditUser(user: ProjectUserList): void {
    const id =
      user.userStatus === userStatus.INVITED ? user.emailId : user.userId;
    let url = `admin/users/edit/${btoa(id)}`;
    if (this.isFromListPage)
      url = `platformAdmin/user/edit/${btoa(id)}/${
        this.route.snapshot.params['projectId']
      }`;
    this.router.navigate([url]);
  }

  ngOnInit(): void {
    this.loggedinUserEmail = this.store.selectSnapshot(
      LoggedinUserState.getLoggedInUserEmail
    );
    this.selectedProjectId = this.projectId
      ? this.projectId
      : this.store.selectSnapshot(UserProjectState.getSelectedProjectId);
  }

  public showDeleteConfirmation(user: ProjectUserList): void {
    this.isDeleteInProgress = true;
    this.store
      .dispatch(new LoadSelectedUserProjects(user.emailId))
      .pipe(
        withLatestFrom(this.projectsOfUserToBeDeleted$),
        catchError((err) => throwError(() => new Error('')))
      )
      .subscribe(([_, userProjects]) => {
        this.userProjectsCount = userProjects.length;
        const id =
          user.userStatus === userStatus.INVITED ? user.emailId : user.userId;
        const isPowerUser = user.isPowerUser;
        let confirmationMessage = '';
        const displayProjectId =
          this.projectId !== ''
            ? this.projectId
            : this.store.selectSnapshot(UserProjectState.getSelectedProjectId);
        if (isPowerUser) {
          confirmationMessage =
            this.userProjectsCount === 1
              ? `Are you sure you want to remove <b>${user.firstName} ${user.lastName}</b> from project <b>${displayProjectId}</b>?<br><br> Once removed, they will no longer have access to their project data and any EC2 Processing Instance & Amazon WorkSpace, if provisioned, will be terminated. Any AWS resources provisioned by this user will also be terminated.`
              : `Are you sure you want to remove <b>${user.firstName} ${user.lastName}</b> from project <b>${displayProjectId}</b>?<br><br> Once removed, they will no longer have access to their project data and any EC2 Processing Instance, if provisioned, will be terminated. Any AWS resources provisioned by this user will also be terminated.`;
        } else {
          confirmationMessage =
            this.userProjectsCount === 1
              ? `Are you sure you want to remove <b>${user.firstName} ${user.lastName}</b> from project <b>${displayProjectId}</b>?<br><br> Once removed, they will no longer have access to their project data and any EC2 Processing Instance & Amazon WorkSpace, if provisioned, will be terminated. Any running jobs on the provisioned workspaces will also be terminated.`
              : `Are you sure you want to remove <b>${user.firstName} ${user.lastName}</b> from project <b>${displayProjectId}</b>?<br><br> Once removed, they will no longer have access to their project data and any EC2 Processing Instance, if provisioned, will be terminated. Any running jobs on the provisioned workspaces will also be terminated.`;
        }
        this.dialog
          .open(MprConfirmComponent, {
            disableClose: true,
            data: {
              confirmTitle: 'Remove User ',
              confirmMessage: confirmationMessage,
              confirmAction: '',
              confirmData: id,
            },
          })
          .afterClosed()
          .subscribe((userId?: string): void => {
            this.isDeleteInProgress = false;
            if (!userId) return;
            this.returnDeleteUserAction(id)
              .pipe(
                catchError((err) => {
                  this.alertMsgService.error({
                    body: err.error.message,
                  });
                  return throwError(() => new Error(''));
                })
              )
              .subscribe((res: any) => {
                const deleteResponse = res.ProjectUser.deleteResponse;
                if (deleteResponse.length > 0) {
                  if (!deleteResponse[0].isSuccess) {
                    this.alertMsgService.error({
                      body: deleteResponse[0].message,
                    });
                  } else {
                    this.alertMsgService.success({
                      body: `User has been deleted successfully.`,
                    });
                  }
                }
              });
          });
      });
  }

  private returnDeleteUserAction(
    id: string
  ): Observable<DeleteUserFromProject> {
    let returnType: Observable<DeleteUserFromProject>;

    if (this.projectId) {
      const requestHeaders: MprHttpHeaderModal = {};
      requestHeaders[HeaderParams.ROLENAME] =
        UserProjectRoleEnum.PLATFORM_ADMIN;

      returnType = this.store.dispatch(
        new DeleteUserFromProject(id, requestHeaders, [this.projectId])
      );
    } else {
      returnType = this.store.dispatch(new DeleteUserFromProject(id));
    }

    return returnType;
  }
}
