<div class="mpr-manage-recurring-schedule">
  <div
    class="mpr-recurring-top-section"
    *ngIf="recurringSchedule.length || searchNotFound"
  >
    <div class="field-container">
      <div class="field-label">LIST OF SCHEDULED JOBS</div>
      <!-- Commenting for DLD-2807 -->
      <!-- <mat-form-field
        floatLabel="never"
        class="mpr-mat-input-field mpr-input-with-icon mpr-width-percentage-30"
        [hideRequiredMarker]="false"
      >
        <input
          class="mpr-metadata-form-block-field-input"
          matInput
          maxlength="100"
          placeholder="Search Schedule Name"
          (keyup.enter)="applyFilter()"
          [(ngModel)]="searchText"
        />
        <mat-icon matPostfix class="my-icon" (click)="applyFilter()"
          >search</mat-icon
        >
      </mat-form-field> -->
    </div>
    <div>
      <button
        #skipper
        mat-button
        class="mpr-button mpr-yellow-button"
        (click)="createSchedule()"
      >
        CREATE NEW SCHEDULE
      </button>
    </div>
  </div>

  <div class="mpr-schedule-table-container">
    <div
      class="mpr-schedule-table-withdata"
      *ngIf="recurringSchedule.length; else noResult"
    >
      <mat-table
        [dataSource]="dataSource"
        class="schedule-list-table mpr-tables"
        role="table"
        aria-rowcount="-1"
      >
        <ng-container matColumnDef="createdBy">
          <mat-header-cell *matHeaderCellDef scope="col">
            Created By
          </mat-header-cell>
          <mat-cell *matCellDef="let schedule">
            {{ schedule.createdByName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="scheduleName">
          <mat-header-cell *matHeaderCellDef scope="col">
            Schedule Name
          </mat-header-cell>
          <mat-cell *matCellDef="let schedule">
            <span
              class="mpr-ellipsis"
              matTooltip="{{ schedule.scheduleName }}"
              >{{ schedule.scheduleName }}</span
            >
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastRunAt">
          <mat-header-cell *matHeaderCellDef scope="col">
            Last Run Date
          </mat-header-cell>
          <mat-cell *matCellDef="let schedule">
            <ng-container *ngIf="schedule.lastRunAt">
              {{ schedule.lastRunAt | utcDate }}
            </ng-container>
            <ng-container *ngIf="!schedule.lastRunAt"> - </ng-container>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="frequency">
          <mat-header-cell *matHeaderCellDef scope="col">
            Frequency
          </mat-header-cell>
          <mat-cell *matCellDef="let schedule">
            {{ schedule.frequency }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef scope="col">
            Edit
          </mat-header-cell>
          <mat-cell *matCellDef="let schedule">
            <button
              class="mpr-btn-transparency"
              title="Edit schedule"
              (click)="openEditModal(schedule)"
              *ngIf="isModificationApplicable(schedule)"
            >
              <mat-icon
                class="mpr-edit-schedule"
                title="Edit schedule"
                aria-hidden="false"
              >
                <img src="assets/images/EditIcon.svg" alt="Edit" />
              </mat-icon>
            </button>
            <button
              class="mpr-btn-transparency"
              disabled
              title="Edit schedule disabled"
              *ngIf="!isModificationApplicable(schedule)"
            >
              <mat-icon
                class="mpr-edit-schedule mpr-disabled-icon"
                title="Edit schedule"
                aria-hidden="false"
              >
                <img src="assets/images/EditIcon.svg" alt="Edit" />
              </mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef scope="col"
            >Delete</mat-header-cell
          >
          <mat-cell *matCellDef="let schedule">
            <button
              class="mpr-btn-transparency"
              (click)="showDeleteScheduleDialog(schedule)"
              *ngIf="isModificationApplicable(schedule)"
              aria-label="Delete Schedule"
            >
              <mat-icon class="mpr-delete-schedule" title="Delete schedule"
                >cancel</mat-icon
              >
            </button>
            <button
              class="mpr-btn-transparency"
              disabled
              title="Delete schedule disabled"
              *ngIf="!isModificationApplicable(schedule)"
            >
              <mat-icon
                class="mpr-delete-schedule mpr-disabled-icon"
                title="Delete schedule disabled"
                >cancel</mat-icon
              >
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="enable">
          <mat-header-cell *matHeaderCellDef scope="col"
            >Enable</mat-header-cell
          >
          <mat-cell *matCellDef="let schedule">
            <mat-slide-toggle
              [checked]="schedule.status === scheduleStatusEnum.ACTIVE"
              (change)="
                enableDisableSchedule(schedule.scheduleId, $event.checked)
              "
              title="Enable/Disable"
              alt="Enable/Disable"
              aria-label="Enable/Disable"
              [disabled]="!isModificationApplicable(schedule)"
              ><div hidden>Enable/Disable</div></mat-slide-toggle
            >
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="destinatonFolder">
          <mat-header-cell *matHeaderCellDef scope="col">
            Destination Folder
          </mat-header-cell>
          <mat-cell *matCellDef="let schedule">
            <button
              class="mpr-link mpr-btn-transparency mpr-destination-folder"
              title="Destination Folder"
              aria-label="Destination Folder"
              (click)="navigateToDestinationFolder(schedule.destinationFolder)"
            >
              {{ displayStagingTarget(schedule.destinationFolder) }}
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row
          role="rowheader"
          *matHeaderRowDef="displayedColumns"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        hidePageSize
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="10"
        [length]="recurringSchedule.length"
        (page)="handlePageEvent($event)"
        aria-label="Select page of users"
        class="mpr-paginator"
        *ngIf="recurringSchedule.length > 10"
      >
      </mat-paginator>
    </div>
    <ng-template #noResult>
      <div class="mpr-schedule-table-noresult" *ngIf="!searchNotFound">
        <div class="mpr-noresult-container">
          <div class="mpr-noresutl-error">
            <span><img src="assets/images/Error.svg" alt="Error" /> </span>
            <span
              >You do not have any schedules created for the current
              project</span
            >
          </div>
          <div class="mpr-noresult-btn">
            <button
              mat-button
              class="mpr-button mpr-yellow-button"
              (click)="createSchedule()"
            >
              CREATE NEW SCHEDULE
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="mpr-schedule-table-noresult" *ngIf="searchNotFound">
      <div class="mpr-noresult-container">
        <div class="mpr-noresutl-error">
          <span><img src="assets/images/Error.svg" alt="Error" /> </span>
          <span
            >We could not find any recurring schedule that match your search
            terms. Please check your search term or try using a different
            one.</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
