<nav>
  <ul class="matero-sidemenu level-0">
    <ng-container *ngFor="let menuItem of menuList">
      <li
        *ngIf="menuItem.isEnabled"
        navAccordionItem
        [ngClass]="
          menuItem.routePath === currentRoute
            ? 'matero-sidemenu-item active'
            : 'matero-sidemenu-item'
        "
        [attr.data-cy]="menuItem.dataTestId"
        (click)="
          navigateToRoute(
            menuItem.routePath,
            menuItem.externalUrl,
            menuItem.target,
            menuItem.text
          )
        "
        (keyup.enter)="
          navigateToRoute(
            menuItem.routePath,
            menuItem.externalUrl,
            menuItem.target,
            menuItem.text
          )
        "
        tabindex="0"
      >
        <a class="matero-sidemenu-link mpr-btn-transparency">
          <mat-icon class="menu-icon" [svgIcon]="menuItem.icon"></mat-icon>
          <span class="menu-name text-uppercase">{{ menuItem.text }}</span>
          <span fxFlex></span>
        </a>
      </li>
      <li
        *ngIf="!menuItem.isEnabled"
        navAccordionItem
        [ngClass]="
          menuItem.routePath === currentRoute
            ? 'matero-sidemenu-item active'
            : 'matero-sidemenu-item'
        "
        [attr.data-cy]="menuItem.dataTestId"
      >
        <a
          class="matero-sidemenu-link matero-sidemenu-item-disabled"
          title="This option is disabled for your role"
          aria-label="Only Admins/Developers can access this feature"
        >
          <mat-icon class="menu-icon" [svgIcon]="menuItem.icon"></mat-icon>
          <span class="menu-name text-uppercase">{{ menuItem.text }}</span>
          <span fxFlex></span>
        </a>
      </li>
    </ng-container>
  </ul>
  <div
    class="platformAdminProfile"
    *ngIf="
      selectedRoleName === 'Platform Admin' || currentRoute === '/platformAdmin'
    "
  >
    <ul class="matero-sidemenu level-0">
      <div class="horizentalLine"></div>
      <li
        navAccordionItem
        [ngClass]="
          currentRoute === '/platformAdmin'
            ? 'matero-sidemenu-item active'
            : 'matero-sidemenu-item'
        "
        (click)="navigateToPlatFormAdminConsole('platformAdmin')"
        (keyup.enter)="navigateToPlatFormAdminConsole('platformAdmin')"
        tabindex="0"
      >
        <a
          class="matero-sidemenu-link mpr-btn-transparency platformAdminProfileHeight"
        >
          <mat-icon class="menu-icon" svgIcon="platform_admin"></mat-icon>
          <span class="menu-name text-uppercase">{{ platformAdminText }}</span>
          <span fxFlex></span>
        </a>
      </li>
    </ul>
  </div>
</nav>
