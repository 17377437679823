import { BASE_URL, httpInterceptorProviders } from '@core';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { IconModule } from './icon.module';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoutesModule } from './routes/routes.module';
import { StateModule } from './state/state.module';
import { ThemeModule } from '@theme/theme.module';
import { UploadsModule } from './uploads/uploads.module';
import { environment } from '../environments/environment';
import { SharedModule } from '@shared';
import { WorkspaceModule } from './workspace/workspace.module';
import { StagingModule } from './staging/staging.module';
import { PlatformAdminModule } from './platform-admin/platform-admin.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    IconModule,
    ThemeModule,
    BrowserAnimationsModule,
    RoutesModule,
    SharedModule,
    StateModule,
    UploadsModule,
    WorkspaceModule,
    StagingModule,
    PlatformAdminModule,
    environment.plugins,
  ],
  providers: [
    { provide: BASE_URL, useValue: environment.apiBaseUrl },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
