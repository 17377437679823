import { UserProjectRoleEnum } from '@core/enums';

export interface UserProject {
  ec2Instance: string;
  isTermsAccepted: boolean;
  machineSize: string;
  projectId: string;
  projectName: string;
  projectNickname: string;
  projectNumber: string;
  roleName: UserProjectRoleEnum;
  serverUrls: ServerUrls;
  userRestrictedDataUseGroups: string[];
  userStatus?: string;
}

export interface TileConfig {
  count?: string;
  countDescription?: string;
  desc?: string;
  externalUrl?: boolean;
  img: string;
  isDisabled: boolean;
  isVisible: boolean;
  path: string;
  roleSetting: UserProjectRoleEnum[];
  tag: string;
  target?: string;
  title: string;
  version?: string;
}

export interface ServerUrls {
  jupyter: string;
  rstudio: string;
}
