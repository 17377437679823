export enum AuthConstants {
  ACCESS_TOKEN = 'access_token',
  AUTHORIZATION_FIELD = 'Authorization',
  AUTH_GRANT_TYPE_FIELD = 'grant_type',
  CLIENT_ID = 'aud',
  CLIENT_ID_FIELD = 'client_id',
  CLIENT_SECRET_FIELD = 'client_secret',
  EMAIL = 'email',
  EXPIRY = 'exp',
  FAMILY_NAME = 'family_name',
  GIVEN_NAME = 'given_name',
  ID = 'custom:userid',
  ID_TOKEN = 'id_token',
  ID_TOKEN_LIFETIME = 1,
  ID_TOKEN_LIFETIME_UNIT = 'hour',
  REFRESH_TOKEN = 'refresh_token',
  NAME = 'name',
  REFRESH_TOKEN_LIFETIME = 30,
  REFRESH_TOKEN_LIFETIME_UNIT = 'day',
  RENEW_ID_TOKEN_BEFORE = 5,
  RENEW_ID_TOKEN_BEFORE_UNIT = 'minute',
}

export enum ConnectionDataStore {
  BOX = 'box.com',
  DATA_LIBRARY_S3 = 'Data Library S3',
  LOCAL = 'local',
  S3 = 'S3',
  DL_S3 = 'dls3',
  SNOWFLAKE = 'Snowflake',
  CHART = 'Chart',
  API = 'API',
  WEB_URL = 'Web URL',
}

export enum ConnectionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ConnectionType {
  INGESTION = 'ingestion',
  OUTPUT = 'export',
}

export enum ConnectionProgressStatus {
  ADDING = 'adding',
  ADDED_CONFIRMATION_PENDING = 'confirmationPending',
  ADDED_CONFIRMATION_SUCESS = 'confirmed',
  ADDED_CONFIRMATION_FAILURE = 'confirmationFailure',
}

export enum FrequencyListEnum {
  HOURLY = 'Hourly',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export enum GenericHttpResponseStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum GenericStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum LaunchEc2Enum {
  FAILED = 'FAILED',
  REPROVISION_TERMINATED = 'REPROVISION-TERMINATED',
  REPROVISIONING = 'REPROVISIONING',
  REPROVISION = 'REPROVISION',
  PROVISIONING = 'PROVISIONING',
  INITIALIZING_STATUS = 'INITIALIZING',
  RUNNING_STATUS = 'RUNNING',
  STOPPED_STATUS = 'STOPPED',
  STOPPING_STATUS = 'STOPPING',
  NOT_PROVISIONED = 'NOT PROVISIONED',
  TERMINATED_STATUS = 'TERMINATED',
  SHUTTING_DOWN = 'SHUTTING-DOWN',
}

export enum WorkspaceStatus {
  PENDING = 'PENDING',
  AVAILABLE = 'AVAILABLE',
  STARTING = 'STARTING',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  TERMINATING = 'TERMINATING',
  NOT_PROVISIONED = 'NOT_PROVISIONED',
  REPROVISIONING_FAILED = 'REPROVISIONING_FAILED',
  REPROVISIONING = 'REPROVISIONING',
  FAILED = 'FAILED',
}

export enum MetaDataFromControls {
  AGGREGATION_LEVEL = 'aggregationLevel',
  DATA_ACCESS_CLASSIFICATION = 'dataAccessClassification',
  DATE_CREATED = 'dateCreated',
  DATA_PURCHASED = 'dataPurchased',
  DATA_SET_NAME = 'datasetName',
  DATA_SOURCE_YEARS_END = 'dataSourceYearsEnd',
  DATA_SOURCE_YEARS_START = 'dataSourceYearsStart',
  DATA_TAGS = 'dataTags',
  DESCRIPTION = 'description',
  FOCUS_AREA = 'focusArea',
  GEOGRAPHIC_REGION = 'geographicRegion',
  GEO_REGION_CUSTOM_VALUE = 'geoRegionCustomValue',
  NO_DATA_SOURCE_YEARS_APPLY = 'noDataSourceYearsApply',
  PII = 'pii',
  RESTRICTED_DATA_USE_GROUP = 'restrictedDataUseGroups',
}

export enum CreateProjectFromControls {
  DWACCESS = 'dwAccess',
  PROJECT_NUMBER = 'projectNumber',
  PROJECT_RESTRICTED_DATA_USE_GROUP = 'projectRestrictedDataUseGroups',
  PROJECT_NICK_NAME = 'projectNickname',
  WARE_HOUSE_SIZE = 'projectSize',
  CREDIT = 'credit',
  EMAIL_DISTRIBUTION_LIST = 'emailDistributionList',
}

export enum OneTimeScheduleEnum {
  ONETIME = 'onetime',
  SCHEDULE = 'schedule',
}

export enum SelectFileTypeEnum {
  FILES = 'Files',
  SUPPORTING = 'SupportingFiles',
}

export enum UploadTypeEnum {
  RECURRING = 'recurring',
  ONETIME = 'onetime',
}

export enum SortOrderEnum {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export enum SortKeyNameEnum {
  MODIFIEDBY = 'modifiedBy',
  MODIFIEDDATE = 'modifiedDate',
  FREQUENCY = 'frequency',
}

export enum UploadStatusesEnum {
  UPLOAD_IN_PROGRESS = 'upload_in_progress',
  UPLOAD_COMPLETED = 'upload_completed',
  UPLOAD_FAILED = 'upload_failed',
  SCAN_IN_PROGRESS = 'scan_in_progress',
  SCAN_COMPLETED = 'scan_completed',
  SCAN_FAILED = 'scan_failed',
  SCAN_SKIPPED = 'scan_skipped',
  CATALOGING_IN_PROGRESS = 'cataloging_in_progress',
  COMPLETED = 'completed',
  CATALOGING_FAILED = 'cataloging_failed',
  COMPLETED_WITH_ERRORS = 'completed_with_errors',
  BLANK = '',
}

export enum StepValueEnum {
  INPROGRESS = 'In-Progress',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  SKIPPED = 'Skipped',
}

export enum UserProjectRoleEnum {
  PLATFORM_ADMIN = 'Platform Admin',
  ADMIN = 'Project Admin',
  DEVELOPER = 'Project Developer',
  RESEARCHER = 'Project Researcher',
  GENERAL_RESEARCHER = 'General Researcher',
  EXTERNAL_DEVELOPER = 'External Developer',
  EXTERNAL_RESEARCHER = 'External Researcher',
  BLANK = '',
  DEFAULT = 'DEFAULT',
}

export enum userStatus {
  INVITED = 'invited',
}

export enum StackbarColorsEnum {
  QUARANTINED = '#3268a7',
  READY = '#a25c7f',
  SKIPPED = '#78a8be',
  SNOWFLAKE = '#225D6F',
}

export enum LabelForDownloadPage {
  FILE_NAME = 'File Name',
  DATASET_NAME = 'Dataset Name',
  TOTAL_SIZE = 'Total Size',
  FILE_SIZE = 'File Size',
}

export enum JobOperationTypeEnums {
  JOB_OPERATION_TYPE_UPLOAD_AND_CATALOG = 'upload_and_catalogue',
  JOB_OPERATION_TYPE_UPLOAD_TO_STAGING = 'upload_to_staging',
  JOB_OPERATION_TYPE_SF_CATALOG = 'sf_catalog',
  JOB_OPERATION_TYPE_URL_CATALOG = 'url_catalog',
  JOB_OPERATION_TYPE_STAGING = 'staging',
  RECURRING_JOB_DETAILS_FOR_STAGING = 'recurring_upload_to_staging',
}

export enum FileManagerOperationTypeEnums {
  FILE_MANAGER_OPERATION_TYPE_CREATE = 'create',
  FILE_MANAGER_OPERATION_TYPE_RENAME = 'rename',
  FILE_MANAGER_OPERATION_TYPE_DELETE = 'delete',
  FILE_MANAGER_OPERATION_TYPE_MOVE = 'move',
}

export enum FailedLoginScenarios {
  LOGIN_FAILED_TECHNICAL = 'loginFailedTechnical',
  LOGIN_FAILED_EXT_GEN_RESEARCHER = 'loginFailedExtGenResearcher',
  LOGIN_FAILED_EXT_DOMAIN_UNSUPPORTED = 'loginFailedExtDomainUnsupported',
}

export enum ExportSource {
  STAGING = 'staging',
  CATALOG = 'catalog',
  BILLING = 'billing',
}

export enum FileManagerActions {
  DEFAULT = 'DEFAULT',
  EXPORT = 'EXPORT',
}

export enum FileManagerMessages {
  EXPORT = 'Export Initiated',
  DEFAULT = 'Please wait while the items load....',
}
