// environment.ts
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

export const environment = {
  production: false,
  prodEnvUrl: 'https://mdataplatform.com',
  apiBaseUrl: 'https://api.dev.mdataplatform.com/',
  ckanUrl: 'https://catalogdev.test.mdataplatform.com', //base path // new CKAN instance
  ckanLoginPath: '/user/login', //user login path
  snowflakeUrl: 'https://ac82321-wjb06002.snowflakecomputing.com',
  useHash: false,
  plugins: [
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  appendEmailToBaseUrl: 'dghazala@mathematica-mpr.com',
  useAcceleratedS3Endpoint: true,
  auth0ClientId: 'Bc48iiK4buVFInOH3Md8KaRCfh4GOYe0',
  auth0Domain:
    'https://auth.dev.mdataplatform.com/',
  auth0ClientSecret: 'hkviyM85GEkMgitUhIrA9M4occlEL4JO9dcfl6jyMh3HLmoFobJ0Cr_lCdCQdIQH',
  maxLocalFileDownloads: 30,
  ec2idleTime: '1 hour',
  helpPageNowUrl: 
  'https://help.dev.mdataplatform.com/#/',
  termsOfUsePageURL: 'https://help.dev.mdataplatform.com/#/terms-of-use-and-privacy-policy',
  externalTermsOfUsePageURL: 'https://help.dev.mdataplatform.com/#/external-terms-of-use-and-privacy-policy',
  contactUsServiceNowUrl:
    'https://mathematica.service-now.com/mpr/?id=sc_cat_item&sys_id=3826e83a1be2d91040eec801604bcb38&sysparm_category=faef17711b1e595040eec801604bcbd8',
  workspaceChangeServiceNowUrl:
    'https://mathematica.service-now.com/mpr/?id=sc_cat_item&sys_id=0900b3001ba2911040eec801604bcb7d&sysparm_category=faef17711b1e595040eec801604bcbd8',
  projectS3BucketName: 'mm-project-data-dev',
};
