<div class="mpr-metadata-container">
  <div class="mpr-form">
    <div class="mpr-warning-message" *ngIf="!isEdit">
      NOTE: After creating the project, Project Number and Short Name can't be
      edited anymore
    </div>
    <form [formGroup]="projectForm">
      <div
        [ngClass]="
          data.dwAccess || data.hasPowerUsers
            ? 'mpr-form-block'
            : 'mpr-form-block-witout-border'
        "
      >
        <div class="mpr-form-block-field">
          <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
            <div class="mpr-form-fields-row-items">
              <label for="projectNumber" class="mpr-form-block-field-label"
                >Project Number<sup>*</sup>
              </label>
              <mat-form-field
                class="mpr-width-percentage-95"
                appearance="outline"
              >
                <input
                  type="text"
                  id="projectNumber"
                  formControlName="projectNumber"
                  matInput
                  class="mpr-mat-input-field"
                  required
                  maxlength="12"
                />
              </mat-form-field>
              <div
                class="mpr-form-block-field-wordcount d-flex"
                *ngIf="!isEdit && roleName !== 'Project Admin'"
                [ngClass]="
                  projectForm.controls['projectNumber'].value.length === 12
                    ? 'mpr-error-color'
                    : ''
                "
                >{{ projectForm.controls['projectNumber'].value.length }}/12
                characters</div
              >
              <mat-error
                class="d-flex"
                *ngIf="projectForm.controls['projectNumber'].touched && projectForm.controls['projectNumber'].errors?.['required']"
                >Please enter project number.</mat-error
              >
              <mat-error
                class="d-flex"
                *ngIf="projectForm.controls['projectNumber'].dirty && !projectForm.controls['projectNumber'].errors?.['required'] && projectForm.controls['projectNumber'].errors?.['whitespace']"
                >Please enter a valid project number.</mat-error
              >
              <mat-error
                class="d-flex"
                *ngIf="projectForm.controls['projectNumber'].dirty && !projectForm.controls['projectNumber'].errors?.['required'] && !projectForm.controls['projectNumber'].errors?.['whitespace'] && projectForm.controls['projectNumber'].errors?.['pattern']"
                >Project number can only contain alphabets(A-Z, a-z), numbers(0
                - 9), dot(.), dash(-) and underscore(_).</mat-error
              >
            </div>

            <div class="mpr-form-fields-row-items">
              <label for="projectNickname" class="mpr-form-block-field-label"
                >Short Name<sup>*</sup>
              </label>
              <mat-form-field
                class="mpr-width-percentage-95"
                appearance="outline"
              >
                <input
                  type="text"
                  id="projectNickname"
                  formControlName="projectNickname"
                  matInput
                  class="mpr-mat-input-field"
                  required
                  maxlength="12"
                />
              </mat-form-field>
              <div
                class="mpr-form-block-field-wordcount d-flex"
                *ngIf="!isEdit && roleName !== 'Project Admin'"
                [ngClass]="
                  projectForm.controls['projectNickname'].value.length === 12
                    ? 'mpr-error-color'
                    : ''
                "
                >{{ projectForm.controls['projectNickname'].value.length }}/12
                characters</div
              >
              <mat-error
                class="d-flex"
                *ngIf="projectForm.controls['projectNickname'].dirty && projectForm.controls['projectNickname'].errors?.['required']"
                >Please enter short name.</mat-error
              >
              <mat-error
                class="d-flex"
                *ngIf="projectForm.controls['projectNickname'].dirty && !projectForm.controls['projectNickname'].errors?.['required'] && projectForm.controls['projectNickname'].errors?.['whitespace']"
                >Please enter a valid short name.</mat-error
              >
              <mat-error
                class="d-flex"
                *ngIf="projectForm.controls['projectNickname'].dirty && !projectForm.controls['projectNickname'].errors?.['required']  && !projectForm.controls['projectNickname'].errors?.['whitespace'] && projectForm.controls['projectNickname'].errors?.['pattern']"
                >Short name can only contain alphabets(A-Z, a-z), numbers(0 -
                9), dot(.), dash(-) and underscore(_).
              </mat-error>
            </div>
            <div class="mpr-form-fields-row-items mpr-project-id">
              <div class="mpr-form-block-field-label">Project ID </div>

              <mat-form-field
                class="mpr-width-percentage-95 mpr-project-id"
                appearance="outline"
              >
                <input
                  type="text"
                  id="projectId"
                  matInput
                  disabled
                  [value]="getProjectIdValue()"
                  class="mpr-mat-input-field"
                  aria-label="ProjectId"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
            <div class="mpr-form-fields-row-items">
              <label for="projectName" class="mpr-form-block-field-label"
                >Project Name<sup>*</sup>
              </label>
              <mat-form-field
                class="mpr-width-percentage-100"
                appearance="outline"
              >
                <input
                  type="text"
                  id="projectName"
                  formControlName="projectName"
                  matInput
                  class="mpr-mat-input-field"
                  required
                  maxlength="100"
                />
              </mat-form-field>
              <div
                class="mpr-form-block-field-wordcount"
                [ngClass]="
                  projectForm.controls['projectName'].value.length === 100
                    ? 'mpr-error-color'
                    : ''
                "
                >{{ projectForm.controls['projectName'].value.length }}/100
                characters</div
              >
              <mat-error
                *ngIf="projectForm.controls['projectName'].touched && projectForm.controls['projectName'].errors?.['required']"
                >Please enter project name.</mat-error
              >
              <mat-error
                *ngIf="projectForm.controls['projectName'].touched && !projectForm.controls['projectName'].errors?.['required'] && projectForm.controls['projectName'].errors?.['whitespace']"
                >Please enter a valid project name.</mat-error
              >
            </div>
          </div>
          <div class="mpr-form-block-field">
            <div class="mpr-label">
              <label
                for="projectRestrictedDataUseGroups"
                class="mpr-form-block-field-label"
                >Restricted data use groups
                <button class="mpr-btn-transparency" 
                  [mtxTooltip]="restrictedDataInfo" 
                  [mprMtxTooltipAriaDescribedBy]="restrictedDataInfo" 
                  #tooltip="mtxTooltip"
                >
                  <mat-icon
                    class="mpr-info-icon"
                    >info
                  </mat-icon>
                </button>
              </label>
              <div
                class="mpr-add-new-label"
                (click)="addRestrictedGroup()"
                *ngIf="isEdit || roleName === 'Project Admin'"
                >+ ADD NEW</div
              >
            </div>

            <mat-form-field
              class="mpr-metadata-dataTags-chip-list"
              appearance="outline"
              [ngClass]="{ 'ng-invalid ng-touched': (projectForm.controls['projectRestrictedDataUseGroups'].touched || projectForm.controls['projectRestrictedDataUseGroups'].dirty) &&
              (projectForm.controls['projectRestrictedDataUseGroups'].errors?.['maxlength'] || projectForm.controls['projectRestrictedDataUseGroups'].errors?.['pattern'])}"
            >
              <mat-chip-list #chipListMetaData [disabled]="isEdit">
                <mat-chip
                  *ngFor="let restrictedDataGroup of restrictedDataUseGroups"
                  (removed)="removeChip(restrictedDataGroup)"
                >
                  {{ restrictedDataGroup }}
                  <button matChipRemove *ngIf="!isEdit">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input
                  id="projectRestrictedDataUseGroups"
                  #restrictedGroupInput
                  [placeholder]="
                    isEdit || roleName === 'Project Admin'
                      ? ''
                      : 'Start typing and add comma to separate restricted groups'
                  "
                  formControlName="projectRestrictedDataUseGroups"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipListMetaData"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addChip($event)"
                  [matChipInputAddOnBlur]="addOnBlur"
                />
              </mat-chip-list>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="changeSelection($event)"
              ></mat-autocomplete>
            </mat-form-field>
            <mat-error
              class="mpr-error-padding-top-4px"
              *ngIf="projectForm.controls['projectRestrictedDataUseGroups'].errors?.['maxlength']"
              >Max 20 characters per tag. Please use comma to create different
              tags</mat-error
            >
            <mat-error
              *ngIf="projectForm.controls['projectRestrictedDataUseGroups'].dirty &&
              projectForm.controls['projectRestrictedDataUseGroups'].value.length > 0 &&
               projectForm.controls['projectRestrictedDataUseGroups'].errors?.['spaceValidators']"
              >Restricted data group must start with alphabets or numbers and it
              can contain only alphabets, numbers, dot(.), hyphen(-) and
              underscore(_).
            </mat-error>
            <mat-error
              *ngIf="projectForm.controls['projectRestrictedDataUseGroups'].dirty && projectForm.controls['projectRestrictedDataUseGroups'].errors?.['textValidators']"
            >
              {{projectForm.controls['projectRestrictedDataUseGroups'].errors?.['message']}}
            </mat-error>
            <div
              class="mpr-form-block-field-wordcount"
              *ngIf="!isEdit && roleName !== 'Project Admin'"
              >Max 20 characters per tag</div
            >
          </div>
          <div class="mpr-form-block-field">
            <label
              for="emailDistributionList"
              class="mpr-form-block-field-label"
              >Email Distribution List <sup>*</sup
              >
              <button class="mpr-btn-transparency" 
                [mtxTooltip]="emailDistributionInfo" 
                [mprMtxTooltipAriaDescribedBy]="emailDistributionInfo" 
                #tooltip="mtxTooltip"
              >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>  
            </label>

            <mat-form-field
              class="mpr-metadata-dataTags-chip-list"
              appearance="outline"
            >
              <mat-chip-list #emailListChipList>
                <mat-chip
                  *ngFor="let email of emailList"
                  [ngClass]="{ 'invalid-chip': isInvalidEmail(email) }"
                  (removed)="removeEmail(email)"
                >
                  {{ email }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input
                  id="emailDistributionList"
                  #emailListInput
                  required
                  placeholder="Start typing and add comma to separate Email list"
                  formControlName="emailDistributionList"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="emailListChipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addEmail($event)"
                  [matChipInputAddOnBlur]="addOnBlur"
                />
              </mat-chip-list>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="changeSelectionEmail($event)"
              ></mat-autocomplete>
            </mat-form-field>
            <mat-error
              *ngIf="emailList.length === 0 && projectForm.controls['emailDistributionList'].touched && projectForm.controls['emailDistributionList'].errors?.['required']"
              >Please enter Email list</mat-error
            >
            <mat-error
              *ngIf=" projectForm.controls['emailDistributionList'].dirty && !projectForm.controls['emailDistributionList'].errors?.['required'] && projectForm.controls['emailDistributionList'].errors?.['invalidEmail']"
              >Invalid email</mat-error
            >
            <mat-error *ngIf="hasInvalidEmails()">
              This domain name is not supported.
            </mat-error>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          roleName === 'Project Admin';
          else showAdditionalFeatureForPlatformAdmin
        "
      >
        <div class="mpr-form-block" *ngIf="data.dwAccess || data.hasPowerUsers">
          <div class="mpr-form-block-field-label"
            >Additional Features
            <button class="mpr-btn-transparency" 
              [mtxTooltip]="additionalFeatureInfo" 
              [mprMtxTooltipAriaDescribedBy]="additionalFeatureInfo" 
              #tooltip="mtxTooltip"
            >
              <mat-icon
                class="mpr-info-icon"
                >info
              </mat-icon>
            </button>  
          </div>
          <ul>
            <li *ngIf="data.dwAccess">
              <span class="mpr-additional-text">Data Warehouse : </span
              ><span class="mpr-additional-status">Enabled</span>
              <div
                class="d-flex mpr-form-fields-row mpr-flex-alignitems-start mpr-margin-bottom"
              >
                <div
                  class="mpr-block"
                  *ngIf="data.dwAccess && data.projectSize"
                >
                  <div class="p-2 mpr-warehouse-attribute">Warehouse Size</div>
                  <div class="mpr-warehouse-details">
                    {{ data.projectSize }}
                  </div>
                </div>
                <div class="mpr-block" *ngIf="data.dwAccess && data.credit">
                  <div class="p-2 mpr-warehouse-attribute">Credit</div>
                  <div class="mpr-warehouse-details">{{ data.credit }}</div>
                </div>
              </div>
            </li>
            <li *ngIf="data.hasPowerUsers">
              <span class="mpr-additional-text">Heavy Lifting : </span
              ><span class="mpr-additional-status">Enabled</span>
            </li>
          </ul>
        </div>
      </div>
      <ng-template #showAdditionalFeatureForPlatformAdmin>
        <div class="mpr-form-block">
          <div class="mpr-form-block-field-label"
            >Additional Features
            <button class="mpr-btn-transparency" 
              [mtxTooltip]="additionalFeatureInfo" 
              [mprMtxTooltipAriaDescribedBy]="additionalFeatureInfo" 
              #tooltip="mtxTooltip"
            >
              <mat-icon
                class="mpr-info-icon"
                >info
              </mat-icon>
            </button>  
          </div>
          <div>
            <mat-checkbox
              formControlName="dwAccess"
              class="mpr-enable-checkbox"
              [disabled]="data.dwAccess"
              (change)="enableDataWareHouse()"
              >Enable Data Warehouse</mat-checkbox
            >
            <div
              class="d-flex mpr-form-fields-row mpr-flex-alignitems-start mpr-margin-top-rem"
            >
              <div class="mpr-form-fields-row-items">
                <div for="projectSize" class="mpr-form-block-field-label"
                  >Warehouse Size <sup>*</sup>
                </div>

                <mat-form-field
                  class="mpr-mat-select-field mpr-width-percentage-95"
                  appearance="outline"
                  floatLabel="never"
                >
                  <mat-select
                    formControlName="projectSize"
                    placeholder="Select Size"
                  >
                    <mat-option
                      *ngFor="let size of wareHouseSize"
                      [value]="size"
                      >{{ size }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <mat-error
                  *ngIf="projectForm.controls['dwAccess'].value && projectForm.controls['projectSize'].touched && projectForm.controls['projectSize'].errors?.['required']"
                >
                  Please enter size</mat-error
                >
              </div>
              <div class="mpr-form-fields-row-items">
                <label for="credit" class="mpr-form-block-field-label"
                  >Credits <sup>*</sup>
                </label>
                <mat-form-field
                  class="mpr-width-percentage-95"
                  appearance="outline"
                >
                  <input
                    id="credit"
                    formControlName="credit"
                    class="mpr-form-block-field-input"
                    matInput
                    placeholder="Enter Credits"
                    type="number"
                    min="1"
                    oninput="validity.valid||(value='');"
                  />
                </mat-form-field>
                <mat-error
                  *ngIf="projectForm.controls['dwAccess'].value && projectForm.controls['credit'].touched && projectForm.controls['credit'].errors?.['required']"
                >
                  Please enter credits</mat-error
                >
                <mat-error
                  *ngIf="projectForm.controls['credit'].dirty && projectForm.controls['credit'].errors?.['pattern']"
                >
                  Please enter a valid credits
                </mat-error>
              </div>

              <div class="mpr-warning-message-box mpr-margin-top-2-rem">
                <span>
                  <img
                    src="assets/images/NoteIcon.svg"
                    alt="Warning"
                    class="mpr-delete-icon"
                  />
                </span>
                <span class="mpr-warning-text">
                  Enabling this feature, will enable Snowflake integration for
                  the project. When users are onboarded, they will get access to
                  Snowflake by default.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="mpr-form-block">
          <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
            <mat-checkbox
              formControlName="hasPowerUsers"
              class="mpr-enable-checkbox"
              [disabled]="data.hasPowerUsers"
              >Enable Heavy Lifting</mat-checkbox
            >
            <div class="mpr-warning-message-box">
              <span>
                <img
                  src="assets/images/NoteIcon.svg"
                  alt="Warning"
                  class="mpr-delete-icon"
                />
              </span>
              <span class="mpr-warning-text">
                Enabling this feature, will turn on Heavy-lifting access for the
                project. When users are onboarded, admin can choose to promote a
                user as power user, which will enable access to selected AWS
                services for them.
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </form>
  </div>
  <div class="mpr-buttons-row" fxLayout="row" fxLayoutAlign="flex-end">
    <div>
      <button
        mat-stroked-button
        class="mpr-button mpr-blue-button mpr-cancel-button"
        (click)="showCancelPopup()"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        [disabled]="
          !projectForm.valid ||
          isAnyChangeInEditForm ||
          hasInvalidEmails() ||
          emailList.length === 0
        "
        mat-button
        class="mpr-button mpr-yellow-button"
        (click)="showConfirmPopup()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
<ng-template #restrictedDataInfo>
  <div class="mpr-width-px-300" id="mpr-restricted-groups">
    Restricted data use groups are created to store project level sensitive
    restricted datasets that may require meeting DUA/MOU restrictions, contain
    PII/PHI information, can only be accessed by a subset of authorized
    individuals within Projects.
  </div>
</ng-template>

<ng-template #emailDistributionInfo>
  <div class="mpr-width-px-300" id="mpr-email-list">
    Email ids added to this list will be notified when new users are added to a
    project, user attributes are updated or when users are offboarded.
  </div>
</ng-template>

<ng-template #additionalFeatureInfo>
  <div class="mpr-width-px-300" id="mpr-additional-features">
    Please contact Platform admin to make any changes to the additional
    features.
  </div>
</ng-template>
