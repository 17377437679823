import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BillingListModel } from './billing-list.model';
import { BillingService } from '@shared/services/billing.service';
import { GetBillingList, ResetBillingList } from './billing.action';
import { BillingModel } from './billing.model';

const stateDefaults = {
    billingList: []
}

@State<BillingListModel>({
    name: 'BillingState',
    defaults: stateDefaults
  })
  @Injectable()
  export class BillingState {
    constructor(private billingService: BillingService) { }

    @Action(GetBillingList)
    public getBillingList({
      patchState,
    }: StateContext<BillingListModel>): Observable<BillingModel[]> {
      return this.billingService.getBillingList().pipe(
        tap((billingList: BillingModel[]): any => {
          patchState({ billingList });
        })
      );
    }

    @Action(ResetBillingList)
    public resetBillingList({
          patchState
    }: StateContext<BillingListModel>): void {
      patchState({ ...stateDefaults });
    }

    @Selector()
    public static returnBillingData(state: BillingListModel): any {
        return state.billingList;
    }
  }