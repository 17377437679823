/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserProjectRoleEnum } from '@core/enums';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { TileConfig, User, UserProject } from '@theme/interfaces';
import {
  DashboardLoaderState,
  FeatureFlagsState,
  UserProjectState,
  ChangeSFRoleState,
} from 'app/state';
import { Observable, Subject, Subscription } from 'rxjs';
import * as constants from '@core/constants';
import { AuthService } from '@core/services';
import { SkipMainContentService } from '@shared/services';
import { ProjectState } from 'app/state/project/project.state';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { ChangeSFDefaultRole } from 'app/state/change-sf-role/change-sf-role.action';
import { CommonResponseModel } from '@shared/interfaces';
import { LoadUserInfodata } from 'app/state/user-info/user-info.actions';
import { UserInfoState } from 'app/state/user-info/user-info.state';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Select(DashboardLoaderState.getDashboardLoaderState)
  public showSpinner$?: Observable<boolean>;
  @Select(UserProjectState.getSelectedProject)
  public project$!: Observable<UserProject>;
  @Select(ProjectState.getSnowflakeProvisioningStatus)
  public snowflakeProvisioningStatus$!: Observable<string>;
  @Select(FeatureFlagsState.returnFeatureFlags)
  public featureFlags$!: Observable<FeatureFlagsStateModel>;
  @Select(ChangeSFRoleState.getChangeRoleStatus)
  public changeSFRoleStatus$!: Observable<CommonResponseModel>;
  @Select(UserInfoState.getCurrentUserRole)
  private userRole$?: Observable<string>;
  public isDWClicked = false;
  public isTermsAccepted = false;
  public sfRoleResponse: CommonResponseModel = {
    status_code: 0,
    message: '',
  };
  public selectedProject!: UserProject;
  public selectedRoleName = '';
  public selectedUser!: User;
  private destroyed$ = new Subject<boolean>();
  private sfObserverSubscription = new Subscription();
  private projectsObserverSubscription = new Subscription();
  public tileConfigs: TileConfig[] = [
    {
      img: 'assets/images/Search_Data_Library.svg',
      title: constants.SEARCH_DATA_CATALOG,
      tag: 'VISIBLE TO ALL',
      desc: 'Search for Public use, Restricted use and Project data files.',
      roleSetting: [
        UserProjectRoleEnum.RESEARCHER,
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
        UserProjectRoleEnum.EXTERNAL_RESEARCHER,
      ],
      isVisible: false,
      isDisabled: false,
      path: `${environment.ckanUrl}${environment.ckanLoginPath}`,
      externalUrl: true,
      target: '_self',
    },
    {
      img: 'assets/images/Upload_File.svg',
      title: constants.PUBLISH_TO_CATALOG,
      tag: '',
      desc: 'Manage one-time and recurring data file uploads.',
      roleSetting: [
        UserProjectRoleEnum.RESEARCHER,
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
        UserProjectRoleEnum.EXTERNAL_RESEARCHER,
      ],
      isVisible: false,
      isDisabled: false,
      path: '/uploads',
      externalUrl: false,
    },
    {
      img: 'assets/images/My_Workspace.svg',
      title: constants.WORKSPACE,
      tag: '',
      desc: 'Access data processing and analysis tools.',
      roleSetting: [
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
      ],
      isVisible: false,
      isDisabled:
        this.selectedProject?.serverUrls?.rstudio &&
        this.selectedProject?.serverUrls?.jupyter
          ? false
          : true,
      path: '/workspace',
      externalUrl: false,
    },
    {
      img: 'assets/images/My_Workspace.svg',
      title: constants.WORKSPACE,
      tag: '',
      desc: 'Access data processing and analysis tools.',
      roleSetting: [
        UserProjectRoleEnum.RESEARCHER,
        UserProjectRoleEnum.EXTERNAL_RESEARCHER,
      ],
      path: 'workspace/aws',
      isVisible: false,
      isDisabled: false,
      externalUrl: false,
    },
    {
      img: 'assets/images/file-manager-icon.svg',
      title: constants.FILE_MANAGER,
      tag: '',
      desc: 'Organize data files in your project staging location.',
      roleSetting: [
        UserProjectRoleEnum.RESEARCHER,
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
        UserProjectRoleEnum.EXTERNAL_RESEARCHER,
      ],
      isVisible: false,
      isDisabled:
        this.selectedProject?.serverUrls?.rstudio &&
        this.selectedProject?.serverUrls?.jupyter
          ? false
          : true,
      path: '/filemanager',
    },
    {
      img: 'assets/images/Manage_Data_Connections.svg',
      title: constants.MANAGE_DATA_CONNECTIONS,
      tag: '',
      desc: 'Add data connections and their credentials to transfer files from external data sources such as AWS S3, Box.com.',
      roleSetting: [
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
      ],
      isVisible: false,
      isDisabled: false,
      path: '/connections',
      externalUrl: false,
    },
    {
      img: 'assets/images/Data_Warehouse.svg',
      title: constants.DATA_WAREHOUSE,
      tag: '',
      desc: 'Store and analyze large volumes of data.',
      roleSetting: [
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.RESEARCHER,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
        UserProjectRoleEnum.EXTERNAL_RESEARCHER,
      ],
      isVisible: false,
      isDisabled: false,
      path: `${environment.snowflakeUrl}`,
      externalUrl: true,
      target: '_blank',
    },
    {
      img: 'assets/images/admin_console.svg',
      title: constants.ADMIN_CONSOLE,
      tag: '',
      desc: 'Manage Projects, Users and Resources.',
      roleSetting: [UserProjectRoleEnum.ADMIN],
      path: '/admin',
      isVisible: false,
      isDisabled: false,
      externalUrl: false,
    },
    {
      img: 'assets/images/admin_console.svg',
      title: constants.ADMIN_CONSOLE,
      tag: '',
      desc: 'Manage Projects, Users and Resources.',
      roleSetting: [UserProjectRoleEnum.PLATFORM_ADMIN],
      path: '/platformAdmin',
      isVisible: false,
      isDisabled: false,
      externalUrl: false,
    },
  ];
  @ViewChild('skipper') public skipper!: ElementRef;

  constructor(
    private router: Router,
    public store: Store,
    private authService: AuthService,
    private skipMainContentService: SkipMainContentService,
    public dialog: MatDialog
  ) {}

  public closeUploadDialog(): void {
    this.dialog.closeAll();
  }

  public navigateToRoute(
    routePath: string,
    isExternalUrl: boolean | undefined,
    target: string | undefined,
    title: string | undefined
  ): void {
    if (!isExternalUrl) {
      if (routePath === '') {
        this.skipMainContentService.fetchValueToOpenUploadPopup();
      } else {
        this.router.navigate([`${routePath}`]);
      }
    } else {
      if (title === constants.DATA_WAREHOUSE) {
        this.isDWClicked = true;
        if (this.sfRoleResponse.status_code !== 0) {
          this.handleExternalUrls(routePath, target);
          this.isDWClicked = false;
          this.skipMainContentService.showHideLoaderForOpeningDataWareHouse(
            false
          );
        } else {
          this.skipMainContentService.showHideLoaderForOpeningDataWareHouse(
            true
          );
        }
      } else {
        this.isDWClicked = false;
        this.handleExternalUrls(routePath, target);
      }
    }
  }

  ngOnInit(): void {
    this.featureFlags$.subscribe((featureFlags: FeatureFlagsStateModel) => {
      if (featureFlags.dwFeatureFlag !== '') this.handleInitLoad();
    });
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.nativeElement.focus();
    });

    this.changeSFRoleStatus$.subscribe((res: CommonResponseModel) => {
      this.sfRoleResponse = res;
      if (this.isDWClicked) {
        if (res.status_code !== 0) {
          window.open(`${environment.snowflakeUrl}`, '_blank');
          this.isDWClicked = false;
          this.skipMainContentService.showHideLoaderForOpeningDataWareHouse(
            false
          );
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private handleExternalUrls(
    externalUrl: string,
    target: string | undefined
  ): void {
    if (this.authService.check()) window.open(externalUrl, target);
    else this.authService.authenticate(true);
  }

  private handleInitLoad(): void {
    this.userRole$?.subscribe((roleName: string) => {
      this.selectedRoleName =
        roleName === UserProjectRoleEnum.BLANK
          ? UserProjectRoleEnum.GENERAL_RESEARCHER
          : roleName;

      this.resetConsoleTileDisplay();
    });
    this.projectsObserverSubscription = this.project$?.subscribe(
      (project: UserProject) => {
        this.selectedProject = project;
        if (this.selectedProject.roleName !== UserProjectRoleEnum.BLANK) {
          this.sfObserverSubscription.unsubscribe();
          this.sfObserverSubscription =
            this.snowflakeProvisioningStatus$.subscribe(
              (snowflakeProvisioningResponse) => {
                //If we do not still have completed this wait.
                if (snowflakeProvisioningResponse === '') return;

                const featureFlags: FeatureFlagsStateModel =
                  this.store.selectSnapshot(FeatureFlagsState);
                const dwResponse: string = featureFlags.dwFeatureFlag;
                const dwTile =
                  snowflakeProvisioningResponse === 'active' &&
                  dwResponse.toLowerCase() === 'true';
                this.setRoleVisibility(dwTile);
                this.setVisibilityAsPerFeatureFlags(featureFlags);
                if (
                  dwTile &&
                  this.store.selectSnapshot(
                    ChangeSFRoleState.getChangeRoleStatus
                  ).status_code === 0
                ) {
                  this.store.dispatch(
                    new ChangeSFDefaultRole(
                      this.selectedProject.projectId,
                      this.selectedProject.roleName
                    )
                  );
                }
              }
            );
        } else {
          if (this.selectedRoleName) {
            this.resetConsoleTileDisplay();
          }
        }
      }
    );
  }

  private resetConsoleTileDisplay(): void {
    this.tileConfigs = this.tileConfigs.map((tile: TileConfig) => ({
      ...tile,
      isVisible: this.setDefaultRoleVisible(tile, this.selectedRoleName),
    }));
  }

  private setDefaultRoleVisible(
    tile: TileConfig,
    selectedRoleName: string
  ): boolean {
    if (tile.title === constants.SEARCH_DATA_CATALOG) {
      return true;
    } else if (
      selectedRoleName === UserProjectRoleEnum.PLATFORM_ADMIN &&
      tile.title === constants.ADMIN_CONSOLE &&
      tile.path === '/platformAdmin'
    ) {
      return true;
    } else if (
      selectedRoleName === UserProjectRoleEnum.PLATFORM_ADMIN &&
      tile.title === constants.ADMIN_CONSOLE &&
      tile.path === '/admin'
    ) {
      return false;
    } else {
      return tile.isVisible;
    }
  }

  private setFeatureFlagAsPerTiles(
    tile: TileConfig,
    featureFlags: FeatureFlagsStateModel
  ): boolean {
    if (
      tile.title === constants.WORKSPACE &&
      tile.roleSetting.includes(UserProjectRoleEnum.RESEARCHER)
    ) {
      return (
        featureFlags.isAwsWorkspacesAvailable.toLowerCase() === 'true' &&
        tile.roleSetting.includes(this.selectedProject.roleName)
      );
    } else if (tile.title === constants.FILE_MANAGER) {
      return (
        featureFlags.isStagingAccessAvailable.toLowerCase() === 'true' &&
        tile.roleSetting.includes(this.selectedProject.roleName)
      );
    } else if (
      tile.title === constants.ADMIN_CONSOLE &&
      this.selectedRoleName !== UserProjectRoleEnum.PLATFORM_ADMIN &&
      tile.path === '/admin'
    ) {
      return (
        featureFlags.isProjectAdminConsole.toLowerCase() === 'true' &&
        tile.roleSetting.includes(this.selectedProject.roleName)
      );
    } else if (
      tile.title === constants.ADMIN_CONSOLE &&
      this.selectedRoleName === UserProjectRoleEnum.PLATFORM_ADMIN &&
      tile.path === '/platformAdmin'
    ) {
      return true;
    } else {
      return tile.title === constants.ADMIN_CONSOLE &&
        this.selectedRoleName === UserProjectRoleEnum.PLATFORM_ADMIN &&
        tile.path === '/admin'
        ? false
        : tile.isVisible;
    }
  }

  private setRoleVisibility(dwAccessStatus: boolean): void {
    this.tileConfigs = this.tileConfigs.map((tile: TileConfig) => ({
      ...tile,
      isVisible:
        tile.title === constants.DATA_WAREHOUSE
          ? tile.roleSetting.includes(this.selectedProject.roleName) &&
            dwAccessStatus
          : tile.roleSetting.includes(this.selectedProject.roleName),
    }));
  }

  private setVisibilityAsPerFeatureFlags(
    featureFlags: FeatureFlagsStateModel
  ): void {
    this.tileConfigs = this.tileConfigs.map((tile: TileConfig) => ({
      ...tile,
      isVisible: this.setFeatureFlagAsPerTiles(tile, featureFlags),
    }));
  }
}
