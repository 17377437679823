<div class="mpr-upload-history">
  <div class="mpr-upload-history-header">
    <h1>Upload History</h1>
    <p>
      The table below provides details of all the data files uploaded by project
      users
    </p>
  </div>
  <div class="mpr-tabs-container">
    <mat-tab-group
      [(selectedIndex)]="activeTabIndex"
      (selectedTabChange)="selectedTabChange($event)">
      <mat-tab label="ONE TIME UPLOAD">
        <div
          id="oneTimeUpload"
          *ngIf="dataOneTimeUpload.length; else noResultOneTime">
          <mat-table
            [dataSource]="dataSourceOneTimeOpload"
            class="staging-upload-history-table mpr-tables one-time-history-table"
            matSort
            matSortActive="uploadDate"
            matSortDisableClear
            matSortDirection="desc"
            (matSortChange)="oneTimeSortChange($event)"
            role="table"
            aria-rowcount="-1"
          >
            <ng-container matColumnDef="userName">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="frequency-header-cell"
                scope="col"
              >
                Uploaded by
                <span
                  class="mpr-sort-image"
                  [ngClass]="getSortArrowDirectionClass(sortKeyNameEnum.MODIFIEDBY)"
                  [attr.aria-label]="
                    getSortColumnAriaLabel(sortKeyNameEnum.MODIFIEDBY)
                  "
                ></span>
              </mat-header-cell>
              <mat-cell *matCellDef="let history" class="frequency-cell">
                {{ history.userName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="dataSource">
              <mat-header-cell *matHeaderCellDef scope="col" class="cell-container">
                Data Source
              </mat-header-cell>
              <mat-cell *matCellDef="let history" class="cell-container">
                <ng-container *ngIf="history.displaySource">
                  <ng-container *ngFor="
                    let source of history.displaySource;
                    let index = index">
                    <ng-container [ngSwitch]="source" class="data-source">
                      <ng-container *ngIf="index >= 1"><br /></ng-container>
                      <ng-container *ngSwitchCase="connectionDataStoreEnum.BOX"
                        >Box.com</ng-container
                      >
                      <ng-container
                        *ngSwitchCase="connectionDataStoreEnum.LOCAL"
                        >Local Drive</ng-container
                      >
                      <ng-container *ngSwitchCase="connectionDataStoreEnum.S3"
                        >External S3</ng-container
                      >
                      <ng-container
                        *ngSwitchCase="connectionDataStoreEnum.DATA_LIBRARY_S3"
                        >Data Library S3</ng-container
                      >
                      <ng-container
                        *ngSwitchCase="connectionDataStoreEnum.DL_S3"
                        >Data Library S3</ng-container
                      >
                      <ng-container
                        *ngSwitchCase="connectionDataStoreEnum.SNOWFLAKE"
                        >Snowflake</ng-container
                      >
                      <ng-container
                        *ngSwitchCase="connectionDataStoreEnum.CHART"
                        >Chart</ng-container
                      >
                      <ng-container
                        *ngSwitchCase="connectionDataStoreEnum.WEB_URL"
                        >Web URL</ng-container
                      >
                      <ng-container *ngSwitchCase="connectionDataStoreEnum.API"
                        >API</ng-container
                      >
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!history.displaySource">
                  <ng-container [ngSwitch]="history.dataStore">
                    <ng-container *ngSwitchCase="connectionDataStoreEnum.BOX"
                      >Box.com</ng-container
                    >
                      <ng-container *ngSwitchCase="connectionDataStoreEnum.LOCAL"
                        >Local Drive</ng-container
                      >
                      <ng-container *ngSwitchCase="connectionDataStoreEnum.S3"
                        >External S3</ng-container
                      >
                      <ng-container
                        *ngSwitchCase="connectionDataStoreEnum.DATA_LIBRARY_S3"
                        >Data Library S3</ng-container
                      >
                      <ng-container *ngSwitchCase="connectionDataStoreEnum.DL_S3"
                        >Data Library S3</ng-container
                      >
                    </ng-container>
                </ng-container>
              </mat-cell>
            </ng-container>
          <ng-container matColumnDef="totalNoOfFiles">
            <mat-header-cell *matHeaderCellDef scope="col">
              # Of Files
            </mat-header-cell>
            <mat-cell *matCellDef="let history">
              {{ history.totalNoOfFiles }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="uploadDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
              Date Uploaded
              <span
                class="mpr-sort-image"
                [ngClass]="
                  getSortArrowDirectionClass(sortKeyNameEnum.MODIFIEDDATE)
                "
                [attr.aria-label]="
                  getSortColumnAriaLabel(sortKeyNameEnum.MODIFIEDDATE)
                "
              ></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let history">
              {{ history.uploadDate | utcDate }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef scope="col">
              Dataset Name
            </mat-header-cell>
            <mat-cell *matCellDef="let history">
              <span *ngIf="history.description; else notApplicable">{{
                history.description
              }}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="jobStatus">
            <mat-header-cell *matHeaderCellDef scope="col">
              Upload Status
            </mat-header-cell>
            <mat-cell *matCellDef="let history" class="d-row">
              <div [ngClass]="history.jobStatus" *ngIf="history.jobStatus">
                <ng-container
                  [ngTemplateOutlet]="jobStatusTemplate"
                  [ngTemplateOutletContext]="{ $implicit: history.jobStatus }"
                ></ng-container>
              </div>
              <button
                #skipper
                (click)="proceedToOneTimeJobDetails(history)"
                class="mpr-link mpr-btn-transparency"
                title="View Upload Details"
                aria-label="View Details"
              >
                View Details
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let history"> </mat-cell>
          </ng-container>
          <mat-header-row
            role="rowheader"
            *matHeaderRowDef="columnsOneTimeUpload; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: columnsOneTimeUpload"></mat-row>
        </mat-table>
        <div class="d-flex mpr-justify-content-center">
          <mpr-load-more
            [spinner]="showSpinner"
            [show]="showLoadForOneTime"
            (loadMoreMethod)="loadMoreOnetimeUpload()"
          ></mpr-load-more>
        </div>
      </div>
      <ng-template #noResultOneTime>
        <div class="mpr-upload-history-noresult">
          <div class="mpr-noresult-container">
            <div class="mpr-noresutl-error">
              <span><img src="assets/images/Error.svg" alt="Error" /> </span>
              <span>{{ oneTimeUploadJobEmptyError }}</span>
            </div>
          </div>
        </div>
      </ng-template>
      </mat-tab>
      <mat-tab label="RECURRING UPLOAD" *ngIf="isRecurringStagingUploadAvailable">
        <div
          id="recurringUpload"
          *ngIf="
            dataRecurringUpload && dataRecurringUpload.length;
            else noResultFRecurring
          "
        >
          <mat-table
            [dataSource]="dataSourceRecurringUpload"
            class="staging-upload-history-table mpr-tables recurring-history-table"
            matSort
            matSortActive="runDate"
            matSortDisableClear
            matSortDirection="asc"
            (matSortChange)="recurringSortChange($event)"
            role="table"
            aria-rowcount="-1"
          >
            <ng-container matColumnDef="scheduleName">
              <mat-header-cell *matHeaderCellDef scope="col">
                Schedule Name
              </mat-header-cell>
              <mat-cell *matCellDef="let history">
                {{ history.scheduleName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="frequency">
              <mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
                Frequency
                <span
                  class="mpr-sort-image"
                  [ngClass]="
                    getSortArrowDirectionClass(sortKeyNameEnum.FREQUENCY)
                  "
                  [attr.aria-label]="
                    getSortColumnAriaLabel(sortKeyNameEnum.FREQUENCY)
                  "
                ></span>
              </mat-header-cell>
              <mat-cell *matCellDef="let history">
                {{ history.frequency }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="dataStore">
              <mat-header-cell *matHeaderCellDef scope="col">
                Data Source
              </mat-header-cell>
              <mat-cell *matCellDef="let history">
                {{ history.dataStore }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="connectionName">
              <mat-header-cell *matHeaderCellDef scope="col">
                Connection
              </mat-header-cell>
              <mat-cell *matCellDef="let history">
                {{ history.connectionName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="totalNoOfFiles">
              <mat-header-cell *matHeaderCellDef scope="col">
                # Of Files
              </mat-header-cell>
              <mat-cell *matCellDef="let history">
                {{ history.totalNoOfFiles }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="runDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
                Last Run Date
                <span
                  class="mpr-sort-image"
                  [ngClass]="
                    getSortArrowDirectionClass(sortKeyNameEnum.MODIFIEDDATE)
                  "
                  [attr.aria-label]="
                    getSortColumnAriaLabel(sortKeyNameEnum.MODIFIEDDATE)
                  "
                ></span>
              </mat-header-cell>
              <mat-cell *matCellDef="let history">
                {{ history.runDate | utcDate }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="jobStatus">
              <mat-header-cell *matHeaderCellDef scope="col">
                Upload Status
              </mat-header-cell>
              <mat-cell *matCellDef="let history" class="d-row">
                <div [ngClass]="history.jobStatus" *ngIf="history.jobStatus">
                  <ng-container
                    [ngTemplateOutlet]="jobStatusTemplate"
                    [ngTemplateOutletContext]="{ $implicit: history.jobStatus }"
                  ></ng-container>
                </div>
                <button
                  (click)="proceedToRecurringJobDetails(history)"
                  class="mpr-link mpr-btn-transparency"
                  title="View Upload details"
                  aria-label="View Details"
                >
                  View Details
                </button>
              </mat-cell>
            </ng-container>
            <mat-header-row
              role="rowheader"
              *matHeaderRowDef="columnsRecurringUpload; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: columnsRecurringUpload"
            ></mat-row>
          </mat-table>
          <div class="d-flex mpr-justify-content-center">
            <mpr-load-more
              [spinner]="showSpinner"
              [show]="showLoadForRecUpload"
              (loadMoreMethod)="loadMoreRecurringUpload()"
            ></mpr-load-more>
          </div>
        </div>
        <ng-template #noResultFRecurring>
          <div class="mpr-recurring-noresult">
            <div class="mpr-noresult-container">
              <div class="mpr-noresutl-error">
                <span><img src="assets/images/Error.svg" alt="Error" /> </span>
                <span>{{ recurringResultsEmptyMessage }}</span>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<ng-template #jobStatusTemplate let-jobStatus>
  <ng-container [ngSwitch]="jobStatus">
    <span *ngSwitchCase="uploadStatusesEnum.COMPLETED">Completed</span>
    <span *ngSwitchCase="uploadStatusesEnum.COMPLETED_WITH_ERRORS"
      >Completed with errors</span
    >
    <span *ngSwitchCase="uploadStatusesEnum.UPLOAD_FAILED">Error</span>
    <span *ngSwitchCase="uploadStatusesEnum.SCAN_FAILED">Error</span>
    <span *ngSwitchCase="uploadStatusesEnum.CATALOGING_FAILED">Error</span>
    <span *ngSwitchDefault>In-Progress</span>
  </ng-container>
</ng-template>

<ng-template #notApplicable>
  <i>Not Applicable</i>
</ng-template>
