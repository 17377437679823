import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  LoadSelectedProjectMetadata,
  ProjectState,
  ResetSelectedProjectMetadata,
  ResetUserProjectState,
  UserProjectState,
} from 'app/state';
import { Observable } from 'rxjs';
import { SkipMainContentService } from '@shared';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { UserProjectRoleEnum } from '@core';
import ProjectModel from 'app/state/project/project-state-model';
import { UserProject } from '@theme/interfaces';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss'],
})
export class ProjectViewComponent implements OnInit, OnDestroy {
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  @Select(ProjectState.getSelectedProjectData)
  public projectData$?: Observable<ProjectModel>;

  @ViewChild('skipper') public skipper!: ElementRef;
  public encodedProjectId: string;
  public panelOpenState = true;
  public projectId: string;
  public showSpinner = true;
  public userRole = UserProjectRoleEnum.PLATFORM_ADMIN;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public store: Store,
    private skipMainContentService: SkipMainContentService
  ) {
    this.projectId = this.route.snapshot.params['projectId'];
    this.encodedProjectId = this.projectId
      ? btoa(this.route.snapshot.params['projectId'])
      : '';
  }

  public navigateToRoute(routeStr?: string): void {
    if (!routeStr) {
      routeStr =
        this.userRole === UserProjectRoleEnum.ADMIN
          ? `/admin/project/edit`
          : `/platformAdmin/projects/edit/${this.projectId}`;
    } else if (routeStr === 'view-all') {
      routeStr = this.encodedProjectId
        ? `/platformAdmin/users/list/${this.encodedProjectId}`
        : `/admin/users`;
    }
    this.router.navigate([routeStr]);
  }

  ngOnDestroy(): void {
    // Ensure we have clean slate when user goes off with this.
    if (this.userRole !== UserProjectRoleEnum.ADMIN) {
      this.store.dispatch(new ResetSelectedProjectMetadata());
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new ResetSelectedProjectMetadata());
    if (!this.projectId) {
      this.project$?.subscribe((project: UserProject) => {
        this.projectId = project.projectId;
        if (project.roleName === UserProjectRoleEnum.ADMIN) {
          this.userRole = UserProjectRoleEnum.ADMIN;
        }
      });
    }
    const requestHeaders: MprHttpHeaderModal = {};
    requestHeaders[HeaderParams.PROJECTID] = this.projectId;
    requestHeaders[HeaderParams.ROLENAME] = this.userRole;
    this.store.dispatch(new LoadSelectedProjectMetadata(requestHeaders));

    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.nativeElement.focus();
    });
  }
}
