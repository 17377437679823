import { AlertMessageService } from '@core/services';
import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConnectionModel, CommonResponseModel } from '@shared/interfaces';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MprFile } from 'app/uploads/interfaces/mpr-file-model';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  ConnectionDataStore,
  ConnectionType,
  JobOperationTypeEnums,
  SelectFileTypeEnum,
  UserProjectRoleEnum,
} from '@core/enums';
import {
  BoxContentPickerComponent,
  MprConfirmComponent,
  S3ContentPickerComponent,
} from '@shared/components';
import { Observable, catchError, throwError, withLatestFrom } from 'rxjs';
import {
  ConnectionState,
  GetAllConnections,
  SetSelectedConnection,
  ResetConnectionState,
} from 'app/state/connection';
import { SelectFileForUploads, SetExistingFileNames, UserProjectState } from 'app/state';
import { MatTable } from '@angular/material/table';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { UserProject } from '@theme/interfaces';
import { SkipMainContentService } from '@shared/services';
import { MatSelect } from '@angular/material/select';
import * as constants from '@core/constants';
import { DatasetDetailsModel } from 'app/uploads/interfaces';
import { ComponentType } from '@angular/cdk/portal';
import { CloudTransferComponent } from 'app/uploads/cloud-transfer/cloud-transfer.component';
import { OnPremiseUploadComponent } from 'app/uploads/on-premise-upload/on-premise-upload.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckFileManagerObjectExists, S3PickerState } from 'app/state/s3-picker';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-one-time-upload-staging',
  templateUrl: './one-time-upload-staging.component.html',
  styleUrls: ['./one-time-upload-staging.component.scss'],
})
export class OneTimeUploadStagingComponent implements OnInit {
  @Select(UserProjectState.getAllUserProjects)
  public allProject$?: Observable<UserProject[]>;
  @Select(ConnectionState.getConnectionLoading)
  public connectionLoading$?: Observable<boolean>;
  @Select(ConnectionState.getConnectionsLoading)
  public connectionsLoading$?: Observable<boolean>;
  @ViewChild('fileUpload', { static: false }) public fileUpload?: ElementRef;
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  @ViewChild('typeOfDataSource') public skipper!: MatSelect;
  @ViewChild('filesTable') public table!: MatTable<any>;
  @Select(ConnectionState.getAllActiveIngestionConnections)
  private connections$?: Observable<ConnectionModel[]>;
  @ViewChild('DuplicateFileMsg')
  private duplicateFileMsg?: TemplateRef<any>;
  @Select(S3PickerState.s3ObjectResponse)
  private s3ObjectResponse$!: Observable<CommonResponseModel>;

  public connectionList: ConnectionModel[] = [];
  public datasetDetails: DatasetDetailsModel = {
    datasetId: '',
    projectID: '',
    datasetName: '',
  };
  public defaultConnectionValue: ConnectionModel = {
    connectionStatus: '',
    projectId: '',
    dataStore: ConnectionDataStore.LOCAL,
    createdDate: '',
    connectionName: '',
    connectionType: ConnectionType.INGESTION,
    emailId: '',
    connectionId: '',
    modifiedDate: '',
    createdBy: '',
    createdByName: '',
    modifiedBy: '',
  };
  public defaultValue = '';
  public destinationPath = '';
  public displayDestinationPath = '';
  public displayedColumns = ['serialNum', 'fileName', 'fileSize', 'removeFile'];
  public duplicateFileList: string[] = [];
  public fileCount = 0;
  public fileList: MprFile[] = [];
  public fileType = SelectFileTypeEnum.FILES;
  public requestHeaders: MprHttpHeaderModal = {};
  public returnPath: string;
  public selectFileTypeEnum = SelectFileTypeEnum;
  public selectedConnection: ConnectionModel = this.defaultConnectionValue;
  public selectedDestinationFolderURL = '';
  public unsupportedFileTypes = ['EXE', 'COM', 'MSI', 'BAT', 'CGI']; // TODO: Will soon move to a externally loaded API / JSON
  private dialogSize: MatDialogConfig = {
    minHeight: '250px ',
    width: '70%',
  };
  private selectedProject!: UserProject;
  constructor(
    private alertMsgService: AlertMessageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public store: Store,
    private router: Router,
    private skipMainContentService: SkipMainContentService
  ) {
    this.returnPath = route.snapshot.data['back'];
    this.selectedDestinationFolderURL =
      route.snapshot.paramMap.get('selectedDestinationFolderPath') ?? '';
    if (this.selectedDestinationFolderURL)
      this.selectedDestinationFolderURL = atob(
        this.selectedDestinationFolderURL
      );
    this.destinationPath = this.displayDestinationPath =
      this.selectedDestinationFolderURL;
  }

  public checkObjectExists(): void {

    const prefix = this.destinationPath + '/';
    const destinationPrefix = this.destinationPath.endsWith('/') ? this.destinationPath : `${this.destinationPath}/`;
    const s3objectNames: string[] = [];
    this.fileList.forEach((element) => {
      s3objectNames.push(element.name);
    });
    const action = 'check_object_exists';
    const fileType = 'True';

    this.store
      .dispatch(
        new CheckFileManagerObjectExists(
          destinationPrefix,
          s3objectNames,
          action,
          fileType
        )
      )
      .pipe(
        withLatestFrom(this.s3ObjectResponse$),
        catchError((err) =>
          // this.showError(err.error.message);
           throwError(() => new Error(''))
        )
      )
      .subscribe((response: any) => {
        if (
          (response[1].status_code === 200 || response[1].status_code === 0) &&
          response[1].message
        ) {
          if (
            response[1].message.length > 0 &&
            typeof response[1].message == 'object'
          ) {
            this.duplicateFileList = response[1].message;
            this.dialog
              .open(MprConfirmComponent, {
                disableClose: true,
                data: {
                  cancelButtonText: 'Cancel',
                  confirmButtonText: 'Keep a Copy',
                  confirmData: true,
                  confirmTitle: 'Duplicate file(s) found',
                  innerContent: this.duplicateFileMsg,
                },
              })
              .afterClosed()
              .subscribe((keepACopy: boolean) => {
                this.store.dispatch(new SetExistingFileNames(this.duplicateFileList));
                if (keepACopy) this.startFileUpload();
              });
          } else {
            this.startFileUpload()
          }
        }
      });
  }

  public getFiles(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const selectedFiles: MprFile[] = Array.from(target.files);
      this.handleFileSelections(selectedFiles, this.fileType);
    }
    // Clear the input
    target.value = '';
  }
  public navigateBack(): void {
    this.router.navigate([this.returnPath]);
  }
  ngOnInit(): void {
    if (this.datasetDetails.datasetName !== '') {
      this.allProject$?.subscribe((allProjects: UserProject[]) => {
        this.store.dispatch(new ResetConnectionState());
        const selectedProject = allProjects.find(
          (project: UserProject) =>
            project.projectId === this.datasetDetails.projectID
        );
        this.requestHeaders = {};
        this.connectionList = [];
        if (selectedProject) {
          this.requestHeaders[HeaderParams.ROLENAME] =
            selectedProject?.roleName || UserProjectRoleEnum.BLANK;
          this.requestHeaders[HeaderParams.PROJECTID] =
            this.datasetDetails.projectID || '';
          if (selectedProject?.roleName !== UserProjectRoleEnum.RESEARCHER && selectedProject?.roleName !== UserProjectRoleEnum.EXTERNAL_RESEARCHER)
            this.store.dispatch(new GetAllConnections(this.requestHeaders));
          else this.loadPremiseConnection();
        } else {
          this.requestHeaders[HeaderParams.ROLENAME] =
            UserProjectRoleEnum.GENERAL_RESEARCHER;
          this.requestHeaders[HeaderParams.PROJECTID] = '';
          this.loadPremiseConnection();
        }
        this.connections$?.subscribe((connections: ConnectionModel[]) => {
          this.connectionList = [...connections];
          this.loadPremiseConnection();
        });
      });
    }
    // one time upload and add files to job flow
    else {
      this.project$?.subscribe((project: UserProject) => {
        this.selectedProject = project;
        if (project.roleName === UserProjectRoleEnum.RESEARCHER || project.roleName === UserProjectRoleEnum.EXTERNAL_RESEARCHER) {
          this.loadPremiseConnection();
        } else {
          this.store.dispatch(new GetAllConnections());
          this.connectionList = [];
          this.connections$?.subscribe((connections: ConnectionModel[]) => {
            this.connectionList = [...connections];
            this.loadPremiseConnection();
          });
        }
      });
    }
    this.skipMainContentService.skip$.subscribe(() => {
      this.skipper.focus();
    });
  }
  public removeFilesFromList(file: MprFile, fileType: string): void {
    if (fileType === SelectFileTypeEnum.FILES) {
      this.fileList = this.fileList.filter((f) => f.name !== file.name);
    }
    if (fileType === SelectFileTypeEnum.FILES) {
      // Reduce the counters
      this.fileCount--;
    }
    this.table.dataSource = this.fileList;
    this.table.renderRows();
  }

  public setSelectedConnection(selectedConnectionId: string): void {
    const connection: ConnectionModel =
      this.connectionList.find(
        (connectionObject) =>
          connectionObject.connectionId === selectedConnectionId
      ) ?? this.defaultConnectionValue;
    this.selectedConnection = connection;
    if (Object.keys(this.requestHeaders).length > 0)
      this.store.dispatch(
        new SetSelectedConnection(connection, this.requestHeaders)
      );
    else this.store.dispatch(new SetSelectedConnection(connection));
  }

  public showConfirmOnChangeIfApplicable(selectedConnectionId: string): void {
    // If user has no file selections to loose let it go.
    if (this.fileList.length === 0) {
      this.setSelectedConnection(selectedConnectionId);
      return;
    }

    // There are some files so best we confirm with user
    this.dialog
      .open(MprConfirmComponent, {
        data: {
          confirmTitle: 'Change Data Source',
          confirmMessage:
            'Are you sure you want to change the data source? Selecting a different data source will clear the currently selected files as upload from only a single source is supported.',
          confirmData: selectedConnectionId,
        },
      })
      .afterClosed()
      .subscribe((connectionId: string): void => {
        // If user clicked cancel revert to earlier value.
        if (!connectionId) {
          this.defaultValue = this.selectedConnection.connectionName;
          return;
        } else {
          // User confirmed lets overwrite
          this.fileList = [];
          this.fileCount = 0;
        }
        this.setSelectedConnection(connectionId);
      });
  }

  public showFilePicker(fileType: SelectFileTypeEnum): void {
    this.fileType = fileType;
    switch (this.selectedConnection.dataStore) {
      case ConnectionDataStore.BOX:
        this.showPickerDialog(BoxContentPickerComponent, this.fileType);
        break;
      case ConnectionDataStore.S3:
      case ConnectionDataStore.DL_S3:
        this.showPickerDialog(S3ContentPickerComponent, this.fileType);
        break;
      default:
        this.fileUpload?.nativeElement.click();
        break;
    }
  }

  public showFolderPickerDialog(): void {
    this.dialog
      .open(S3ContentPickerComponent, {
        data: {
          pickerType: 'folder',
          selectedProject: this.selectedProject,
          showDefaultProjectBucket: true,
        },
      })
      .afterClosed()
      .subscribe((selectedFolder) => {
        this.destinationPath = '';
        if (selectedFolder) {
          this.destinationPath = selectedFolder
            .split('/')
            .slice(1)
            .join('/')
            .slice(0, -1);
          this.displayDestinationPath = this.destinationPath;
        } else {
          this.destinationPath = this.displayDestinationPath;
        }
      });
  }

  public showReplaceFilesWarning(fileType: SelectFileTypeEnum): void {
    this.dialog
      .open(MprConfirmComponent, {
        data: {
          confirmMessage:
            'Adding more files will completely overwrite the currently selected files. Are you sure you want to continue?',
          confirmData: 'clearFileSelection',
        },
      })
      .afterClosed()
      .subscribe((clearFiles: string): void => {
        if (clearFiles === 'clearFileSelection') {
          if (fileType === SelectFileTypeEnum.FILES) {
            this.fileCount = 0;
            this.fileList = [];
          }
          this.showFilePicker(fileType);
        }
      });
  }

  public showReplaceWarningOrFilePciker(fileType: SelectFileTypeEnum): void {
    switch (fileType) {
      case SelectFileTypeEnum.FILES:
        if (this.fileCount > 0) {
          this.showReplaceFilesWarning(fileType);
        } else {
          this.showFilePicker(fileType);
        }
        break;
    }
  }

  public startFileUpload(requestHeaders?: any): void {
    this.setSelectedFileToState();
    const connectionId = this.store.selectSnapshot(
      ConnectionState.getSelectedConnectionId
    );
    const jobOperationType = JobOperationTypeEnums.JOB_OPERATION_TYPE_STAGING;
    const useComponent: ComponentType<unknown> =
      connectionId === ConnectionDataStore.LOCAL
        ? OnPremiseUploadComponent
        : CloudTransferComponent;
    this.dialog.open(useComponent, {
      ...this.dialogSize,
      disableClose: true,
      data: { requestHeaders, jobOperationType },
    });
  }

  private getSupportedFileAndHandleErrorDisplay(
    files: MprFile[],
    fileType: string
  ): MprFile[] {
    let unsupportedFileCount = 0;
    const userSelectedUnsupportedTypes: string[] = [];
    const supportedFiles = files.filter((file) => {
      const fileExt = file.name.split('.').pop();
      if (
        fileExt &&
        this.unsupportedFileTypes.includes(fileExt.toUpperCase())
      ) {
        unsupportedFileCount++;
        const fileExtLowercase = fileExt.toUpperCase();
        if (!userSelectedUnsupportedTypes.includes(fileExtLowercase))
          userSelectedUnsupportedTypes.push(fileExtLowercase);
        return false;
      }
      // Excluding unsupported.
      if (fileType === SelectFileTypeEnum.FILES) {
        this.fileCount++;
      }

      return true;
    });

    // Only if user has selected unsupported files: below is just a "if" shortened
    if (unsupportedFileCount > 0)
      this.handleErrorDisplay(
        unsupportedFileCount,
        userSelectedUnsupportedTypes
      );
    return supportedFiles;
  }
  private handleErrorDisplay(
    unsupportedFileCount: number,
    userSelectedUnsupportedTypes: string[]
  ): void {
    const extFileText = `${userSelectedUnsupportedTypes.join(',')}`;
    this.alertMsgService.error({
      title: `The file format [${extFileText}] you are trying to upload is not supported.`,
      body: `${unsupportedFileCount} of your file(s) are in [${extFileText}] file format, so they cannot be uploaded.`,
      autoDismiss: false,
    });
  }

  private handleFileSelections(files: MprFile[], fileType: string): void {
    // Remove alerts if any
    this.alertMsgService.dismmissAll();
    if (
      Object.keys(this.selectedConnection).length !== 0 &&
      files &&
      files.length
    ) {
      if (fileType === SelectFileTypeEnum.FILES) {
        // Reduce the counters
        this.fileCount = 0;
        this.fileList = this.getSupportedFileAndHandleErrorDisplay(
          files,
          fileType
        );
      }
    }
  }
  private loadPremiseConnection(): void {
    this.connectionList.unshift(constants.DL_S3_CONNECTION);
    this.connectionList.unshift(constants.LOCAL_DRIVE_SOURCE);
  }

  private setSelectedFileToState(): void {
    // Put to state
    this.store.dispatch(
      new SelectFileForUploads(
        this.fileList,
        [],
        '',
        '',
        [],
        JobOperationTypeEnums.JOB_OPERATION_TYPE_UPLOAD_TO_STAGING,
        this.destinationPath
      )
    );
  }

  private showPickerDialog(openerComponent: any, fileType: string): void {
    this.dialog
      .open(openerComponent, {
        data: {
          pickerType: 'file',
        },
      })
      .afterClosed()
      .subscribe((selectedItems) => {
        if (selectedItems)
          this.handleFileSelections(selectedItems, this.fileType);
      });
  }
}
