export interface UserAWSStatus {
  isNewVersionAvailable?: boolean;
  operatingSystem?: string;
  ram?: string;
  registrationCode?: string;
  url?: string;
  userName?: string;
  vCPU?: string;
  workspaceStatus?: string;
}
