import { TemplateRef } from '@angular/core';

export interface MprConfirmInputModal {
  cancelButtonText: string;
  confirmAction?: string;
  confirmButtonText: string;
  confirmData?: any; // Could be any thing not fixed
  confirmMessage: string;
  confirmTitle: string;
  innerContent?: TemplateRef<any>;
}
