import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { MprConfirmComponent, ScheduleModel } from '@shared';
import { GenericStatus, UserProjectRoleEnum } from '@core/enums';
import { AlertMessageService } from '@core/services';
import { SkipMainContentService, ScheduleService } from '@shared/services';
import {
  DeleteSchedule,
  EnableDisableSchedule,
  GetAllSchedule,
} from 'app/state/uploads/schedule/schedule.action';
import { ScheduleState } from 'app/state/uploads/schedule/schedule.state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EditScheduleDataModel } from '@shared/interfaces';
import { LoggedinUserState } from 'app/state';
import { UserProjectState } from 'app/state/user-projects/user-project.state';
import { Observable } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-recurring-schedule',
  templateUrl: './recurring-schedule.component.html',
  styleUrls: ['./recurring-schedule.component.scss'],
})
export class RecurringScheduleComponent implements OnInit, AfterViewChecked {
  @ViewChild('skipper') public skipper!: MatButton;
  @Select(UserProjectState.getSelectedProjectRole)
  public userProjectRole$?: Observable<string>;
  @ViewChild('paginator') private paginator!: MatPaginator;
  public currentPage!: number;
  public dataSource!: MatTableDataSource<ScheduleModel>;
  public displayedColumns : string[] = [];
  public pageEvent: PageEvent | undefined;
  public pageIndex = 0;
  public pageSizeOptions = [10, 25, 100];
  public recurringSchedule: ScheduleModel[] = [];
  public returnPath: string;
  public scheduleStatusEnum = GenericStatus;
  public searchNotFound = false;
  public searchText!: string;
  public userSelectedProjectRole = '';
  private scheduleType = ''

  constructor(
    private alertService: AlertMessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private scheduleService: ScheduleService,
    private store: Store,
    private skipMainContentService: SkipMainContentService,
    private cdr: ChangeDetectorRef,
  ) {
    this.returnPath = route.snapshot.data['back'];
    this.dataSource = new MatTableDataSource(this.recurringSchedule);
    this.scheduleType = this.router.url.includes('staging')? 'staging': '';

  }

  public applyFilter(): void {
    this.searchNotFound = false;
    this.dataSource.filter = JSON.stringify([{ value: this.searchText }]);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    if (!this.recurringSchedule.length) {
      this.searchNotFound = true;
    }
  }

  public createSchedule(): void {
    if (this.scheduleType === 'staging') {
      this.router.navigate(['staging/create-schedule']);
    } else {
      this.router.navigate(['/uploads/create-schedule']);
    }
  }

  public displayStagingTarget(fullStagingPath: string): string{
    if(fullStagingPath){
      const pathArray = fullStagingPath.split('/');
      return pathArray[pathArray.length-1];
    }
    else return '';
  }

  public enableDisableSchedule(
    scheduleId: string,
    scheduleChecked: boolean,
  ): void {
    const status = scheduleChecked
      ? this.scheduleStatusEnum.ACTIVE
      : this.scheduleStatusEnum.INACTIVE;
    this.store
      .dispatch(new EnableDisableSchedule(scheduleId, status))
      .subscribe(() => {
        const scheduleStatus =
          status === this.scheduleStatusEnum.ACTIVE ? 'Enabled' : 'Disabled';
        this.alertService.success({
          body: `Schedule ${scheduleStatus} Successfully`,
        });
        this.store.dispatch(new GetAllSchedule(this.scheduleType));
      });
  }

  public handlePageEvent(event: PageEvent): void {
    this.pageEvent = event;
    this.pageIndex = event.pageIndex;
  }

  public isModificationApplicable(schedule: ScheduleModel): boolean {
    const loggedInUserId = this.store.selectSnapshot(
      LoggedinUserState.getLoggedInUserId,
    );
    return (
      schedule.userId === loggedInUserId ||
      this.userSelectedProjectRole === UserProjectRoleEnum.ADMIN
    );
  }

  public loadPagination(): void {
    if (this.currentPage !== this.pageIndex) {
      const button = document.getElementsByClassName(
        'mat-paginator-navigation-previous',
      );
      if (this.recurringSchedule.length) {
        const elem = document.getElementById('currentPage');
        if (elem) elem.parentNode?.removeChild(elem);
        if (button[0]) {
          button[0].insertAdjacentHTML(
            'afterend',
            `<span id="currentPage" class="current-page">Page ${
              this.pageIndex + 1
            }</span>`,
          );
          this.currentPage = this.pageIndex;
        }
      }
    }
  }

  public navigateToDestinationFolder(destinationFolder: string): void {
    this.router.navigate([
      '/filemanager',
      btoa(destinationFolder.replace('s3://', '')),
    ]);
  }

  ngAfterViewChecked(): void {
    this.loadPagination();
  }
 

  ngOnInit(): void {
    this.displayedColumns = this.scheduleType === 'staging' ?
     ['createdBy', 'scheduleName', 'lastRunAt', 'frequency', 'destinatonFolder', 'edit','delete',] : ['createdBy', 'scheduleName', 'lastRunAt', 'frequency', 'edit', 'delete', 'enable'];
    this.store.dispatch(new GetAllSchedule(this.scheduleType));
    this.store
      .select(ScheduleState.getAllSchedules)
      .subscribe((schedules: ScheduleModel[]) => {
        this.recurringSchedule = schedules;
        this.dataSource = new MatTableDataSource(this.recurringSchedule);
        this.cdr.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (
          data: ScheduleModel,
          filtersJson: string,
        ): boolean => {
          const filters = JSON.parse(filtersJson);
          const connectionName: string = data['scheduleName'] || '';
          this.recurringSchedule = schedules.filter((each) =>
            filters.find((val: { value: string }) =>
              each.scheduleName.startsWith(val.value),
            ),
          );
          this.pageIndex = 0;
          this.currentPage = -1;
          this.loadPagination();
          return filters.some((val: { value: string }) =>
            connectionName.startsWith(val.value),
          );
        };
      });
    this.userProjectRole$?.subscribe((role) => {
      this.userSelectedProjectRole = role;
    });
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.focus();
    });
  }

  
  public openEditModal(schedule: EditScheduleDataModel): void {
    this.router.navigate(this.scheduleType === 'staging' ? ['staging/edit-schedule',schedule.scheduleId] : ['/uploads/edit-schedule',schedule.scheduleId])

  }

  public showDeleteScheduleDialog(schedule: ScheduleModel): void {
    const dialogRef = this.dialog.open(MprConfirmComponent, {
      data: {
        confirmTitle: 'Delete Schedule',
        confirmMessage: 'Are you sure you want to delete this schedule?<br><br>',
        confirmAction: schedule.scheduleName,
        confirmData: schedule.scheduleId,
      },
    });
    // NOTE: You do not need to unsubscribe from a dialogRef.afterClosed
    dialogRef.afterClosed().subscribe((connectionId?: string): void => {
      if (!connectionId) return;
      this.store
        .dispatch(new DeleteSchedule(schedule.scheduleId))
        .subscribe(() => {
          this.store.dispatch(new GetAllSchedule(this.scheduleType));
          this.alertService.success({ body: 'Schedule Deleted Successfully' });
        });
    });
  }
}
