import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProject } from '@theme/interfaces';
import { Select, Store } from '@ngxs/store';
import { customSort } from '@shared/utils';
import {
  Observable,
  Subscription,
  catchError,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { ProjectState, UserProjectState, DomainListState } from 'app/state';
import {
  AddUserToProject,
  ResetUserListState,
} from 'app/state/project-user/project-user.actions';
import ProjectCountModel from 'app/state/project-user/project-user-state-model';
import { ProjectUserState } from 'app/state/project-user/project-user.state';
import { AlertMessageService } from '@core/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { UserList } from 'app/state/project-user/user-list-model';
import { NgForm } from '@angular/forms';
import { ConsoleStatsState } from 'app/state/console-stats/console-stats.state';
import { GetProjectCount } from 'app/state/console-stats/console-stats.actions';
import { GetAllowedDomains } from 'app/state/domain-list/domain-list.actions';
import { UserProjectRoleEnum } from '@core';
import { ProjectDetails } from 'app/state/console-stats/console-stats-state-model';
import { MprConfirmComponent } from '@shared';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss'],
})
export class AddUsersComponent implements OnInit, OnDestroy {
  @Select(ProjectUserState.getUsersListDetails)
  public UsersDetailsList$?: Observable<UserList[]>;
  @Select(DomainListState.returnAllowedDomains)
  public allowedDomains$?: Observable<string[]>;
  @Select(ConsoleStatsState.getAllProjects)
  public allprojects$?: Observable<UserProject[]>;
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  @Select(ProjectUserState.getLoaderStatus)
  public showSpinner$?: Observable<boolean>;
  @ViewChild('userForm') public userFrm?: NgForm;
  @Select(UserProjectState.getSelectedProjectUserRestrictedDataUseGroups)
  private getSelectedProjectUserRestrictedDataUseGroups$!: Observable<string[]>;
  @Select(ProjectState.getProjectPowerUsers)
  private projectPoweruser$!: Observable<boolean>;
  public allowedExternalDomains: Array<string> = [];
  public config: ProjectCountModel;
  public disableInputFields = true;
  public fromUserList = false;
  public isAddUserEnabled = true;
  public isPlatformAdmin = false;
  public isPowerUserApplicable = false;
  public panelOpenState = false;
  public preselectedProjectId = '';
  public restrictedGroups: string[] = [];
  public returnPath: string;
  public selectProjectDetails: any = {};
  public selectedProject!: UserProject;
  public selectedProjectId = '';
  public showSpinner = true;
  public sortedValue: UserProject[] | undefined;
  private allSubscription = new Subscription();
  
  constructor(
    private route: ActivatedRoute,
    public store: Store,
    private router: Router,
    public dialog: MatDialog,
    private alertMsgService: AlertMessageService
  ) {
    this.returnPath = route.snapshot.data['back'];
    if (router.url.includes('platformAdmin/')) {
      this.isPlatformAdmin = true;

      if (this.router.url.includes('/user/add')) {
        this.fromUserList = true;
        this.selectedProjectId = this.route.snapshot.params['projectId'];
        this.returnPath = `/platformAdmin/users/list/${this.selectedProjectId}`;
      }
    }
    this.config = {
      count: 0,
      loader: false,
      projectUsersList: [
        {
          ec2Status: '',
          emailId: '',
          firstName: '',
          isPowerUser: false,
          lastName: '',
          machineSize: '',
          projectId: '',
          projectRestrictedDataUseGroups: [],
          roleName: '',
          userId: '',
          userStatus: '',
        },
      ],
    };
  }

  public actionChangeProject(
    selectedProject: string,
    showConfirmModal: boolean,
    data?: any
  ): void {
    if (!data && showConfirmModal) {
      this.selectedProjectId = this.preselectedProjectId;
      return;
    }

    this.preselectedProjectId = selectedProject;
    this.showSpinner = true;
    this.allprojects$?.subscribe((projects: any) => {
      this.selectProjectDetails = projects.find(
        (projectDetails: any) => projectDetails.projectId === selectedProject
      );
      this.store.dispatch(
        new GetAllowedDomains(
          this.selectProjectDetails.projectId,
          UserProjectRoleEnum.PLATFORM_ADMIN
        )
      );
    });
    this.formattedUserFormValue();
  }

  public addAddress(): void {
    this.config['projectUsersList'].push({
      ec2Status: '',
      emailId: '',
      firstName: '',
      isPowerUser: false,
      lastName: '',
      projectId: this.selectedProjectId,
      machineSize: '',
      projectRestrictedDataUseGroups: this.restrictedGroups,
      roleName: '',
      userId: '',
      userStatus: '',
    });
  }

  public changeProject(selectedProject: string): void {
    const canChangeProject = this.formIsValid(true);
    if (canChangeProject) {
      this.preselectedProjectId = selectedProject;
      this.actionChangeProject(selectedProject, false);
    } else {
      const dialogRef = this.dialog.open(MprConfirmComponent, {
        data: {
          confirmTitle: 'Change Project',
          confirmMessage: `You will lose the details you entered. Are you sure you want to continue? `,
          confirmData: {
            selectedProject,
          },
        },
      });
      dialogRef.afterClosed().subscribe((data: any): void => {
        this.actionChangeProject(selectedProject, true, data);
      });
    }
  }

  public formIsValid(isChangeProject?: boolean): boolean {
    let isValid = true;
    let isChangeProjectFlag = true;
    const requiredFields = ['emailId', 'firstName', 'lastName', 'roleName'];

    this.config['projectUsersList'].forEach((user) => {
      requiredFields.forEach((field) => {
        if ((user as any)[field].trim() === '') {
          isValid = false;
        } else {
          if (isChangeProject) {
            isChangeProjectFlag = false;
          }
        }
      });
    });

    if (isChangeProject) {
      return isChangeProjectFlag;
    }

    return isValid;
  }

  public formattedUserFormValue(): void {
    this.config['projectUsersList'] = [];
    if (this.isPlatformAdmin) {
      this.selectedProjectId = this.selectProjectDetails.projectId || '';
      this.preselectedProjectId = this.selectedProjectId;
      this.restrictedGroups =
        this.selectProjectDetails.projectRestrictedDataUseGroups;
      this.isPowerUserApplicable = this.selectProjectDetails.hasPowerUsers;
    } else {
      this.getSelectedProjectUserRestrictedDataUseGroups$.subscribe(
        (restrictedGroup: any) => {
          this.restrictedGroups = restrictedGroup;
        }
      );
      this.projectPoweruser$?.subscribe((poweruser: boolean) => {
        this.isPowerUserApplicable = poweruser;
      });
    }
    this.config['projectUsersList'].push({
      ec2Status: '',
      emailId: '',
      firstName: '',
      isPowerUser: false,
      lastName: '',
      projectId: this.selectedProjectId,
      machineSize: '',
      projectRestrictedDataUseGroups: this.restrictedGroups,
      roleName: '',
      userId: '',
      userStatus: '',
    });
    this.showSpinner = false;
  }

  public navigateBack(): void {
    this.router.navigate([this.returnPath]);
  }

  ngOnDestroy(): void {
    this.allSubscription.unsubscribe();
    this.store.dispatch(new ResetUserListState());
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    if (this.isPlatformAdmin) {
      this.store.dispatch(new GetProjectCount('True'));
      this.allprojects$?.subscribe((projects: any) => {
        this.sortedValue = customSort(projects) 
      });
    }
    
    this.project$?.subscribe((project: UserProject) => {
      this.selectedProject = project;
      this.selectedProjectId = this.selectedProjectId
        ? atob(this.selectedProjectId)
        : this.selectedProject.projectId;
      if (this.isPlatformAdmin) {
        this.allprojects$?.subscribe((projects: any) => {
          if (projects && projects.length > 0) {
            if (this.selectedProjectId === '' && !this.fromUserList) {
              this.store.dispatch(
                new GetAllowedDomains(
                  projects[0].projectId,
                  UserProjectRoleEnum.PLATFORM_ADMIN
                )
              );
              this.formattedUserFormValue();
            } else {
              this.selectProjectDetails = this.selectedProject = projects.find(
                (projectDetails: any) =>
                  projectDetails.projectId === this.selectedProjectId
              );
              this.store.dispatch(
                new GetAllowedDomains(
                  this.selectedProjectId,
                  UserProjectRoleEnum.PLATFORM_ADMIN
                )
              );
              this.formattedUserFormValue();
            }
          }
        });
      } else {
        this.store.dispatch(
          new GetAllowedDomains(
            this.selectedProjectId,
            UserProjectRoleEnum.ADMIN
          )
        );
        this.formattedUserFormValue();
      }
    });
    this.allowedDomains$?.subscribe((externalDomains: Array<string>) => {
      this.allowedExternalDomains = externalDomains;
    });
  }

  public onSubmit(): void {
    this.store.dispatch(new ResetUserListState());
    const dialogRef = this.openAddUserConfirmationModal();
    dialogRef.afterClosed().subscribe((data: any): void => {
      this.showSpinner = true;
      if (!data) {
        this.showSpinner = false;
        return;
      }

      if (!this.formIsValid()) {
        this.alertMsgService.error({
          body: 'Please enter all required fields and try again.',
        });
        return;
      }

      if (this.isPlatformAdmin) {
        this.store.dispatch(
          new AddUserToProject(
            this.config['projectUsersList'],
            UserProjectRoleEnum.PLATFORM_ADMIN
          )
        );
      } else {
        this.store.dispatch(
          new AddUserToProject(this.config['projectUsersList'])
        );
      }
      if (this.UsersDetailsList$) {
        this.allSubscription = this.UsersDetailsList$.pipe(
          catchError((err) => {
            this.alertMsgService.error({
              body: err.error.message,
            });
            this.showSpinner = false;
            return throwError(() => new Error(''));
          })
        ).subscribe((response: Array<UserList>) => {
          if (response && response.length > 0) {
            if (
              response[0].isSuccess === true ||
              response[0].isSuccess === false
            ) {
              this.showSpinner = false;
              this.dialog.open(ConfirmationComponent, {
                disableClose: true,
                data: {
                  response,
                  fromUserList: this.fromUserList,
                  projectId: this.selectedProjectId,
                },
              });
            }
          }
        });
      }
    });
  }

  public openAddUserConfirmationModal(): MatDialogRef<object> {
    const userList = this.config['projectUsersList'].map((user) => ({
      roleName: user.roleName,
      userName: `${user.firstName} ${user.lastName}`,
      isSuccess: true,
    }));

    return this.dialog.open(ConfirmationComponent, {
      data: {
        isPreconfirm: true,
        confirmTitle: `The following users will be added to project ${this.selectedProjectId}`,
        confirmMessage: ` Are you sure you want to continue?`,
        response: userList,
        fromUserList: this.fromUserList,
        projectId: this.selectedProjectId,
      },
    });
  }

  public removeUser(index: number): void {
    if (this.config['projectUsersList'].length > 1) {
      this.config['projectUsersList'].splice(index, 1);
    }

    // Below code is to remove any duplicate email errors in case the user decided to remove the one
    // that other fields are duplicate of
    const controlStr = 'userDetail' + index + 'emailId';
    const group = this.userFrm?.form.controls['group'];
    const fields = Object.keys(group?.value) ?? [];

    fields.forEach((field) => {
      if (field.toLowerCase().includes('emailid')) {
        const otherEmailControl =
          this.userFrm?.form.controls['group'].get(field);
        if (otherEmailControl?.getError('duplicateEmail') === controlStr)
          otherEmailControl.setErrors(null);
      }
    });
  }

}
