/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class GetRDPDetails {
  public static type = '[WorkSpacesInstance] Get  RDP Details ';
}

export class ResetRDPDetails {
  public static type = '[WorkSpacesInstance] Reset RDP details';
}
