import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexPlotOptions,
    ApexResponsive,
    ApexXAxis,
    ApexLegend,
    ApexFill,
    ApexYAxis,
    ApexTooltip
} from 'ng-apexcharts';

export interface ChartOptions {
    chart: ApexChart | any;
    colors: Array<string> | any;
    dataLabels: ApexDataLabels | any;
    fill: ApexFill | any;
    legend: ApexLegend | any;
    plotOptions: ApexPlotOptions | any;
    responsive: ApexResponsive[] | any;
    series: ApexAxisChartSeries | any;
    tooltip: ApexTooltip | any;
    xaxis: ApexXAxis | any;
    yaxis: ApexYAxis | any;
}