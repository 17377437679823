<div>
  <div *ngIf="selectedUserProjects.length === 0 || showSpinner; else showManageUserForm">
    <div class="mpr-noresult-container">
      <div class="mpr-noresutl-error">
        <span>
          <mat-spinner diameter="45" color="accent"></mat-spinner>
        </span>
      </div>
    </div>
  </div>
  <ng-template #showManageUserForm>
    <div
      class="mpr-manage-users"
      *ngIf="selectedUserDetails && selectedUserDetails.length > 0"
    >
      <div class="mpr-manage-users-header">
        <span class="mpr-selected-User"
          >List of Projects For {{ selectedUserDetails[0].userName }}</span
        >
      </div>
      <div class="mpr-tabs-container">
        <table
          *ngIf="selectedUserProjects.length > 0"
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8 staging-upload-history-table mpr-tables one-time-history-table"
        >
          <!-- Project Name Column -->
          <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef>Project Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.projectId }}
            </td>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="roleName">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let element">{{ element.roleName | replaceRoleName }}</td>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let element">
              <button
                class="mpr-btn-transparency mpr-cursor-pointer"
                (click)="editUser(selectedUserDetails[0], element)"
              >
                <img
                  src="assets/images/Edit.svg"
                  alt="Edit User"
                  class="img-margin"
                  matTooltip="Edit User"
                />
              </button>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element">
              <button
                class="mpr-btn-transparency mpr-cursor-pointer"
                (click)="
                  showDeleteConfirmation(selectedUserDetails[0], element)
                "
              >
                <img
                  src="assets/images/delete.svg"
                  alt="Delete User"
                  class="img-margin"
                  matTooltip="Delete User"
                />
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </ng-template>
</div>
