import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, catchError, tap, throwError } from 'rxjs';
import ManageProjectModel, {
  MessageDetails,
  projectDetails,
} from './manage-project-state-model';
import {
  CreateAProject,
  EditAProject,
  GetProjectDeatils,
  ResetProjectData,
  SetLoaderState,
} from './manage-project.actions';
import { UserProjectService } from '@theme/services';

const stateDefaults: ManageProjectModel = {
  res: {
    credit: 0,
    dwAccess: false,
    emailDistributionList: [],
    hasPowerUsers: false,
    projectName: '',
    projectNickname: '',
    projectNumber: '',
    projectRestrictedDataUseGroups: [],
    projectSize: '',
  },
  message: {
    projectId: '',
  },
  loader: false,
};
@State<ManageProjectModel>({
  defaults: stateDefaults,
  name: 'ManageProject',
})
@Injectable()
export class ManageProjectState {
  constructor(
    private store: Store,
    private userProjectService: UserProjectService
  ) {}

  @Action(CreateAProject)
  public CreateAProject(
    { patchState }: StateContext<ManageProjectModel>,
    { projectFormDeatils, roleName }: CreateAProject
  ): Observable<ManageProjectModel> {
    let loader = true;
    patchState({ loader });
    return this.userProjectService
      .createAProject(projectFormDeatils, roleName)
      .pipe(
        tap((response: ManageProjectModel) => {
          const message = response.message;
          loader = false;
          patchState({ message, loader });
        })
      );
  }

  @Action(EditAProject)
  public EditAProject(
    { patchState }: StateContext<ManageProjectModel>,
    { projectFormDeatils, roleName, projectId }: EditAProject
  ): Observable<ManageProjectModel> {
    let loader = true;
    patchState({ loader });
    return this.userProjectService
      .editAProject(projectFormDeatils, roleName, projectId)
      .pipe(
        tap((response: ManageProjectModel) => {
          const message = response.message;
          loader = false;
          patchState({ message, loader });
        })
      );
  }

  @Action(GetProjectDeatils)
  public GetProjectDeatils(
    { patchState }: StateContext<ManageProjectModel>,
    { requestHeaders }: GetProjectDeatils
  ): Observable<projectDetails> {
    let loader = true;
    patchState({ loader });
    return this.userProjectService.getProjectDetails(requestHeaders).pipe(
      tap((res: projectDetails) => {
        loader = false;
        patchState({ res, loader });
      })
    );
  }

  @Action(ResetProjectData)
  public ResetProjectData({
    patchState,
  }: StateContext<ManageProjectModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(SetLoaderState)
  public SetLoaderState(
    { patchState }: StateContext<ManageProjectModel>,
    { loader }: SetLoaderState
  ): void {
    patchState({ loader });
  }

  @Selector()
  public static getLoaderStatus(state: ManageProjectModel): boolean {
    return state.loader;
  }

  @Selector()
  public static getProjectCreationStatus(
    state: ManageProjectModel
  ): MessageDetails | string {
    return state.message;
  }

  @Selector()
  public static getProjectDetails(
    state: ManageProjectModel
  ): projectDetails | undefined {
    return state.res;
  }
}
