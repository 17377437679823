<a class="matero-branding" href="/">
  <span data-cy="app-side-menu-branding-logo" class="m-8"
    ><img
      src="./assets/images/mathematica.png"
      class="matero-branding-logo-expanded"
      alt="Mathematica Logo"
  /></span>
  <span
    ><mpr-app-name dataTestId="app-side-menu-branding-text"></mpr-app-name
  ></span>
  <span class="sr-only">{{ brandName }}</span>
</a>
