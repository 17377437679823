import { JobOperationTypeEnums } from '@core/enums';
import { URLListModel } from 'app/uploads/interfaces';
import { FileAttrsModel } from './file-attrs-model';

export interface UploadsFilesFormModel {
  connectionId: string;
  datasetName?: string;
  existingFileNames?: string[]
  fileNameSizeMapping: FileAttrsModel[];
  jobId?: string;
  jobOperationType?: JobOperationTypeEnums;
  noOfFiles: number;
  noOfSupportingFiles: number;
  noOfUrls: number;
  stagingTargetPath?: string;
  supportingFileNameSizeMapping: FileAttrsModel[];
  urls: URLListModel[];
}
