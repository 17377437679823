import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MprConfirmInputModal } from '@shared';

@Component({
  selector: 'mpr-confirm',
  templateUrl: './mpr-confirm.component.html',
  styleUrls: ['./mpr-confirm.component.scss'],
})
export class MprConfirmComponent {
  constructor(
    private dialogRef: MatDialogRef<MprConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MprConfirmInputModal
  ) {}

  public confirm(): void {
    this.dialogRef.close(this.data.confirmData);
  }
}
