import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ProjectSelectGuard } from '@core/guards';
import { AdminLayoutComponent } from '@theme/admin-layout/admin-layout.component';
import { CreateScheduleComponent } from './create-schedule/create-schedule.component';
import { EditScheduleComponent } from '@shared/components';
import { JobDetailsComponent } from './job-details/job-details.component';
import { OneTimeUploadsComponent } from './one-time-uploads/one-time-uploads.component';
import { RecurringScheduleComponent } from './../shared/components/recurring-schedule/recurring-schedule.component';
import { UploadOptionComponent } from './upload-option/upload-option.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ProjectSelectGuard],
    canActivateChild: [AuthGuard, ProjectSelectGuard],
    children: [
      {
        path: '',
        component: UploadOptionComponent,
        data: {
          back: '/dashboard',
          backTitle: 'Dashboard',
          title: 'Publish To Catalog',
        },
      },
      {
        path: 'onetime',
        component: OneTimeUploadsComponent,
        data: {
          back: '/uploads',
          backTitle: 'Publish To Catalog',
          title: 'One Time Upload',
        },
      },
      {
        path: 'upload-option',
        component: UploadOptionComponent,
        data: {
          back: '/dashboard',
          backTitle: 'Dashboard',
          title: 'UPLOAD FILE(S) & HISTORY',
        },
      },
      {
        path: 'schedule',
        component: RecurringScheduleComponent,
        data: {
          back: '/uploads',
          backTitle: 'Uploads File(s) & History',
          title: 'Manage Recurring Schedules',
        },
      },
      {
        path: 'create-schedule',
        component: CreateScheduleComponent,
        data: {
          back: '/uploads/schedule',
          backTitle: 'Schedule',
          title: 'Create New Schedule',
        },
      },
      {
        path: 'job-details/:uploadType/:jobId',
        component: JobDetailsComponent,
        data: {
          back: '/uploads',
          backTitle: 'Uploads File(s) & History',
          title: 'Job Details',
        },
      },
      {
        path: 'edit-schedule/:scheduleId',
        component: EditScheduleComponent,
        data: {
          back: '/uploads/schedule',
          backTitle: 'Schedule',
          title: 'Edit Schedule',
        },
      },
      { path: '', redirectTo: 'upload-option', pathMatch: 'full' },
    ],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        // For upload via Job
        path: 'onetime/job/:id',
        component: OneTimeUploadsComponent,
        data: {
          back: '/uploads',
          backTitle: 'Uploads File(s) & History',
          title: 'Add Files',
        },
      },
      {
        // For upload via Job
        path: 'onetime/dataset/:datasetDetails',
        component: OneTimeUploadsComponent,
        data: {
          back: '/uploads',
          backTitle: 'Uploads File(s) & History',
          title: 'Add Files',
        },
      },
      {
        path: 'job-details/:uploadType/dataset/:jobId/:projectDetails',
        component: JobDetailsComponent,
        data: {
          back: '/uploads',
          backTitle: 'Uploads File(s) & History',
          title: 'Job Details',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UploadsRoutingModule {}
