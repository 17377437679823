<div
  class="mpr-dashboard-loader"
  *ngIf="projectData$ | async as projectData; else showSpinner"
>
  <div
    class="container-with-border"
    *ngIf="projectData.projectId; else showSpinner"
  >
    <div>
      <div>
        <div class="d-flex mpr-justify-content-space-between">
          <div class="page-header">
            {{ projectData.projectId }}
          </div>
          <div>
            <button
              aria-label="Edit"
              class="mpr-edit-button"
              mat-button
              (click)="navigateToRoute()"
              #skipper
            >
              <img
                src="assets/images/PencilIcon.svg"
                alt=""
                class="mpr-edit-icon"
              />
              <span class="mpr-export-text">Edit</span>
            </button>
          </div>
        </div>
        <div
          class="d-flex"
          *ngIf="projectData['dwAccess'] || projectData['hasPowerUsers']"
        >
          <div
            class="mpr-dw-tag p-4 d-flex mpr-align-items-center m-r-8"
            *ngIf="projectData['dwAccess']"
          >
            <img
              src="assets/images/Data_Warehouse_Blue.svg"
              class="mpr-dw-tag-icon m-l-4"
              alt="Datawarehouse Enabled"
            />
            <div class="m-l-4 mpr-dw-tag-text">Data Warehouse Access</div>
          </div>
          <div
            class="mpr-hl-tag p-4 d-flex mpr-align-items-center"
            *ngIf="projectData['hasPowerUsers']"
          >
            <img
              src="assets/images/Heavy_Lifting_Green.svg"
              class="mpr-tile-icon mpr-hl-tag-icon m-l-8"
              alt="Heavylifting Enabled"
            />
            <span class="m-l-4 mpr-hl-tag-text">Heavy Lifting</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mpr-project-title m-t-16">
      {{ projectData.projectName }}
    </div>
    <div class="mpr-medium-bold m-t-24 d-flex" *ngIf="projectData['dwAccess']">
      <div class="m-b-8">
        Data Warehouse Size: {{ projectData['projectSize'] }}
      </div>
      <div class="m-l-16">
        Onboarded On: {{ projectData['createdDate'] | utcDate: 'MM/dd/yyyy' }}
      </div>
    </div>
    <div class="mpr-divider"></div>
    <div class="mpr-no-padding">
      <mat-expansion-panel
        expanded="true"
        *ngIf="(projectData['userCountsByRole'] | json) !== '{}'"
      >
        <mat-expansion-panel-header class="mpr-mat-header">
          <mat-panel-title>
            <div class="d-flex mpr-flex-align-items-center mpr-flex-dir-row">
              <div class="mpr-collapsible-header">Project Users and Roles</div>
            </div>
            <mat-panel-description>
              &nbsp;
              <button
                (click)="navigateToRoute('view-all')"
                class="mpr-btn-transparency"
              >
                <span class="mpr-view-all">View All</span>
              </button>
            </mat-panel-description>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex m-t-16">
          <mat-chip-list>
            <mat-chip
              class="mpr-role-tags"
              *ngFor="let role of projectData['userCountsByRole'] | keyvalue"
            >
              {{ role.key + '' | replaceRoleName }} : {{ role.value }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header class="mpr-mat-header">
          <mat-panel-title>
            <div class="d-flex mpr-flex-align-items-center mpr-flex-dir-row">
              <div class="mpr-collapsible-header">Email Notification List</div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex m-t-16">
          <mat-chip-list>
            <mat-chip
              class="mpr-other-tags"
              *ngFor="let email of projectData['emailDistributionList']"
            >
              {{ email }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel expanded="true" class="m-t-20">
        <mat-expansion-panel-header class="mpr-mat-header">
          <mat-panel-title>
            <div class="d-flex mpr-flex-align-items-center mpr-flex-dir-row">
              <div class="mpr-collapsible-header">
                Restricted Data User Groups
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex m-t-16">
          <mat-chip-list>
            <mat-chip
              class="mpr-other-tags"
              *ngFor="
                let group of projectData['projectRestrictedDataUseGroups']
              "
            >
              {{ group }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>

<ng-template #showSpinner>
  <div class="mpr-noresult-container">
    <div class="mpr-noresutl-error">
      <span>
        <mat-spinner diameter="45" color="accent"></mat-spinner>
      </span>
    </div>
  </div>
</ng-template>
