import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SortKeyNameEnum,
  SortOrderEnum,
  UploadStatusesEnum,
  ConnectionDataStore,
} from '@core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
  OneTimeUploadHistory,
  RecurringUploadHistory,
} from '@shared/interfaces';
import {
  GetAllOneTimeUpload,
  GetAllRecurringUpload,
} from 'app/state/uploads/upload-history/history.action';
import { UploadHistoryState } from 'app/state/uploads/upload-history/history.state';
import { SortEventModel, SortKeyModel } from '../interfaces';
import { timer } from 'rxjs';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-upload-history',
  templateUrl: './upload-history.component.html',
  styleUrls: ['./upload-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadHistoryComponent implements OnInit {
  @ViewChild(MatSort) public sort!: MatSort;
  @ViewChild('paginatorOneTime') private paginatorOneTime!: MatPaginator;
  @ViewChild('paginatorRecurring') private paginatorRecurring!: MatPaginator;
  public activeTabIndex = 0;
  public columnsOneTimeUpload = [
    'userName',
    'dataStore',
    'totalNoOfFiles',
    'uploadDate',
    'description',
    'jobStatus',
  ];

  public columnsRecurringUpload = [
    'scheduleName',
    'frequency',
    'dataStore',
    'connectionName',
    'totalNoOfFiles',
    'runDate',
    'description',
    'jobStatus',
  ];

  public connectionDataStoreEnum = ConnectionDataStore;
  public currentPageOneTime!: number;
  public currentPageRecurring!: number;

  public dataOneTimeUpload: OneTimeUploadHistory[] = [];

  public dataRecurringUpload!: RecurringUploadHistory[];

  public dataSourceOneTimeOpload!: MatTableDataSource<any>;
  public dataSourceRecurringUpload!: MatTableDataSource<any>;
  public jobOperationType = '';
  public oneTimeUploadJobEmptyError =
    'There are no one-time uploads initiated for this project in the last one year';
  // MatPaginator Output
  public pageEventOneTime: PageEvent | undefined;
  public pageEventRecurring: PageEvent | undefined;
  public pageIndexOneTime = 0;
  public pageIndexRecurring = 0;
  public pageSizeOptions = [10, 25, 100];
  public recurringResultsEmptyMessage =
    'There are no recurring uploads initiated for this project in the last one year';
  public returnPath: string;
  public scheduleType = '';
  public searchPlaceHolder = 'Search Dataset Name';
  public searchTerm = '';
  public showLoadForOneTime!: boolean;
  public showLoadForRecUpload!: boolean;
  public showSpinner = false;
  public sortKey: SortKeyModel = {
    oneTimeSortKey: SortKeyNameEnum.MODIFIEDDATE,
    recurringSortKey: SortKeyNameEnum.MODIFIEDDATE,
  };
  public sortKeyNameEnum = SortKeyNameEnum;
  public sortOrder = SortOrderEnum.DESCENDING;
  public sortOrderEnum = SortOrderEnum;
  public startKey = '';
  public uploadStatusesEnum = UploadStatusesEnum;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.returnPath = route.snapshot.data['back'];
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm = filterValue;
    this.startKey = '';
    if (this.activeTabIndex === 1) {
      this.loadRecurringScheduleJobs();
    } else {
      this.loadOnetimeJobs();
    }
  }

  public getSortArrowDirectionClass(column: SortKeyNameEnum): string {
    if (this.activeTabIndex === 1) {
      if (this.sortKey.recurringSortKey === column)
        return this.sortOrder === SortOrderEnum.DESCENDING
          ? 'mpr-descending-icon'
          : 'mpr-ascending-icon';
      else return 'mpr-up-down-icon';
    } else {
      if (this.sortKey.oneTimeSortKey === column)
        return this.sortOrder === SortOrderEnum.DESCENDING
          ? 'mpr-descending-icon'
          : 'mpr-ascending-icon';
      else return 'mpr-up-down-icon';
    }
  }

  public getSortColumnAriaLabel(column: SortKeyNameEnum): string {
    if (this.activeTabIndex === 1)
      return this.sortKey.recurringSortKey === column
        ? this.sortOrder
        : 'Sortable Column';
    else
      return this.sortKey.oneTimeSortKey === column
        ? this.sortOrder
        : 'Sortable Column';
  }

  public handlePageEventOneTime(event: PageEvent): void {
    this.pageEventOneTime = event;
    this.pageIndexOneTime = event.pageIndex;
  }

  public handlePageEventRecurring(event: PageEvent): void {
    this.pageEventRecurring = event;
    this.pageIndexRecurring = event.pageIndex;
  }

  public handlePageNumberOneTime(): void {
    if (this.currentPageOneTime !== this.pageIndexOneTime) {
      const button = document
        .getElementById('paginatorOneTime')
        ?.getElementsByClassName('mat-paginator-navigation-previous');
      if (this.dataOneTimeUpload.length) {
        const elem = document.getElementById('currentPageOneTime');
        if (elem) elem.parentNode?.removeChild(elem);
        if (button && button[0]) {
          button[0].insertAdjacentHTML(
            'afterend',
            '<span id="currentPageOneTime" class="current-page">Page ' +
              (this.pageIndexOneTime + 1).toString() +
              '</span>'
          );
          this.currentPageOneTime = this.pageIndexOneTime;
        }
      }
    }
  }

  public handlePageNumberRecurring(): void {
    if (this.currentPageRecurring !== this.pageIndexRecurring) {
      const button = document
        .getElementById('paginatorRecurring')
        ?.getElementsByClassName('mat-paginator-navigation-previous');
      if (this.dataRecurringUpload.length) {
        const elem = document.getElementById('currentPageRecurring');
        if (elem) elem.parentNode?.removeChild(elem);
        if (button && button[0]) {
          button[0].insertAdjacentHTML(
            'afterend',
            '<span id="currentPageRecurring" class="current-page">Page ' +
              (this.pageIndexRecurring + 1).toString() +
              '</span>'
          );
          this.currentPageRecurring = this.pageIndexRecurring;
        }
      }
    }
  }

  public loadMoreOnetimeUpload(): void {
    this.setLoadMoreSpinnerVisibility(true);
    this.store
      .select(UploadHistoryState.getOneTimeUpload)
      .subscribe((oneTimeUploads) => {
        this.setLoadMoreSpinnerVisibility(false);
        this.startKey = oneTimeUploads.lastEvaluatedKey;
        this.showLoadForOneTime =
          oneTimeUploads.lastEvaluatedKey === '' ? false : true;
      });
    this.store.dispatch(
      new GetAllOneTimeUpload(
        this.startKey,
        this.sortKey.oneTimeSortKey,
        this.sortOrder,
        this.searchTerm,
        this.jobOperationType,
        true
      )
    );
  }

  public loadMoreRecurringUpload(): void {
    this.setLoadMoreSpinnerVisibility(true);
    this.store
      .select(UploadHistoryState.getRecurringUpload)
      .subscribe((recurringUploads) => {
        this.setLoadMoreSpinnerVisibility(false);
        this.startKey = recurringUploads.lastEvaluatedKey;
        this.showLoadForRecUpload =
          recurringUploads.lastEvaluatedKey === '' ? false : true;
      });
    this.store.dispatch(
      new GetAllRecurringUpload(
        this.startKey,
        this.sortKey.recurringSortKey,
        this.sortOrder,
        this.searchTerm,
        this.scheduleType,
        true
      )
    );
  }

  public loadOnetimeJobs(): void {
    this.store.dispatch(
      new GetAllOneTimeUpload(
        this.startKey,
        this.sortKey.oneTimeSortKey,
        this.sortOrder,
        this.searchTerm,
        this.jobOperationType
      )
    );
    this.store
      .select(UploadHistoryState.getOneTimeUpload)
      .subscribe((oneTimeUploads) => {
        this.dataOneTimeUpload = oneTimeUploads.items;
        this.showLoadForOneTime =
          oneTimeUploads.lastEvaluatedKey === '' ? false : true;
        this.dataSourceOneTimeOpload = new MatTableDataSource(
          this.dataOneTimeUpload
        );
        if (this.dataOneTimeUpload.length < 1 && this.searchTerm !== '')
          this.oneTimeUploadJobEmptyError =
            'No results matching the keywords entered for description.';
        this.cdr.detectChanges();
        this.dataSourceOneTimeOpload.paginator = this.paginatorOneTime;
      });
  }

  public loadRecurringScheduleJobs(): void {
    this.setLoadMoreSpinnerVisibility(true);
    this.store.dispatch(
      new GetAllRecurringUpload(
        this.startKey,
        this.sortKey.recurringSortKey,
        this.sortOrder,
        this.searchTerm,
        this.scheduleType
      )
    );
    this.store
      .select(UploadHistoryState.getRecurringUpload)
      .subscribe((recurringUploads) => {
        this.setLoadMoreSpinnerVisibility(false);
        this.dataRecurringUpload = recurringUploads.items;
        this.showLoadForRecUpload =
          recurringUploads.lastEvaluatedKey === '' ? false : true;
        this.dataSourceRecurringUpload = new MatTableDataSource(
          this.dataRecurringUpload
        );
        if (this.dataRecurringUpload.length < 1 && this.searchTerm.length > 0) {
          this.recurringResultsEmptyMessage =
            'No results matching with the schedule name searched';
        }
        this.cdr.detectChanges();
        this.dataSourceRecurringUpload.paginator = this.paginatorRecurring;
      });
    this.dataSourceOneTimeOpload = new MatTableDataSource(
      this.dataOneTimeUpload
    );
    this.dataSourceOneTimeOpload.paginator = this.paginatorOneTime;
  }

  ngOnInit(): void {
    this.loadOnetimeJobs();
  }

  public oneTimeSortChange(e: SortEventModel): void {
    this.sortOrder =
      e.direction === 'asc'
        ? SortOrderEnum.ASCENDING
        : SortOrderEnum.DESCENDING;
    this.sortKey.oneTimeSortKey =
      e.active === 'userName'
        ? SortKeyNameEnum.MODIFIEDBY
        : SortKeyNameEnum.MODIFIEDDATE;
    this.startKey = '';
    this.loadOnetimeJobs();
  }

  public proceedToOneTimeJobDetails(history: OneTimeUploadHistory): void {
    this.router.navigate([`/uploads/job-details/onetime/${history.jobId}`]);
  }
  public proceedToRecurringJobDetails(history: RecurringUploadHistory): void {
    this.router.navigate([
      `/uploads/job-details/recurring/${history.scheduleJobId}`,
    ]);
  }

  public recurringSortChange(e: SortEventModel): void {
    this.sortOrder =
      e.direction === 'asc'
        ? SortOrderEnum.ASCENDING
        : SortOrderEnum.DESCENDING;
    this.sortKey.recurringSortKey =
      e.active === 'frequency'
        ? SortKeyNameEnum.FREQUENCY
        : SortKeyNameEnum.MODIFIEDDATE;
    this.startKey = '';
    this.loadRecurringScheduleJobs();
  }

  public selectedTabChange(e: any): void {
    this.searchTerm = '';
    this.startKey = '';
    this.activeTabIndex = e.index;
    if (e.index === 1) {
      this.sortOrder = SortOrderEnum.DESCENDING;
      this.searchPlaceHolder = 'Search Schedule Name';
      this.sortKey.recurringSortKey = SortKeyNameEnum.MODIFIEDDATE;
      this.loadRecurringScheduleJobs();
      (this.sort.sortables.get('runDate') as MatSortHeader)._arrowDirection =
        'desc';
    } else {
      this.sortOrder = SortOrderEnum.DESCENDING;
      this.searchPlaceHolder = 'Search Dataset Name';
      this.sortKey.oneTimeSortKey = SortKeyNameEnum.MODIFIEDDATE;
      this.loadOnetimeJobs();
      (this.sort.sortables.get('uploadDate') as MatSortHeader)._arrowDirection =
        'desc';
    }
  }

  public setLoadMoreSpinnerVisibility(showHide: boolean): void {
    if (showHide) {
      this.showSpinner = showHide;
      return;
    }

    // The loader till the table shows updated values
    timer(500).subscribe(() => {
      this.showSpinner = false;
    });
  }
}
