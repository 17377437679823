<div
  class="mpr-mat-dialog-container"
  [ngClass]="data.isForgotPassword ? 'container-forgot-size' : 'container-size'"
>
  <div class="mpr-workspace-close" mat-button mat-dialog-close>
    <img src="assets/images/cancel-popup.svg" alt="Close icon" />
  </div>
  <h1 mat-dialog-title class="mpr-dialog-workspace-title">
    {{ title }}
  </h1>
  <span class="mpr-workspace-text" *ngIf="!data.isForgotPassword"
    >Enter a password that will be used to access your Amazon Workspace</span
  >

  <form
    [formGroup]="awsWorkspaceSetPasswordForm"
    (ngSubmit)="submit()"
    class="mpr-dialog-form"
  >
    <mat-dialog-content>
      <label
        for="password"
        *ngIf="data.isForgotPassword"
        class="mpr-form-block-field-label"
        >USER ID : {{ data.userId }}
      </label>
      <div class="mpr-form-block-field">
        <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
          <div class="mpr-form-fields-row-items">
            <div class="d-flex mpr-flex-align-items-center mpr-flex-dir-row">
              <div  class="mpr-form-block-field-label"
                >Enter Password
              </div>
              <button class="mpr-btn-transparency" 
                [mtxTooltip]="dataSourePopover" 
                [mprMtxTooltipAriaDescribedBy]="dataSourePopover" 
                #tooltip="mtxTooltip"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
            </div>
            <mat-form-field
              floatLabel="never"
              class="mpr-mat-input-field mpr-width-percentage-100"
              [hideRequiredMarker]="true"
            >
              <input
                [type]="hidePassword ? 'password' : 'text'"
                id="password"
                formControlName="password"
                matInput
                class="mpr-form-block-field-input"
                placeholder="Type your password"
                autocomplete="off"
              />
              <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
                hidePassword ? 'visibility' : 'visibility_off'
              }}</mat-icon>
            </mat-form-field>
            <mat-error
              class="mpr-margin-top"
              *ngIf="awsWorkspaceSetPasswordForm.controls['password'].dirty && awsWorkspaceSetPasswordForm.controls['password'].errors?.['pattern']"
            >
              Password is not in required format.
            </mat-error>
          </div>
        </div>

        <div
          class="d-flex mpr-form-fields-row mpr-flex-alignitems-start mpr-margin-label-top"
        >
          <div class="mpr-form-fields-row-items">
            <div class="mpr-form-block-field-label"
              >Confirm Password
            </div>
            <mat-form-field
              floatLabel="never"
              class="mpr-mat-input-field mpr-width-percentage-100"
              [hideRequiredMarker]="true"
            >
              <input
                [type]="hideConfirmPassword ? 'password' : 'text'"
                id="confirmPassword"
                formControlName="confirmPassword"
                matInput
                class="mpr-form-block-field-input"
                placeholder="Type your password"
                autocomplete="off"
              />
              <mat-icon
                matSuffix
                (click)="hideConfirmPassword = !hideConfirmPassword"
                >{{
                  hideConfirmPassword ? 'visibility' : 'visibility_off'
                }}</mat-icon
              >
            </mat-form-field>
            <mat-error
              class="mpr-margin-top"
              *ngIf="awsWorkspaceSetPasswordForm.controls['confirmPassword'].dirty && awsWorkspaceSetPasswordForm.controls['confirmPassword'].errors?.['pattern']"
            >
              Password is not in required format.
            </mat-error>
            <mat-error
              class="mpr-margin-top"
              *ngIf="awsWorkspaceSetPasswordForm.controls['confirmPassword'].dirty && awsWorkspaceSetPasswordForm.controls['confirmPassword'].errors?.['confirmedValidator']"
            >
              Passwords do not match.
            </mat-error>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div><img src="assets/images/amazon-workspace.svg" alt="awsLogo" /></div>
      <div class="d-flex">
        <div>
          <button
            mat-button
            mat-dialog-close
            class="mpr-button mpr-blue-button mpr-cancel-button"
          >
            CANCEL
          </button>
        </div>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <div>
          <button
            mat-button
            class="mpr-button mpr-yellow-button"
            type="submit"
            [disabled]="!awsWorkspaceSetPasswordForm.valid || disableAction"
          >
            <span>PROCEED</span>
          </button>
        </div>
      </div>
    </mat-dialog-actions>
  </form>
</div>
<ng-template #dataSourePopover>
  <div id="mpr-aws-password">
    <div>Password must:</div>
    <br />
    <div class="d-flex">
      <ol type="1" class="margin-bottom">
        <li>Be between 8 and 32 characters</li>
        <li>Must contain at least one character from each of the following:</li>
        <ol type="i" class="margin-bottom">
          <li>Lowercase characters (a-z)</li>
          <li>Uppercase characters (A-Z)</li>
          <li>Numbers (0-9)</li>
          <li>
            Non-alphanumeric characters
            (~!@#$%^&*_-+=`|\()&#123;&#125;[]:;"'<>,.?/)
          </li>
        </ol>
      </ol>
    </div>
  </div>
</ng-template>
