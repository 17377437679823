import { UserProjectRoleEnum } from '@core/enums';
import { ServerUrls } from './user-project';
import { UserAWSStatus } from './user-aws-status';

export interface User {
  amazonWorkspace?: UserAWSStatus;
  createdBy: string;
  dwFeatureFlag: string;
  ec2Provisioned: boolean;
  ec2Status: string;
  emailId: string;
  instanceId: string;
  isHeavyLiftingFlagAvailable?: string;
  isPowerUser: boolean;
  isStataFeatureFlagAvailable?: string;
  isVscodeFeatureFlagAvailable?: string;
  machineSize: string;
  memory: string;
  modifiedBy: string;
  projectId: string;
  projectName: string;
  projectNickname: string;
  projectNumber: string;
  projectRestrictedDataUseGroups: string[];
  projectRestrictedS3Space: string[];
  projectStagingS3Space: string;
  projectStandardS3Space: string;
  publicLimitedS3Space: string;
  publicUseS3Space: string;
  rVersion: string;
  roleName: UserProjectRoleEnum;
  serverUrls: ServerUrls;
  userId: string;
  userPrivateS3Space?: string;
  userProjectId: string;
  userStatus: string;
  vCPU: string;
  workspaceStatus: string;
}
