/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { JobOperationTypeEnums } from '@core';
import { URLListModel } from 'app/uploads/interfaces';
import { MprFile } from 'app/uploads/interfaces/mpr-file-model';
import { UploadsMetadataState } from './uploads-metadata-state';

export class SelectFileForUploads {
  public static type = '[UploadsState] Select files';

  constructor(
    public selectedFiles: MprFile[],
    public selectedSupportingFiles: MprFile[],
    public jobId: string,
    public datasetName: string,
    public urls: URLListModel[],
    public jobOperationType?: JobOperationTypeEnums,
    public stagingTargetPath?: string
  ) {}
}

export class ResetUploadsState {
  public static type = '[UploadsState] Reset to Default';
}

export class SetUploadsMetaData {
  public static type = '[UploadsState] Set File Metadata';

  constructor(public fileMetadata: UploadsMetadataState) {}
}

export class SetExistingFileNames {
  public static type = '[UploadsState] Set Existing/Duplicate Filenames';

  constructor(public existingFileNames: string[]) {}
}
