/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MprHttpHeaderModal } from '@core/interfaces';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertMessageService, AuthService } from '@core/services';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { noWhitespaceValidator } from '@shared/validators';
import { RESTRICTED_GROUP_REGEX } from '@core/constants';
@Component({
  selector: 'app-add-restricted-group',
  templateUrl: './add-restricted-group.component.html',
  styleUrls: ['./add-restricted-group.component.scss'],
})
export class AddRestrictedGroupComponent implements OnInit {
  public requestHeaders: MprHttpHeaderModal = {};
  public restrictedGroupForm!: FormGroup;
  public validationErrorMessage = '';
  public disableAction = false;

  constructor(
    private dialogRef: MatDialogRef<AddRestrictedGroupComponent>,
    private alertMsgService: AlertMessageService,
    private fb: FormBuilder
  ) {}

  public get createEditFolderFormControls(): {
    [key: string]: AbstractControl;
  } {
    return this.restrictedGroupForm.controls;
  }

  ngOnInit(): void {
    this.restrictedGroupForm = this.fb.group({
      projectRestrictedDataUseGroups: [
        '',
        [
          Validators.maxLength(20),
          Validators.pattern(RESTRICTED_GROUP_REGEX),
          noWhitespaceValidator(),
          this.customTextInputValidator,
        ],
      ],
    });
  }

  public customTextInputValidator(
    control: AbstractControl
  ): { [key: string]: any } | null | [[key: string]] {
    let valid = true;
    let invalidText;
    const invalidTexts = ['staging', 'restricted'];
    const inputValue = control.value.toString();
    if (inputValue) {
      if (invalidTexts.includes(inputValue.toLowerCase())) {
        valid = false;
        invalidText = inputValue;
      }
    }
    return valid
      ? null
      : {
          textValidators: true,
          message: `"staging", "restricted" is not a supported restricted data use group. Please enter a different name.`,
        };
  }

  public submit(): void {
    this.alertMsgService.success({
      body: `New restricted data use group created sucessfully.`,
    });
    // All is well
    this.dialogRef.close({
      result: 'success',
      restrictedGroupValue:
        this.restrictedGroupForm.controls['projectRestrictedDataUseGroups']
          .value,
    });
  }
}
