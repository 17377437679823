<mat-table
  [dataSource]="dataSource"
  class="file-list-table"
  #filesTable
  role="table"
  aria-rowcount="-1"
>
  <ng-container matColumnDef="serialNum">
    <mat-header-cell *matHeaderCellDef scope="col">
      Serial No.
    </mat-header-cell>
    <mat-cell *matCellDef="let file; let i = index">
      {{ i + 1 }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="fileName">
    <mat-header-cell *matHeaderCellDef scope="col"> File Name </mat-header-cell>
    <mat-cell *matCellDef="let file"> {{ file.name }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="fileSize">
    <mat-header-cell *matHeaderCellDef scope="col"> Size </mat-header-cell>
    <mat-cell *matCellDef="let file">
      {{ file.size | fileSize }}
    </mat-cell>
  </ng-container>
  <!-- Remove Column    Starts       -->
  <ng-container matColumnDef="removeFile">
    <mat-header-cell *matHeaderCellDef scope="col"> Remove </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <button class="mpr-btn-transparency" (click)="removeFilesFromList(file)">
        <mat-icon
          class="mpr-remove-file"
          title="Remove file"
          aria-hidden="false"
          aria-label="Remove file"
          >cancel</mat-icon
        >
      </button>
    </mat-cell>
  </ng-container>
  <!-- Remove Column    Ends       -->
  <mat-header-row
    role="rowheader"
    *matHeaderRowDef="displayedColumns"
  ></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
