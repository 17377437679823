// icon.module
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MaterialModule } from './material.module';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [MaterialModule],
})
export class IconModule {
  private path = '/assets/images';

  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry
  ) {
    this.matIconRegistry
      .addSvgIcon('home', this.setPath(`${this.path}/home.svg`))
      .addSvgIcon('catalog', this.setPath(`${this.path}/catalog.svg`))
      .addSvgIcon('download', this.setPath(`${this.path}/download.svg`))
      .addSvgIcon('exchange', this.setPath(`${this.path}/exchange.svg`))
      .addSvgIcon('filemanager', this.setPath(`${this.path}/filemanager.svg`))
      .addSvgIcon('workspace', this.setPath(`${this.path}/workspace.svg`))
      .addSvgIcon('upload', this.setPath(`${this.path}/upload.svg`))
      .addSvgIcon(
        'Data_Warehouse',
        this.setPath(`${this.path}/Data_Warehouse.svg`)
      )
      .addSvgIcon('arrow-left', this.setPath(`${this.path}/arrow-left.svg`))
      .addSvgIcon(
        'admin-console',
        this.setPath(`${this.path}/admin_console.svg`)
      )
      .addSvgIcon(
        'platform_admin',
        this.setPath(`${this.path}/platform_admin.svg`)
      );
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
