import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { customSort } from '@shared/utils';
import {
  LoadSelectedUserProjects,
  LoadAllUserdata,
  ResetUserProjectState,
  UserState,
  SelectedUserProjectInfoState,
} from 'app/state';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import { Observable, catchError, throwError, timer } from 'rxjs';
import { UserProject } from '@theme/interfaces';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import UserStateModel from 'app/state/user/user-state-model';
import { UserList } from 'app/state/project-user/user-list-model';
import { MatDialog } from '@angular/material/dialog';
import { MprConfirmComponent } from '@shared';
import { DeleteUserFromProject } from 'app/state/project-user/project-user.actions';
import { AlertMessageService } from '@core/services';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { UserProjectRoleEnum } from '@core';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit, OnDestroy {
  @Select(SelectedUserProjectInfoState.getSelectedUserProjects)
  public allProjectsForPlatformAdmin$?: Observable<UserProject[]>;
  @Select(UserState.getAllUsers)
  public listDetails!: Observable<UserStateModel>;
  public dataSource!: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'projectName',
    'roleName',
    'edit',
    'delete',
  ];
  public selectedUserDetails: any = {};
  public selectedUserHasOnlyOneProject = false;
  public selectedUserId = '';
  public selectedUserProjects: any = [];
  public showSpinner = true;
  private requestHeaders?: MprHttpHeaderModal = {};

  constructor(
    public actions$: Actions,
    public store: Store,
    private alertService: AlertMessageService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.selectedUserId = atob(route.snapshot.params['emailId']);
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { message: any };
    if (state?.message) {
      timer(1000).subscribe(() => {
        this.alertService.success({
          body: state.message.message,
        });
      });
    }
  }

  public editUser(user: UserList, projectData: UserProject): void {
    const userStr =
      projectData['userStatus'] === 'invited'
        ? user['emailId']
        : user['userId'];

    const encodedUserStr = btoa(userStr);
    const projectId = btoa(projectData.projectId);
    this.router.navigate([
      `/platformAdmin/users/edit/${encodedUserStr}/${projectId}`,
    ]);
  }

  public loadUserData(): void {
    this.store.dispatch(new ResetUserProjectState());
    this.showSpinner = true;
    this.selectedUserProjects = [];
    this.dataSource = new MatTableDataSource(this.selectedUserProjects);

    this.store.dispatch([
      new LoadSelectedUserProjects(this.selectedUserId),
      new LoadAllUserdata(),
    ]);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetUserProjectState());
  }

  ngOnInit(): void {
    this.store.dispatch(new ResetUserProjectState());
    this.loadUserData();
    this.listDetails.subscribe((userDetail: UserStateModel) => {
      if (userDetail && userDetail.userInfo) {
        this.selectedUserDetails = userDetail.userInfo.filter(
          (el: any) => el.emailId === this.selectedUserId
        );
      }
    });

    // Only when my action is successful then do this
    this.actions$
      .pipe(ofActionSuccessful(LoadSelectedUserProjects))
      .subscribe((action) => {
        const projectsForSelectedUser = this.store.selectSnapshot(
          SelectedUserProjectInfoState.getSelectedUserProjects
        );

        //Create a new array and sort it alphabetically by projectId
        const sortedProjects = customSort(projectsForSelectedUser);
  
        this.showSpinner = true;
        this.selectedUserProjects = [];

        if (sortedProjects && sortedProjects.length === 0) 
          this.router.navigate([`/platformAdmin/users`]);
        else
         this.selectedUserProjects = sortedProjects;

        this.selectedUserHasOnlyOneProject = this.selectedUserProjects.length === 1;
        this.dataSource = new MatTableDataSource(this.selectedUserProjects);
        this.showSpinner = false;
      });
  }

  public showDeleteConfirmation(
    user: UserList,
    projectData: UserProject
  ): void {
    const userId =
      projectData['userStatus'] === 'invited'
        ? user['emailId']
        : user['userId'];
    const projectId = projectData['projectId'];
    const dialogRef = this.dialog.open(MprConfirmComponent, {
      data: {
        confirmTitle: 'Delete User',
        confirmMessage: this.selectedUserHasOnlyOneProject
          ? `Are you sure you want to delete <b>${user.userName}</b> from project <b>${projectId}</b>?<br/><br/>Once removed, they will no longer have access to their project data and any EC2 processing instance & Amazon WorkSpace, if provisioned, will be terminated. Any running jobs on the provisioned workspaces will also be terminated.`
          : `Are you sure you want to delete <b>${user.userName}</b> from project <b>${projectId}</b>?<br/><br/>Once removed, they will no longer have access to their project data and any EC2 processing instance, if provisioned, will be terminated. Any running jobs on the provisioned workspaces will also be terminated.`,
        confirmData: {
          userId,
          projectId,
        },
      },
    });
    // NOTE: You do not need to unsubscribe from a dialogRef.afterClosed
    dialogRef.afterClosed().subscribe((data: any): void => {
      if (!data) return;

      this.requestHeaders = {};
      this.requestHeaders[HeaderParams.ROLENAME] =
        UserProjectRoleEnum.PLATFORM_ADMIN;

      this.store
        .dispatch(
          new DeleteUserFromProject(data.userId, this.requestHeaders, [
            data.projectId,
          ])
        )
        .pipe(
          catchError((err) => {
            this.alertService.error({
              body: err.error.message,
            });
            return throwError(() => new Error(''));
          })
        )
        .subscribe((res) => {
          const deleteResponse = res.ProjectUser.deleteResponse;
          if (deleteResponse.length > 0) {
            if (!deleteResponse[0].isSuccess) {
              this.alertService.error({
                body: deleteResponse[0].message,
              });
              this.loadUserData();
            } else {
              if(!this.selectedUserHasOnlyOneProject){
                this.alertService.success({
                  body: `User has been deleted successfully from ${data.projectId}.`,
                });
              } else {
                setTimeout(() => {
                  this.alertService.success({
                    body: `User has been deleted successfully from ${data.projectId}.`,
                  });
                },0);
                this.router.navigate([`/platformAdmin/users`]);
              }
              this.selectedUserProjects = this.selectedUserProjects.filter((item: any) => item.projectId !== data.projectId );
              this.selectedUserHasOnlyOneProject = this.selectedUserProjects.length === 1;
              this.dataSource = new MatTableDataSource(this.selectedUserProjects);           
            }
          }
        });
    });
  }
}
