<div *ngIf="showNonProdWarning()" class="upload-page-env-warning">
  <div class="mpr-display-flex mpr-padding-15">
    <div>
      <div class="mpr-display-flex-start mpr-fw-b mpr-fs-12">
        <div class="mpr-margin-med">
          <mat-icon class="mpr-warning-icon">warning_amber</mat-icon>
        </div>
        <div>Non-production Environment</div>
      </div>
      <div class="mpr-upload-fw-m">
        This is a non-production environment. It is provided solely to develop
        and test new functionality.<br />
        Please make sure you are not uploading any sensitive data here.
      </div>
    </div>
    <div class="mpr-display-flex mpr-marginleft-auto">
      <div>
        <a href="{{ prodEnvUrl }}" target="_blank">
          <button class="mpr-prod-btn">
            <div>Go to</div>
            <div>Production Data Library</div>
          </button>
        </a>
      </div>
    </div>
    <div class="mpr-size-12 mpr-self-align-top">
      <a (click)="hideWarning()" class="mpr-fc-warning">
        <mat-icon id="mpr-close-icon">clear</mat-icon>
      </a>
    </div>
  </div>
</div>
<ng-container
  *ngIf="{
    project: (project$ | async),
    projects: (projects$ | async),
    selectedConnection: (selectedConnection$ | async)
  } as projectData"
>
  <div>
    <div
      class="subheader app-sub-header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="subheader-left-container">
        <mpr-subheader-project-select
          *ngIf="isDashboard && projectData.projects?.length; else mprsubheader"
        ></mpr-subheader-project-select>
        <ng-template #mprsubheader>
          <mpr-subheader-page-title-nav></mpr-subheader-page-title-nav>
        </ng-template>
      </div>
      <div *ngIf="projectData.project">
        <mat-chip-list
          [tabIndex]="matChipTabIndex"
          aria-label="Selected Project"
          class="subheader-chips"
          *ngIf="
            projectData.project.projectNumber !== '' && !isDownloadOrPages()
          "
        >
          <mat-chip>
            <div class="subheader-mat-chip-items">
              <div class="text-uppercase truncate">
                {{ projectData.project | projectShortName }}
              </div>
            </div>
          </mat-chip>
          <button
            class="subheader-project-info-icon mpr-btn-transparency"
            [mtxTooltip]="popover"
            [mprMtxTooltipAriaDescribedBy]="popover"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Project Name"
              class="mpr-info-icon"
              >info_outline</mat-icon
            >
          </button>
          <div class="mpr-vertical-line"></div>
          <mat-chip
            ><span class="text-uppercase truncate">{{
              projectData.project.roleName | replaceRoleName
            }}</span></mat-chip
          >
        </mat-chip-list>
        <ng-template #popover>
          <div id="mpr-project-name">
            {{ projectData.project.projectName }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div
    class="dashboard-sub-header-note"
    *ngIf="
      projectData.projects?.length &&
      !projectData.project?.projectId &&
      isDashboard
    "
  >
    <div class="dashboard-sub-header-message">
      Select your desired project to access the file uploads and processing
      functions
    </div>
  </div>
  <div
    class="download-file-sub-header-note"
    *ngIf="
      router.url.includes('/download') &&
      projectData.selectedConnection?.connectionId === ''
    "
  >
    <div class="download-file-sub-header-message">
      Please select an export location for the {{ getFileOrDatasetDownload() }}.
    </div>
  </div>
</ng-container>
